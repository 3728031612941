import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import restService from '../../../../utils/restService';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
// import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';

const Weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

//requires a groupID 
class UserPreferences extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            groupID: "",
            group: "",
            user: {},
            //render control
            isLoading: true,
            variant: "light",
        };
    }

    componentDidMount() {
        //get group data
        try {
            var history = this.props.location.pathname + this.props.location.search;
            const match = matchPath(history, {
                path: "/civGroup/*?groupID=:groupID",
                strict: false
            });

            var groupID = match.params.groupID;
            this.setState({ groupID: groupID });
            this.getGroupInfo(groupID);
            this.getUserInfo(groupID);
        }
        catch (err) {
            console.log("Unexpected error trying to setup Group Page: ", err);
            this.props.history.push("/");
        }
    }

    //-----API FUNCTIONS-----//
    //#region API
    getGroupInfo = (groupID) => {
        restService.groups.getFullGroup(groupID).then((response) => {
            const data = response.data;
            var user = data?.members.find(user => user.userID === this.props.auth.user.id);
            this.setState({ group: data, user: user, isLoading: false });
        }).catch((err) => {
            console.log("Error retrieving games data: ", err);
        });
    }

    getUserInfo = (groupID) => {
        //restService.
        var userID = this.props.auth.user.id;

        restService.civilization.UserSettings.getPlayerAvailability(groupID, userID).then(res => {
            const data = res.data;
            this.setState({ playerAvailability: data });
        }).catch(err => {
            console.log("Unexpected error trying to leave the group: ", err);
        });
    }
    //#endregion

    //-----EVENT FUNCTIONS-----//
    //#region EVENTS
    onSubmitLeave = e => {
        e.preventDefault();

        if (this.props.auth.user.id === this.state.group.groupOwner) {
            this.handleCloseConfirm();
            this.setState({ alertError: true, errorMessage: "Group creators/owners cannot leave, they may only delete" });

            //close success after 5 seconds
            setTimeout(() => {
                this.setState({ alertError: false, errorMessage: "" });
            }, 10000);
        }
        else {
            var payload = {
                groupID: this.state.groupID,
                userID: this.props.auth.user.id
            }

            restService.groups.leaveGroup(payload).then(res => {
                this.handleCloseConfirm();
                this.props.history.push("/dashboard");
            }).catch(err => {
                console.log("Unexpected error trying to leave the group: ", err);
            });
        }
    };

    onChange = e => {
        const target = e.target;
        // const value = target.type === 'checkbox' ? target.checked : target.value;
        // const name = target.id;

        var payload = {
            groupID: this.state.groupID,
            userID: this.props.auth.user.id,
            time: target.attributes.time.value,
            day: target.attributes.day.value
        };
        restService.civilization.UserSettings.postPlayerAvailability(this.state.groupID, this.props.auth.user.id, payload).then(res => {
            if (res.status === 200) {
                //this.getUserInfo(this.state.groupID);
                this.setState({ title: "Success!", message: "Successfully saved game setting preference.", showToast: true });
            }
        }).catch(err => {
            console.log("Unexpected error while setting map type preference: ", err);
        });
    };

    onChangeNotifications = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        var { user, group } = this.state;

        if (user.notifications) {
            user.notifications[name] = value;
        }

        var payload = {
            receiveEventNotifications: value
        }

        restService.members.postMemberNotifications(group._id, this.props.auth.user.id, payload).catch(err => {
            console.log("Unexpected error while setting map type preference: ", err);
        });

        this.setState({ user: user });
    };
    //#endregion

    //-----HANDLER FUNCTIONS-----//
    //#region HANDLERS
    handleShow = () => {
        this.setState({ show: true });
    }

    handleClose = () => {
        //reset from variables
        this.setState({ show: false });
    }

    handleShowConfirm = () => {
        this.setState({ showConfirm: true });
    }

    handleCloseConfirm = () => {
        this.setState({ showConfirm: false });
    }
    //#endregion

    //-----DISPLAY FUNCTIONS-----//
    //#region DISPLAY
    displayBody = () => {
        return (
            <Form>
                <Form.Label>Leave Group</Form.Label>
                <div>
                    <Button onClick={this.handleShowConfirm}>Leave Group</Button>
                </div>
            </Form>
        );
    }

    displayAlertError = () => {
        if (this.state.alertError) {
            return (
                <Alert variant="danger" className="fade">
                    {this.state.errorMessage}
                </Alert>
            );
        }
        else {
            return null;
        }
    }
    //#endregion

    render() {
        const { user, isLoading, playerAvailability } = this.state;
        if (isLoading) {
            return null;
        }
        else {


            return (
                <div className="py-1 px-3" >
                    {this.displayAlertError()}
                    <div className="py-3">
                        <Card bg={this.state.variant} >
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                Weekly Availability
                            </Card.Header>

                            <Card.Body>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            {Weekdays.map((day, index) => (
                                                <th key={index}>{day}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {Weekdays.map((day, index) => (
                                                <td key={index}><Form.Check type="checkbox" id={index + "morning"} defaultChecked={playerAvailability.find(pl => { return pl.day === day && pl.time === "Morning" })} time="Morning" day={day} label="Morning" onChange={this.onChange} /></td>
                                            ))}
                                        </tr>
                                        <tr>
                                            {Weekdays.map((day, index) => (
                                                <td key={index}><Form.Check type="checkbox" id={index + "afternoon"} defaultChecked={playerAvailability.find(pl => { return pl.day === day && pl.time === "Afternoon" })} time="Afternoon" day={day} label="Afternoon" onChange={this.onChange} /></td>
                                            ))}
                                        </tr>
                                        <tr>
                                            {Weekdays.map((day, index) => (
                                                <td key={index}><Form.Check type="checkbox" id={index + "evening"} defaultChecked={playerAvailability.find(pl => { return pl.day === day && pl.time === "Evening" })} time="Evening" day={day} label="Evening" onChange={this.onChange} /></td>
                                            ))}
                                        </tr>
                                    </tbody>
                                </Table>

                            </Card.Body>
                        </Card>
                    </div>


                    <div className="py-3">
                        <Card bg={this.state.variant} >
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                User
                            </Card.Header>

                            <Card.Body>
                                {this.displayBody()}
                            </Card.Body>
                        </Card>
                    </div>

                    <div className="py-3">
                        <Card bg={this.state.variant}>
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                Notifications
                            </Card.Header>

                            <Card.Body>
                                <Form.Check
                                    type="checkbox"
                                    id="receiveEventNotifications"
                                    label="Event Notifications"
                                    onChange={this.onChangeNotifications}
                                    checked={user.notifications?.receiveEventNotifications}
                                />
                            </Card.Body>
                        </Card>
                    </div>

                    {/* CONFIRM LEAVE */}
                    <Modal show={this.state.showConfirm} onHide={this.handleCloseConfirm} centered animation={true} backdrop="static" keyboard={false}>
                        <Modal.Header>
                            <Modal.Title>Leave Group</Modal.Title>
                            <button type="button" onClick={this.handleCloseConfirm} className="btn-close" aria-label="Close"></button>
                        </Modal.Header>

                        <Modal.Body style={{ margin: '5px' }}>
                            Are you sure you wish to leave the group?
                        </Modal.Body>

                        <Modal.Footer>
                            <Button type="submit" onClick={this.onSubmitLeave} as={Col} xs={2}>
                                Submit
                            </Button>
                            <Button onClick={this.handleCloseConfirm} as={Col} xs={2} style={{ marginLeft: '10px' }}>
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            );
        }
    }
}

UserPreferences.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(UserPreferences);


