import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import restService from '../../../../utils/restService';

import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';

//requires a groupID 
class Welcome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            groupID: props.groupID,
            group: {},
            cards: [
                { title: "Welcome" }
            ],
            //render control
            isLoading: true,
            variant: "light",
            maps: []
        };
    }

    componentDidMount() {
        this.getGroupInfo(this.props.groupID);
    }

    //-----API FUNCTIONS-----//
    //#region API
    getGroupInfo = (groupID) => {
        restService.groups.getGroup(groupID).then((response) => {
            const data = response.data;
            this.setState({ group: data });
        }).catch((err) => {
            console.log("Error retrieving games data: ", err);
        }).then(() => {
            this.getGroupCards(groupID);
        });
    }

    getGroupCards = (groupID) => {
        restService.components.getGroupCards(groupID).then((response) => {
            const data = response.data?.sort(function (a, b) { return a.cardOrder - b.cardOrder; });;

            if (data.length) {
                this.setState({ cards: data });
            }

            this.setState({ isLoading: false });
        }).catch((err) => {
            console.log("Error retrieving group cards data: ", err);
        });
    }
    //#endregion

    //-----EVENT FUNCTIONS-----//
    //#region EVENTS

    //#endregion

    //-----HANDLER FUNCTIONS-----//
    //#region HANDLERS

    //#endregion

    //-----DISPLAY FUNCTIONS-----//
    //#region DISPLAY
    displayBody = (card) => {
        var text = card?.body;
        if (text) {
            return (
                <div>
                    {text.split("\n").map((i, key) => {
                        return <div style={{ minHeight: '24px' }} key={key}>{i}</div>;
                    })}
                </div>
            );
        }
        else {
            return null;
        }
    }

    displayCards = (cards) => {
        if (this.state.isLoading) {
            return (
                <Spinner animation="border" role="status" variant="secondary" >
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            );
        }
        else {
            return cards.map((card, index) => (
                <Card key={index} bg={this.state.variant} className="my-3">
                    <Card.Header>{card.title}</Card.Header>

                    <Card.Body>
                        {this.displayBody(card)}
                    </Card.Body>
                </Card>
            ));
        }
    }
    //#endregion

    render() {
        const { cards } = this.state;

        return (
            <div className="py-1 px-3" >
                {this.displayCards(cards)}
            </div>
        );

    }
}

Welcome.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(Welcome);