import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { matchPath } from "react-router";
// import restService from '../../../../utils/restService';

// import Navbar from 'react-bootstrap/Navbar';
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
// import Form from 'react-bootstrap/Form';
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import Card from 'react-bootstrap/Card';

import MemberSettings from "../RuneScapeComponents/GroupSettings/MemberSettings";
import GroupSettings from "../RuneScapeComponents/GroupSettings/GroupSettings";
import WelcomePageSettings from "../RuneScapeComponents/GroupSettings/WelcomePageSettings";

class RuneScapeGroupSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            groupID: ""
        };
    }

    componentDidMount() {
        //get group data
        try {
            var history = this.props.location.pathname + this.props.location.search;
            const match = matchPath(history, {
                path: "/runeScapeClan/*?groupID=:groupID",
                strict: false
            });
            var groupID = match.params.groupID;
            this.setState({ groupID: groupID });
        }
        catch (err) {
            console.log("Unexpected error trying to setup Group Page: ", err);
            this.props.history.push("/");
        }
    }

    render() {
        const { groupID } = this.state;

        return (
            <div>
                {groupID != null && groupID !== "" ? <GroupSettings groupID={groupID} /> : null}
                {groupID != null && groupID !== "" ? <MemberSettings groupID={groupID} /> : null}
                {groupID != null && groupID !== "" ? <WelcomePageSettings groupID={groupID} /> : null}
            </div>
        );
    }

}

RuneScapeGroupSettings.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(RuneScapeGroupSettings);