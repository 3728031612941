import keyMirror from 'keymirror';

export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const USER_REGISTERED = "USER_REGISTERED";
export const SET_STEAM_ID = "SET_STEAM_ID";

const ActionTypes = keyMirror({
    HIDE_ALERT: null,
    SHOW_ALERT: null,
});

export default ActionTypes;