import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import restService from '../../../../../utils/restService';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';

class Members extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            groupID: "",
            group: {},
            //render control
            isLoading: true,
            variant: "light",
            show: false,
        };
    }

    componentDidMount() {
        this.setState({ groupID: this.props.groupID });
        this.getFullGroup(this.props.groupID);
    }

    getFullGroup = (groupID) => {
        restService.groups.getFullGroup(groupID).then((response) => {
            const data = response.data;
            this.setState({ group: data });
        }).catch((err) => {
            console.log("Error retrieving member data: ", err);
        }).then(() => {
            this.setState({ isLoading: false })
        });
    }

    // getMemberInfo = (memberIDs) => {
    //     var params = { memberIDs: memberIDs, groupID: this.state.groupID };

    //     restService.groups.getMembers(params).then((response) => {
    //         const data = response.data;
    //         console.log("Logging data: ", data);
    //         this.setState({ members: data });
    //     }).catch((err) => {
    //         console.log("Unexpected error retrieving member data: ", err);
    //     }).then(() => {
    //         this.setState({ isLoading: false })
    //     });
    // }

    handleShow = () => {
        this.setState({ show: true });
    }

    handleClose = () => {
        //reset from variables
        this.setState({ show: false });
    }

    // joinPublicGroup = () => {
    //     var payload = {
    //         userID: this.props.auth.user.id,
    //         groupID: this.state.group._id
    //     }

    //     // axios
    //     //     .post("api/groups/joinPublicGroup", payload)
    //     //     .then(res => {
    //     //         const data = res.data;
    //     //         this.setState({ group: data, members: data.members })
    //     //     })
    //     //     .catch(err => {
    //     //         console.log("Unexpected error while joining group: ", err)
    //     //     });
    // }

    onChange = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        this.setState({ [name]: value });
    }

    displayPlayerMembership = (group) => {
        if (!group) {
            return null;
        }
        else {
            return group.members.map((member, index) => (
                <Container key={index} className="py-2">
                    <Row>
                        <Col xs={2} className="d-flex align-items-center"> {member.username} </Col>
                        <Col xs={4} className="align-items-center">
                            <Form.Group className="py-1">
                                <Form.Control
                                    disabled={group.groupOwner === member.userID || ((!(group.groupOwner === member.userID) && member.role === "Administrator") && !(this.props.auth.user.id === group.groupOwner))}
                                    as="select" id={member.username} aria-label="Membership" value={member.role} onChange={this.onChangeMembership}
                                >
                                    <option>Administrator</option>
                                    <option>Member</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={2} className="d-flex align-items-center" >
                            {group.groupOwner === this.props.auth.user.id && !(group.groupOwner === member.userID) ? <Button onClick={() => { this.kickMember(member.userID) }}>Kick</Button> : null}
                        </Col>
                    </Row>
                </Container>
            ));
        }
    }

    kickMember = (userID) => {
        var payload = {
            userID: userID,
            groupID: this.state.group._id
        }

        restService.groups.kickMember(payload).then(res => {
            const data = res.data;
            this.setState({ group: data });
        }).catch(err => {
            console.log("Unexpected error while updating group members: ", err);
        }).then(() => {
            this.getMemberInfo(this.state.group.members);
        })
    }

    displayGroupMembers = (group) => {
        if (!this.state.isLoading) {
            return (
                <Modal.Body>
                    {this.displayPlayerMembership(group)}
                </Modal.Body>
            );
        }
        else {
            return (
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            );
        }
    }

    onChangeMembership = e => {
        const target = e.target;
        const value = target.value;
        const name = target.id;

        const member = this.state.group?.members.find(member => member.username === name);
        var payload = {
            userID: member.userID,
            role: value
        };
        
        restService.groups.updateMemberRole(this.state.group._id, payload).then(res => {
            var data = res.data;
            var group = this.state.group;
            group.members.find(member => member.userID === data.userID).role = data.role;

            this.setState({ group: group });
        }).catch(err => {
            console.log("Unexpected error trying to change member relationship: ", err);
        });
    }

    displayBody = (members, isLoading) => {
        if (isLoading) {
            return (
                <div>
                    <Spinner animation="border" role="status" variant="secondary" >
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            );
        }
        else {
            return (
                <div>
                    <Table responsive striped bordered hover variant={this.state.variant} >
                        <thead>
                            <tr>
                                <th>Member</th>
                                <th>Rank</th>
                            </tr>
                        </thead>
                        <tbody>
                            {members.map((member, index) => (
                                <tr key={index}>
                                    <th>{member.username}</th>
                                    <th>{member.role}</th>
                                </tr>))}
                        </tbody>
                    </Table>
                </div>
            )
        }
    }

    render() {
        const { group, isLoading } = this.state;

        return (
            <div className="py-3 px-3">
                <Card bg={this.state.variant}>
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        Members
                        <Button onClick={this.handleShow}>Edit</Button>
                    </Card.Header>
                    <Card.Body>
                        {this.displayBody(group.members, isLoading)}
                    </Card.Body>
                </Card>

                {/* Show members modal */}
                <Modal size="xl" show={this.state.show} onHide={this.handleClose} centered animation={true} backdrop="static" keyboard={false}>
                    <Modal.Header className="d-flex justify-content-between align-items-center">
                        <Modal.Title>Edit Members</Modal.Title>
                        <button type="button" onClick={this.handleClose} className="btn-close" style={{ marginLeft: '0px' }} aria-label="Close"></button>
                    </Modal.Header>

                    {this.displayGroupMembers(group)}
                </Modal>
            </div>
        );
    }

}

Members.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(Members);