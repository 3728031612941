import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import moment from "moment";
import axios from 'axios';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
// import restService from '../../../../utils/restService';

import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';

class EventsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //errors
            errors: {},
            alertError: false,
            //component variables
            
            add: false,
            edit: false,
            addEditTitle: "",
            showAddEdit: false,
            showEvent: false,
            showConfirm: false,
            groupID: "",
            events: [],
            isDisabled: false,
            //event variable
            modalEvent: {
                eventName: "",
                startDate: null,
                endDate: null,
                maxNumberOfMembers: ""
            },
        };
    }

    componentDidMount() {
        try {
            var history = this.props.location.pathname + this.props.location.search;
            const match = matchPath(history, {
                path: "/*/*?groupID=:groupID",
                strict: false
            });
            var groupID = match.params.groupID;
            this.setState({ groupID: groupID });
            this.getGroupEvents(groupID);
        }
        catch (err) {
            console.log("Unexpected error trying to setup Group Page: ", err);
            this.props.history.push("/");
        }
    }

    getGroupEvents = (groupID) => {
        axios.get(`/api/events/getEvents?groupID=${groupID}`).then((response) => {
            const data = response.data;
            data.sort((a, b) => { return new Date(a.startDate) - new Date(b.startDate) });

            this.setState({ events: data });
        }).catch((err) => { console.log("Error retrieving events data: ", err) });
    }

    //creates a new group
    onSubmitAddEdit = e => {
        e.preventDefault();

        var { modalEvent } = this.state;

        if (modalEvent.endDate < modalEvent.startDate) {
            this.setState({ alertError: true, errorMessage: "Start Date must be before End Date" });

            //close success after 5 seconds
            setTimeout(() => {
                this.setState({ alertError: false });
            }, 5000);
        }
        else {
            var payload;
            if (this.state.edit) {
                payload = {
                    eventID: modalEvent._id,
                    groupID: modalEvent.groupID,
                    userID: modalEvent.userID,
                    eventName: modalEvent.eventName,
                    startDate: modalEvent.startDate,
                    endDate: modalEvent.endDate,
                    maxNumberOfMembers: modalEvent.maxNumberOfMembers
                }

                axios.post(`/api/events/updateEvent?eventID=${modalEvent._id}`, payload).then(res => {
                    if (res.status === 200) {
                        this.handleCloseAddEdit();
                    }
                }).catch(err => {
                    console.log("Unexpected error trying to update an event: ", err);
                }).then(() => {
                    this.getGroupEvents(this.state.groupID);
                });
            }
            else {
                payload = {
                    groupID: this.state.groupID,
                    userID: this.props.auth.user.id,
                    eventName: modalEvent.eventName,
                    startDate: modalEvent.startDate,
                    endDate: modalEvent.endDate,
                    maxNumberOfMembers: modalEvent.maxNumberOfMembers
                }

                axios.post("/api/events/postEvent", payload).then(res => {
                    if (res.status === 200) {
                        this.handleCloseAddEdit();
                    }
                }).catch(err => {
                    console.log("Unexpected error trying to create an event: ", err);
                    this.setState({ alertError: true, errorMessage: "Unexpected error trying to create an event" });

                    //close success after 5 seconds
                    setTimeout(() => {
                        this.setState({ alertError: false, errorMessage: "" });
                    }, 5000);
                }).then(() => {
                    this.getGroupEvents(this.state.groupID);
                });
            }
        }
    };

    onSubmitAttend = e => {
        e.preventDefault();

        //var { modalEvent } = this.state;

            var payload = {
                eventID: this.state.modalEvent._id,
                userID: this.props.auth.user.id,
                username: this.props.auth.user.username,
                email: this.props.auth.user.email
            }

            axios.post("/api/events/attendEvent", payload).then(res => {
                if (res.status === 200) {
                    this.handleCloseEvent();
                }
            }).catch(err => {
                console.log("Unexpected error trying to create a new game: ", err)
            }).then(() => {
                this.getGroupEvents(this.state.groupID);
            });
    }

    onSubmitUnattend = e => {
        e.preventDefault();

        var payload = {
            eventID: this.state.modalEvent._id,
            userID: this.props.auth.user.id,
            username: this.props.auth.user.username,
            email: this.props.auth.user.email,
        }

        axios.post("/api/events/unattendEvent", payload).then(res => {
            if (res.status === 200) {
                this.handleCloseEvent();
            }
        }).catch(err => {
            console.log("Unexpected error trying to create a new game: ", err)
        }).then(() => {
            this.getGroupEvents(this.state.groupID);
        });
    }

    //delete the event
    onSubmitDelete = e => {
        e.preventDefault();

        axios.post(`/api/events/deleteEvent?eventID=${this.state.modalEvent._id}`).then((response) => {
            if (response.status === 200) {
                this.setState({ showEvent: false, showConfirm: false });
            }
        }).catch((err) => { console.log("Unexpected error while trying to delete event: ", err) }).then(() => {
            this.getGroupEvents(this.state.groupID);
        });
    }

    onChange = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        this.setState({ [name]: value });
    };

    onChangeModalEvent = async e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        var { modalEvent } = this.state;
        modalEvent[name] = value;

        this.setState({ modalEvent: modalEvent });
    };

    changeStartDate = (e) => {
        var { modalEvent } = this.state;
        modalEvent.startDate = new Date(e._d);
        this.setState({ modalEvent: modalEvent });
    }

    changeEndDate = (e) => {
        var { modalEvent } = this.state;
        modalEvent.endDate = new Date(e._d);
        this.setState({ modalEvent: modalEvent });
    }

    onChangeNewGame = async e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        var { newGame } = this.state;
        newGame[name] = value;
        this.setState({ newGame: newGame });
    };

    //handlers
    handleShowAdd = () => {
        this.setState({ showAddEdit: true, addEditTitle: "Add Event", add: true });
    }

    handleShowEdit = () => {
        var { modalEvent } = this.state;

        this.setState({
            edit: true,
            showAddEdit: true,
            showEvent: false,
            addEditTitle: "Edit Event",
            eventName: modalEvent.eventName,
            startDate: new Date(modalEvent.startDate),
            endDate: new Date(modalEvent.endDate),
            maxNumberOfMembers: modalEvent.maxNumberOfMembers
        });
    }

    handleCloseAddEdit = () => {
        //reset from variables
        this.setState({
            showAddEdit: false,
            eventName: "",
            startDate: null,
            endDate: null,
            add: false,
            edit: false
        });
    }

    handleShowEvent = (event) => {
        this.setState(prevState => ({
            showEvent: true,
            modalEvent: event,
            newGame: {
                ...prevState.newGame,
                gameName: event.eventName
            }
        }));
    }

    handleCloseEvent = () => {
        this.setState({ showEvent: false, modalEvent: {} });
    }

    handleShowConfirm = () => {
        this.setState({ showConfirm: true });
    }

    handleCloseConfirm = () => {
        this.setState({ showConfirm: false });
    }

    validStart = (current) => {
        var start = moment().subtract(1, "day");
        return current.isAfter(start);
    }

    validEnd = (current) => {
        var start = moment().subtract(1, "day");
        if (!this.state.startDate) {
            return current.isAfter(start);
        }
        else {
            start = moment(this.state.startDate).subtract(1, "day")
            return current.isSameOrAfter(start);
        }
    }

    displayMainEvent = (events) => {
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

        if (!events.length) {
            return (
                <div>
                    <p>No Scheduled Events Upcoming</p>
                </div>
            );
        }
        else {
            var mainEvent = events[0];

            return (
                <div>
                    <h3><strong>{mainEvent.eventName}</strong></h3>
                    <div>{new Date(mainEvent.startDate).toLocaleDateString(undefined, options)}  {new Date(mainEvent.startDate).toLocaleTimeString('en-US')}</div>
                    <div>{new Date(mainEvent.endDate).toLocaleDateString(undefined, options)}  {new Date(mainEvent.endDate).toLocaleTimeString('en-US')}</div>
                    <div><strong>Attending Members: </strong></div>
                    {mainEvent.attendingMembers.map((member, index) => (
                        <div key={index} >{member.username}</div>
                    ))}
                </div>

            );
        }
    }

    displayAlertError = () => {
        if (this.state.alertError) {
            return (
                <Alert variant="danger" className="fade">
                    {this.state.errorMessage}
                </Alert>
            );
        }
        else {
            return null;
        }
    }

    displayEvents = (events) => {
        if (!events) {
            return null;
        }
        else if (!events.length) {
            return null;
        }
        else {
            return (
                <Row xs={1} md={4} className="g-4">
                    {events.map((event, index) => (
                        <Col key={index} >
                            <Card className="box" onClick={() => this.handleShowEvent(event)}>
                                <Card.Header>{event.eventName}</Card.Header>
                                <Card.Body>
                                    <div>{new Date(event.startDate).toDateString()}</div>
                                    <div>{new Date(event.endDate).toDateString()}</div>
                                    <div><strong>Attending Members: </strong></div>
                                    {event.attendingMembers.map((member, index) => (
                                        <div key={index} >{member.username}</div>
                                    ))}


                                </Card.Body>
                                <Card.Footer style={{ background: "#0d6efd", color: "white" }}>
                                    {!event.attendingMembers.some(m => m.userID === this.props.auth.user.id) ? <div style={{ textAlign: "center" }}><span style={{ cursor: "default" }}>Attend</span></div> : <div style={{ textAlign: "center" }}><span style={{ cursor: "default" }}>Unattend</span></div>}
                                </Card.Footer>
                            </Card>
                        </Col>
                    ))}
                </Row>
            );
        }
    }

    memberOptions = (members) => {
        if (!members?.length) {
            return null;
        }
        else {
            return members.map((member, index) => (
                <option key={index} value={member.username}>{member.username}</option>
            ));
        }
    }

    displayAttendButton = () => {
        var {modalEvent} = this.state;
        if (this.state.showEvent) {
            var user = modalEvent.attendingMembers.find(member => member.userID === this.props.auth.user.id);
            if (user != null) {
                return (
                    <Button type="submit" className="mx-2" onClick={this.onSubmitUnattend}>Unattend</Button>
                );
            }
            else {
                return (
                    <Button type="submit" className="mx-2" onClick={this.onSubmitAttend}>Attend</Button>
                );
            }
        }
        else {
            return null;
        }
    }

    displayAttendingMembers = () => {
        if (this.state.showEvent) {
            return this.state.modalEvent.attendingMembers.map((member, index) => (
                <div key={index}>{member.username}</div>
            ));
        }
        else {
            return null;
        }
    }

    displayUpdateButtons = () => {
        if (this.state.showEvent) {
            if (this.state.modalEvent.userID === this.props.auth.user.id) {
                return (
                    <Form>
                        <Button variant="link" onClick={this.handleShowEdit} style={{ marginLeft: 'auto' }}>Edit</Button>
                        <Button variant="link" onClick={this.handleShowConfirm} style={{ marginLeft: 'auto' }}>Delete</Button>
                        <button type="button" onClick={this.handleCloseEvent} className="btn-close" aria-label="Close"></button>
                    </Form>
                );
            }
            else {
                return <button type="button" onClick={this.handleCloseEvent} className="btn-close" aria-label="Close"></button>;
            }
        }

    }

    render() {
        const { events, modalEvent } = this.state;
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

        return (
            <div className="py-3 px-3">
                {/* bg='dark' text='dark' */}
                <Card >
                    <Card.Header>
                        <Navbar expand="lg">
                            <Navbar.Brand>Next Event</Navbar.Brand>
                            <Nav className="me-auto px-1"></Nav>
                            <Nav><Button onClick={this.handleShowAdd}>Add</Button></Nav>
                        </Navbar>
                    </Card.Header>
                    <Card.Body>
                        {/* Next even goes here */}
                        {this.displayMainEvent(events)}
                    </Card.Body>
                </Card>

                <div className="py-3">
                    {this.displayEvents(events)}
                </div>

                {/* ADD NEW EVENT MODAL */}
                <Modal show={this.state.showAddEdit} onHide={this.handleCloseAddEdit} animation={true} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title>{this.state.addEditTitle}</Modal.Title>
                        <button type="button" onClick={this.handleCloseAddEdit} className="btn-close" aria-label="Close"></button>
                    </Modal.Header>

                    <Modal.Body >
                        <Form>
                            <Form.Group>
                                <Form.Label>Event Name</Form.Label>
                                <Form.Control required type="text" id="eventName" value={modalEvent.eventName} onChange={this.onChangeModalEvent} />
                            </Form.Group>

                            <Form.Label>Start Time</Form.Label>
                            <Datetime ref="datetime" isValidDate={this.validStart} id="startDate"
                                value={modalEvent.startDate} onChange={this.changeStartDate} />

                            <Form.Label>End Time</Form.Label>
                            <Datetime ref="datetime" isValidDate={this.validEnd} id="endDate"
                                value={modalEvent.endDate} onChange={this.changeEndDate} />

                            
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        {this.displayAlertError()}
                        <Form>
                            <Button type="submit" onClick={this.onSubmitAddEdit}>
                                Submit
                            </Button>
                            <Button onClick={this.handleCloseAddEdit} style={{ marginLeft: '10px' }}>
                                Cancel
                            </Button>
                        </Form>
                    </Modal.Footer>
                </Modal>

                {/* ON CLICK EVENT MODAL */}
                <Modal show={this.state.showEvent} size="lg" onHide={this.handleCloseEvent} centered animation={true} backdrop="static" keyboard={false}>
                    <Modal.Header className="d-flex">
                        <Modal.Title>{modalEvent.eventName}</Modal.Title>
                        {this.displayUpdateButtons()}
                    </Modal.Header>

                    <Modal.Body style={{ margin: '5px' }}>
                        <div><strong>Start Time: </strong>{new Date(modalEvent.startDate).toLocaleDateString(undefined, options)}  {new Date(modalEvent.startDate).toLocaleTimeString('en-US')}</div>
                        <div><strong>End Time:  </strong>{new Date(modalEvent.endDate).toLocaleDateString(undefined, options)}  {new Date(modalEvent.endDate).toLocaleTimeString('en-US')}</div>
                        {/* <div><strong>Max Number of Players: </strong>{modalEvent.maxNumberOfMembers}</div> */}
                        <div><strong>Attending Members: </strong></div>
                        {this.displayAttendingMembers()}
                    </Modal.Body>
                    <Modal.Footer>
                        <Form>

                            {this.displayAttendButton()}
                            <Button onClick={this.handleCloseEvent} className="mx-2">
                                Cancel
                            </Button>
                        </Form>
                    </Modal.Footer>
                </Modal>

                {/* CONFIRM DELETE MODAL */}
                <Modal show={this.state.showConfirm} onHide={this.handleCloseConfirm} centered animation={true} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <Modal.Title>Delete Event</Modal.Title>
                        <button type="button" onClick={this.handleCloseConfirm} className="btn-close" aria-label="Close"></button>
                    </Modal.Header>

                    <Modal.Body style={{ margin: '5px' }}>
                        Are you sure you wish to delete this event?
                    </Modal.Body>

                    <Modal.Footer>
                        <Button type="submit" onClick={this.onSubmitDelete}>Submit</Button>
                        <Button onClick={this.handleCloseConfirm}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

EventsTab.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(EventsTab);