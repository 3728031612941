import React, { Component } from "react";
import { Link, withRouter  } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser } from "../../actions/authActions";

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

class Register extends Component {
    constructor() {
        super();
        this.state = {
            username: "",
            email: "",
            password: "",
            password2: "",
            errors: {}
        };
    }

    componentDidMount() {
        // If logged in and user navigates to Register page, should redirect them to dashboard
        if (this.props.auth.isAuthenticated) {
          this.props.history.push("/dashboard");
        }
      }

      UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
          this.setState({
            errors: nextProps.errors
          });
        }
      }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSubmit = e => {
        e.preventDefault();
        const newUser = {
            username: this.state.username,
            email: this.state.email,
            password: this.state.password,
            password2: this.state.password2
        };
        
        this.props.registerUser(newUser, this.props.history);
    };

    render() {
        const { errors } = this.state;

        return (
            <div>
            <div className="container align-items-center" style={{ marginTop: "5%", maxWidth:"600px" }}>
              <Card>
                <Card.Body>
                  <div className="row">
                    <div className="col">
                      <Link to="/" className="btn-flat waves-effect">
                        Back to home
                      </Link>
                      <div>
                        <h4>
                          <b>Register</b> below
                        </h4>
                        <p className="grey-text text-darken-1">
                          Already have an account? <Link to="/login">Log in</Link>
                        </p>
                      </div>
                      <Form noValidate onSubmit={this.onSubmit}>
                        <Form.Group controlId="username">
                          <Form.Label>Username</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            value={this.state.username}
                            onChange={this.onChange}
                            error={errors.username}
                            isInvalid={errors.username}
                          />
                          <Form.Control.Feedback type='invalid'>
                            {errors.username}
                          </Form.Control.Feedback>
                        </Form.Group>
                        
                        <Form.Group controlId="email">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            required
                            type="email"
                            value={this.state.email}
                            onChange={this.onChange}
                            error={errors.email}
                            isInvalid={errors.email}
                          />
                          <Form.Control.Feedback type='invalid'>
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="password" >
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            required
                            type="password"
                            value={this.state.password}
                            onChange={this.onChange}
                            error={errors.password}
                            isInvalid={errors.password || errors.passwordincorrect}
                          />
                          <Form.Control.Feedback type='invalid'>
                            {errors.password}
                            {errors.passwordincorrect}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="password2" >
                          <Form.Label>Confirm Password</Form.Label>
                          <Form.Control
                            required
                            type="password"
                            value={this.state.password2}
                            onChange={this.onChange}
                            error={errors.password2}
                            isInvalid={errors.password2}
                          />
                          <Form.Control.Feedback type='invalid'>
                            {errors.password2}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Button type="submit" onClick={this.onSubmit} style={{ margin: 'auto' }}>
                          Register
                        </Button>
                      </Form>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div >
        );
    }
}

Register.propTypes = {
    registerUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { registerUser }
)(withRouter(Register));