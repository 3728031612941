import React, { Component } from "react";
import { Link } from "react-router-dom";
import { matchPath } from "react-router";

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import axios from "axios";

class ChangePassword extends Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      password: "",
      userID: "",
      token: ""
    };
  }

  componentDidMount() {
    var history = this.props.location.pathname + this.props.location.search;
    this.props.history.push(history);

    const match = matchPath(history, {
        path: "/changePassword?userID=:userID&token=:token",
        exact: true, 
        strict: false
    });

    this.setState({
        userID: match.params.userID,
        token: match.params.token
    });
  }


  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const userData = {
      password: this.state.password
    };

    axios
        .post(`/api/users/${this.state.userID}/${this.state.token}`, userData)
        .then(res => {
            if (res.status === 200) {
                this.props.history.push("/login");
            }
        })
        .catch(err => {
            console.log("Error changing password: ", err.response.data);
            this.setState({ errors: err.response.data });
        });
  };

  render() {
    const { errors } = this.state;

    return (
      <div>
        <div className="d-flex flex-column align-items-center" style={{ marginTop: "10%" }}>
          <Card>
            <Card.Body>
              <div className="row">
                <div className="col">
                  <Link to="/" className="btn-flat waves-effect">
                    Back to home
                  </Link>
                  <div>
                    <p className="grey-text text-darken-1">
                      Enter your new password here.
                    </p>
                  </div>

                  <Form noValidate onSubmit={this.onSubmit} >
                    <Form.Group controlId="password">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        required
                        type="password"
                        placeholder="Password"
                        value={this.state.password}
                        onChange={this.onChange}
                        error={errors.password}
                        isInvalid={errors.password}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                   
                    <Button type="submit" onClick={this.onSubmit} style={{ margin: 'auto' }}>
                      Update
                    </Button>
                  </Form>
                  
                </div>
                
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  }
}

// ResetPassword.propTypes = {
//   loginUser: PropTypes.func.isRequired,
//   auth: PropTypes.object.isRequired,
//   errors: PropTypes.object.isRequired
// };

// const mapStateToProps = state => ({
//   auth: state.auth,
//   errors: state.errors
// });

export default ChangePassword;
// connect(
//   mapStateToProps
// )(ResetPassword);