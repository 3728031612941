import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import { withRouter  } from "react-router-dom";
// import _ from 'lodash';
import restService from '../../../utils/restService';

//react imports
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

//custom component imports

class CivGroup extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            isLoading: true,
            variant: "light",
            groupID: "",
            group: {},
            errors: {}, //errors
        };
    }

    //API calls
    componentDidMount() {
        try {
            var history = this.props.history.location.pathname + this.props.history.location.search;
            const match = matchPath(history, {
                path: "/civGroup/*?groupID=:groupID",
                strict: true
            });

            //get leagueID from history URL
            var groupID = match.params.groupID;

            if (this.props.auth.isAuthenticated) {
                this.props.history.push(history);
            }

            this.setState({ groupID: groupID, match: match });
            this.getFullGroup(groupID);
        }
        catch (err) {
            console.warn("Unexpected error trying to setup Group Page: ", err);
            this.props.history.push("/");
        }
    }

    getFullGroup = (groupID) => {
        restService.groups.getFullGroup(groupID).then((response) => {
            const data = response.data;
            this.setState({ group: data, isLoading: false });

            //if group is private and member not included in members list - push them to dashboard
            if (data.isPrivate && !data.members.some(m => m.userID === this.props.auth.user.id)) {
                this.props.history.push("/");
            }
        }).catch((err) => {
            console.log("Error retrieving group data: ", err);
        });
    }

    render() {
        // const { errors } = this.state;
        const { group, match } = this.state;

        if (this.state.isLoading) {
            return null;
        }
        else {
            return (
                <div>
                    <Navbar expand="lg" variant="light" bg="light" collapseOnSelect>
                        <Navbar.Brand className="px-3 py-1">{group.groupName}</Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto px-2" >
                            <Nav.Link href={`/civGroup/welcome?groupID=${group._id}`}>Welcome</Nav.Link>
                            <Nav.Link href={`/civGroup/members?groupID=${group._id}`}>Members</Nav.Link>
                            <Nav.Link href={`/civGroup/main?groupID=${group._id}`}>Active Games</Nav.Link>
                            <Nav.Link href={`/civGroup/rankings?groupID=${group._id}`}>Rankings</Nav.Link>
                            {group.members.some(m => m.userID === this.props.auth.user.id) ? <Nav.Link href={`/civGroup/events?groupID=${group._id}`}>Events</Nav.Link> : null}
                            {group.members.some(m => m.userID === this.props.auth.user.id) ? <Nav.Link href={`/civGroup/preferences?groupID=${group._id}`}>Preferences</Nav.Link> : null}
                            {group.members.some(m => m.userID === this.props.auth.user.id) ? <Nav.Link href={`/civGroup/userSettings?groupID=${group._id}`}>User Settings</Nav.Link> : null}
                            {group.members.some(m => m.userID === this.props.auth.user.id && m.role === "Administrator") ? <Nav.Link href={`/civGroup/groupSettings?groupID=${group._id}`}>Group Settings</Nav.Link> : null}
                            
                        </Nav>
                        { match.params[1] === "civGame" ? 
                            <Nav className="px-4">
                                <Nav.Link href={`/civGroup/main?groupID=${group._id}`}>Back</Nav.Link>
                            </Nav> : null 
                        }
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            );
        }
    }
}

CivGroup.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(withRouter(CivGroup));