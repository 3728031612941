import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import restService from '../../../../utils/restService';

// import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
// import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
// import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import SMMToast from "../../../components/SMMToast";

import civilizations from '../Civilizations';
import _ from "lodash";

//requires a groupID 

class LeaderBans extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            groupID: "",
            group: {
                settings: {
                    maxNumberOfLeaderBans: 5
                }
            },
            civPreferences: [],
            index: 0,
            //render control
            isLoading: true,
            variant: "light",
            showToast: false,
            title: "", 
            message: "",
            leaders: []
        };
    }

    componentDidMount() {
        this.setState({ groupID: this.props.groupID, });
        this.getGroupInfo(this.props.groupID);
    }

    handleCloseToast = () => { this.setState({ showToast: false }); }

    //-----API FUNCTIONS-----//
    //#region API
    getGroupInfo = (groupID) => {
        restService.groups.getGroup(groupID).then((response) => {
            const data = response.data;

            /* 2022-12-28
                FIX THIS: Work around hack to make sure the settings property of groups in state isn't overwritten and the maxLeaderBans is forwarded back to the state.
            */
            const maxLeaderBans = this.state.group.settings.maxNumberOfLeaderBans;
            if (!data.settings || !data.settings?.maxNumberOfLeaderBans)
                data.settings = _.assign(data.settings, { maxNumberOfLeaderBans: maxLeaderBans });

            
            // Replaces the default case of the switch statement, which would return if the game name wasn't in the civ object.
            if (!civilizations[data.gameName]) return;

            // Rest of function is expecting array of Civs, so converting to array.
            this.setState({ group: data, leaders: Object.values(civilizations[data.gameName]) });
        }).catch((err) => {
            console.log("Error retrieving games data: ", err);
        }).then(() => {
            this.getCivPreferences(groupID);
        });
    }

    getCivPreferences = (groupID) => {
        restService.civilization.getCivPreferences(groupID, this.props.auth.user.id).then((response) => {
            const data = response.data;
            const leaders = _.cloneDeep(this.state.leaders);

            data?.leaderBans.forEach(leader => {
                leaders.filter(l => l.civID === leader.civID).map(obj => { return obj.isChecked = true });
            });
            
            this.setState({ civPreferences: data, isLoading: false, leaders: leaders });
        }).catch((err) => {
            console.log("Error retrieving games data: ", err);
        });
    }
    //#endregion

    //-----EVENT FUNCTIONS-----//
    //#region EVENTS
    onSubmit = e => {
        e.preventDefault();

    };

    onChange = e => {
        var { leaders } = this.state;
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const civID = target.id;

        this.setState(leaders.filter(l => `${l.civID}` === civID).map(obj => { return obj.isChecked = value }));

        var leader = leaders.find(l => `${l.civID}` === civID);

        var payload = {
            leader: leader,
            operation: value
        }

        //console.log(payload, this.state);

        restService.civilization.postCivLeaderBanPreference(this.props.groupID, this.props.auth.user.id, payload).then(res => {
            if (res.status === 200) {
                this.setState({ title: "Success!", message: "Successfully saved leader ban choice", showToast: true });
            }
        }).catch(err => {
            console.log("Unexpected error while setting map type preference: ", err);
        });
    };
    //#endregion

    //-----HANDLER FUNCTIONS-----//
    //#region HANDLERS
    handleShow = () => {
        this.setState({ show: true });
    }

    handleClose = () => {
        //reset from variables
        this.setState({ show: false });
    }
    //#endregion

    //-----DISPLAY FUNCTIONS-----//
    //#region DISPLAY
    displayBody = (leaders) => {
        
        var { settings } = this.state.group;
        if (!settings) { return null }
        
        if (this.state.isLoading) {
            return (
                <Spinner animation="border" role="status" variant="secondary" >
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            );
        }
        else {
            
            return (
                <Row xs={1} md={3} className="g-3">
                    {leaders.map((leader, index) => (
                        <Col key={index} >
                            <Form.Check
                                type="checkbox"
                                id={leader.civID}
                                label={leader.leader + " (" + leader.nation + ")"}
                                onChange={this.onChange}
                                checked={leader.isChecked ?? false}
                                disabled={!leader.isChecked && settings.maxNumberOfLeaderBans <= this.state.leaders.filter(l => l.isChecked).length}
                            />
                        </Col>
                    ))}
                </Row>
            );
        }
    }
    //#endregion

    render() {
        var { settings } = this.state.group;
        const { leaders, title, message } = this.state;
        return (
            <div className="py-3 px-3" >
                <Card bg={this.state.variant}>
                    <Card.Header>Leader Bans (Max: {settings.maxNumberOfLeaderBans})</Card.Header>

                    <Card.Body>
                        {this.displayBody(leaders)}
                    </Card.Body>
                </Card>

                <SMMToast show={this.state.showToast} title={title} message={message} />
            </div>
        );
    }
}

LeaderBans.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(LeaderBans);