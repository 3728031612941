import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import restService from '../../../../../utils/restService';
import { withRouter  } from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
// import Table from 'react-bootstrap/Table';
import Placeholder from 'react-bootstrap/Placeholder';

//import _ from "lodash";

//requires a groupID 
class GroupSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            groupID: "",
            group: {},
            index: 0,
            show: false,
            showConfirm: false,
            showSpinner: false,
            //render control
            isLoading: true,
            variant: "light",
            //group settings
            isPrivate: false,
        };
    }

    componentDidMount() {
        this.setState({ groupID: this.props.groupID });
        this.getFullGroup(this.props.groupID);
    }

    //-----API FUNCTIONS-----//
    //#region API
    getFullGroup = (groupID) => {
        restService.groups.getFullGroup(groupID).then((response) => {
            const data = response.data;
            var index;
            switch (data.gameName) {
                case "Civilization 1":
                    index = 0;
                    break;
                case "Civilization 2":
                    index = 1;
                    break;
                case "Civilization 3":
                    index = 2;
                    break;
                case "Civilization 4":
                    index = 3;
                    break;
                case "Civilization 5":
                    index = 4;
                    break;
                case "Civilization 6":
                    index = 5;
                    break;
                default:
                    return;
            }

            this.setState({ group: data, index: index, isPrivate: data.isPrivate, isLoading: false });
        }).catch((err) => {
            console.log("Error retrieving games data: ", err);
        });
    }


    //#endregion

    //-----EVENT FUNCTIONS-----//
    //#region EVENTS
    onSubmit = e => {
        e.preventDefault();

    };

    onChange = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        this.setState({ [name]: value });
    };
    //#endregion

    //-----HANDLER FUNCTIONS-----//
    //#region HANDLERS
    handleShow = () => {
        this.setState({ show: true });
    }

    handleClose = () => {
        //reset from variables
        this.setState({ show: false });
    }

    handleShowConfirm = () => {
        this.setState({ showConfirm: true });
    }

    handleCloseConfirm = () => {
        this.setState({ showConfirm: false });
    }
    

    onSubmitDelete = e => {
        e.preventDefault();

        this.setState({ showSpinner: true, showConfirm: false });

        restService.groups.deleteGroup(this.state.groupID).then(res => {
            if (res.status === 200) {
                this.setState({ showSpinner: false });

                this.props.history.push("/dashboard");
            }
        }).catch(err => {
            this.setState({ showSpinner: false });
            console.log("Unexpected erro while attempting to delete group: ", err);
        });

    }

    onSubmitUpdateGroup = e => {
        var payload = {
            isPrivate: this.state.isPrivate,
        }

        restService.civilization.postGroupSettings(this.state.group._id, payload).then(res => {
            this.handleClose();
            const data = res.data;
            this.setState({ group: data, isPrivate: data.isPrivate });
        }).catch(err => {
            console.log("Unexpected error trying to update an group: ", err);
        });
    }
    //#endregion

    //-----DISPLAY FUNCTIONS-----//
    //#region DISPLAY
    displayBody = (group, isLoading) => {
        if (isLoading) {
            return (
                <div>
                    <Placeholder as="div" animation="wave"><Placeholder xs={2} size="lg" /></Placeholder>
                    <Placeholder as="div" animation="wave"><Placeholder xs={1} /></Placeholder>
                    <Placeholder as="div" animation="wave"><Placeholder xs={2} size="lg" /></Placeholder>
                    <Placeholder as="div" animation="wave"><Placeholder xs={8} /></Placeholder>
                </div>
            );
        }
        else {
            return (
                <div>
                    <h5>Group Privacy</h5>
                    <div>{group.isPrivate ? "Private Group" : "Public Group"}</div>

                    <h5 style={{ marginTop: '10px' }}>Invite Link</h5>
                    <div >{`https://squadmatchmake.ca/invite?groupID=${group._id}&inviteLink=${group.inviteLink}`}</div>
                </div>

            )
        }
    }

    copyLink = (group) => {
        try {
            navigator.clipboard.writeText(`https://squadmatchmake.ca/invite?groupID=${group._id}&inviteLink=${group.inviteLink}`);
            this.setState({ alert: true, alertMessage: "Copied!", variant: "success" });
            //close success after 5 seconds
            setTimeout(() => {
                this.setState({ alert: false, alertMessage: "", variant: "" });
            }, 5000);
        }
        catch (err) {
            this.setState({ alert: true, alertMessage: "Error", variant: "danger" });

            //close success after 5 seconds
            setTimeout(() => {
                this.setState({ alert: false, alertMessage: "", variant: "" });
            }, 5000);
        }
    }

    displayEditSettings = (group) => {
        if (!group) {
            return null;
        }
        else {
            return (
                <div className="py-3 px-3">
                    <Form>
                        <Form.Group>
                            <Form.Label><strong>Group Privacy</strong></Form.Label>
                            <Form.Check
                                type="checkbox"
                                id="isPrivate"
                                label={this.state.isPrivate ? "Private Group" : "Public Group"}
                                onChange={this.onChange}
                                checked={this.state.isPrivate}
                            />
                        </Form.Group>

                        {/* <Form.Group>
                        <Form.Label><strong>Group Victories</strong></Form.Label>
                        <Form.Check
                            type="checkbox"
                            id="allowMultipleWinners"
                            label={this.state.allowMultipleWinners ? "Allow Multiple Victors" : "Single Victors"}
                            onChange={this.onChange}
                            checked={this.state.allowMultipleWinners}
                            style={{ margin: '5px' }}
                        />
                    </Form.Group> */}

                        <Form.Group className="py-3" >
                            <Button onClick={this.onSubmitUpdateGroup}>Save</Button>
                        </Form.Group>
                    </Form>


                </div>
            );
        }
    }

    displayDeleteGroup = (group) => {
        if (!group) {
            return null;
        }
        else if (group.groupOwner === this.props.auth.user.id) {
            return <Button variant="link" onClick={this.handleShowConfirm} style={{ marginLeft: 'auto' }}>Delete</Button>;
        }
        else {
            return null;
        }
    }
    //#endregion

    render() {
        const { group, isLoading, showSpinner } = this.state;

        return (
            <div className="py-3 px-3" >
                <Card bg={this.state.variant}>
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        Settings
                        <Button onClick={this.handleShow}>Edit</Button>
                    </Card.Header>
                    <Card.Body>
                        {this.displayBody(group, isLoading)}
                    </Card.Body>
                </Card>

                {/* SHOW SETTINGS MODAL! */}
                <Modal size="xl" show={this.state.show} onHide={this.handleClose} centered animation={true} backdrop="static" keyboard={false}>
                    <Modal.Header className="d-flex justify-content-between align-items-center">
                        <Modal.Title>Edit Group Settings</Modal.Title>
                        {this.displayDeleteGroup(group)}
                        <button type="button" onClick={this.handleClose} className="btn-close" style={{ marginLeft: '0px' }} aria-label="Close"></button>
                    </Modal.Header>
                    
                    {showSpinner ? <Spinner animation="border" role="status" variant="secondary" ><span className="visually-hidden">Loading...</span></Spinner> : this.displayEditSettings(group)}
                </Modal>

                {/* CONFIRM DELETE */}
                <Modal show={this.state.showConfirm} onHide={this.handleCloseConfirm} centered animation={true} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <Modal.Title>Delete Group</Modal.Title>
                        <button type="button" onClick={this.handleCloseConfirm} className="btn-close" aria-label="Close"></button>
                    </Modal.Header>

                    <Modal.Body style={{ margin: '5px' }}>
                        Are you sure you wish to permanently delete this group?
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={this.onSubmitDelete} as={Col} xs={2} className="px-1">Submit</Button>
                        <Button onClick={this.handleCloseConfirm} as={Col} xs={2} className="px-1">Cancel</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );

    }
}

GroupSettings.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(withRouter(GroupSettings));