import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import restService from '../../../../utils/restService';

// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
// import Form from 'react-bootstrap/Form';
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';

//requires a groupID 

class RankingsTeams extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            groupID: "",
            group: "",
            members: [],
            games: [],
            //render control
            isLoading: true,
            variant: "light",
        };
    }

    componentDidMount() {
        this.setState({ groupID: this.props.groupID });
        this.getFullGroup(this.props.groupID);
    }

    //-----API FUNCTIONS-----//
    //#region API
    getFullGroup = (groupID) => {
        restService.groups.getFullGroup(groupID).then((response) => {
            const data = response.data;
            this.setState({ group: data });
        }).catch((err) => {
            console.log("Error retrieving games data: ", err);
        }).then(() => {
            this.getGameInfo(groupID);
        });
    }

    getGameInfo = (groupID) => {
        restService.civilization.getFinishedGames(groupID, "team").then((response) => {
            const data = response.data;
            this.setState({ games: data });
        }).catch((err) => {
            console.log("Unexpected error retrieving games data: ", err)
        }).then(() => {
            this.setState({ isLoading: false });
        });
    }
    //#endregion

    //-----EVENT FUNCTIONS-----//
    //#region EVENTS
    onSubmit = e => {
        e.preventDefault();

    };

    onChange = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        this.setState({ [name]: value });
    };
    //#endregion

    //-----HANDLER FUNCTIONS-----//
    //#region HANDLERS
    handleShow = () => {
        this.setState({ show: true });
    }

    handleClose = () => {
        //reset from variables
        this.setState({ show: false });
    }
    //#endregion

    //-----DISPLAY FUNCTIONS-----//
    //#region DISPLAY
    displayHeaders = () => {
        return (
            <tr>
                <th>Player</th>
                <th>Games Played</th>
                {/* Dynamic Victory Conditions */}
                <th>Total</th>
            </tr>
        );
    }

    displayRankings = (games, members) => {
        if (!games && !members) { return null; }
        else if (!games.length && !members.length) { return null; }
        else {
            try {
                var rankings = [];
                members.forEach(member => {
                    rankings.push({
                        userID: member.userID,
                        username: member.username,
                        team: member.team,
                        gamesPlayed: 0,
                        score: 0,
                        science: 0,
                        diplomatic: 0,
                        domination: 0,
                        religion: 0,
                        culture: 0,
                        territorial: 0,
                        total: 0
                    });
                });

                games.forEach(game => {
                    game.members.forEach(member => {
                        var player = rankings.find(r => r.userID === member.userID);
                        if (player) { player.gamesPlayed += 1; }
                    });
                    
                    var winners = game.members.filter(m => m.team === game.winner);
                    if (winners) {
                        winners.forEach(winner => {
                            let user = rankings.find(e => e.userID === winner.userID);
                            user[game.victoryType.toLowerCase()] += 1;
                            user.total += 1
                        });
                    }
                });

                return rankings.map((member, index) => (
                    <tr key={index}>
                        <th>{index+1}</th>
                        <th>{member.username}</th>
                        <th>{member.gamesPlayed}</th>
                        <th>{member.score}</th>
                        <th>{member.science}</th>
                        <th>{member.diplomatic}</th>
                        <th>{member.domination}</th>
                        <th>{member.religion}</th>
                        <th>{member.culture}</th>
                        <th>{member.territorial}</th>
                        <th>{member.total}</th>
                    </tr>
                ));
            }
            catch (err) {
                console.log("Unexpected error setting up group rankings: ", err);
            }
        }
    }

    displayBody = (games, members) => {
        if (!this.state.isLoading && members.length) {
            return (
                <Table responsive striped bordered hover variant={this.state.variant} >
                    <thead>
                        <tr>
                            <th></th>
                            <th>Player</th>
                            <th>Games Played</th>
                            <th>Score</th>
                            <th>Science</th>
                            <th>Diplomatic</th>
                            <th>Domination</th>
                            <th>Religion</th>
                            <th>Culture</th>
                            <th>Territorial</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.displayRankings(games, members)}
                    </tbody>
                </Table>
            );
        }
        else {
            return (
                <Spinner animation="border" role="status" variant="secondary" >
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            );
        }
    }
    //#endregion

    render() {
        const { games, group } = this.state;
        return (
            <div className="py-3 px-3">
                <Card bg={this.state.variant}>
                    <Card.Header>Rankings - Teams</Card.Header>

                    <Card.Body>
                        {this.displayBody(games, group.members)}
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

RankingsTeams.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(RankingsTeams);