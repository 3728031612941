import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';


class ResetPassword extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      errors: {}
    };
  }

  componentDidMount() {
    this.props.history.push("/resetPassword");
  }


  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const userData = {
      email: this.state.email
    };

    axios
        .post("/api/users/resetPassword", userData)
        .then(res => {
            // if (res.status === 200) {
            //     this.openSuccessModal();
            // }
        })
        .catch(err => console.log("Error posting message to EstateSafe: ", err))
        .then(() => {
            this.setState({
                email: "",
            });
        });
  };

  render() {
    const { errors } = this.state;

    return (
      <div>
        <div className="d-flex flex-column align-items-center" style={{ marginTop: "10%" }}>
          <Card>
            <Card.Body>
              <div className="row">
                <div className="col">
                  <Link to="/" className="btn-flat waves-effect">
                    Back to home
                  </Link>
                  <div>
                    <p className="grey-text text-darken-1">
                      Enter your email here to receive a reset password email.
                    </p>
                  </div>

                  <Form noValidate onSubmit={this.onSubmit} >
                    <Form.Group controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        required
                        type="email"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={this.onChange}
                        error={errors.email}
                        isInvalid={errors.email || errors.emailnotfound}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.email}
                        {errors.emailnotfound}
                      </Form.Control.Feedback>
                    </Form.Group>
                   
                    <Button type="submit" onClick={this.onSubmit} style={{ margin: 'auto' }}>
                      Send
                    </Button>
                  </Form>
                  
                </div>
                
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  }
}

// ResetPassword.propTypes = {
//   loginUser: PropTypes.func.isRequired,
//   auth: PropTypes.object.isRequired,
//   errors: PropTypes.object.isRequired
// };

// const mapStateToProps = state => ({
//   auth: state.auth,
//   errors: state.errors
// });

export default ResetPassword;
// connect(
//   mapStateToProps
// )(ResetPassword);