import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// import Modal from 'react-bootstrap/Modal';
// import Form from 'react-bootstrap/Form';
// import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
// import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
// import SMMToast from "../../../components/SMMToast";

import defaultGameSettings from '../GameSettings';

class GameSettingsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            show: false,
            errors: {},
            game: {},
            members: [],
            notes: "",
            edit: false,
            value: "",
            gameSettings: null,
            gameModes: null,
            victoryTypes: null,
            tradingAndDiplomacy: null
        };
    }

    componentDidMount() {
        const game = this.props.game;

        var gameSettings = defaultGameSettings["Civilization 6"]?.gameSettings;
        var gameModes = defaultGameSettings["Civilization 6"]?.gameModes;
        var victoryTypes = defaultGameSettings["Civilization 6"]?.victoryTypes;
        var tradingAndDiplomacy = defaultGameSettings["Civilization 6"]?.tradingAndDiplomacy;

        console.log("Logging gameSettings: ", gameSettings);

        game.settings?.gameSettings?.forEach(setting => {
            console.log("Logging setting: ", setting);
            var item = gameSettings.find(s => s.title === setting.title);
            item.isEnabled = setting.isEnabled;
            item.value = setting.value;

            //console.log("Logging item: ", item);
        });

        game.settings?.gameModes?.forEach(mode => {
            var item = gameModes.find(s => s.title === mode.title);
            item.isEnabled = mode.isEnabled;
            item.value = mode.value;
        });

        game.settings?.victoryTypes?.forEach(victory => {
            var item = victoryTypes.find(s => s.title === victory.title);
            item.isEnabled = victory.isEnabled;
            item.value = victory.value;
        });

        game.settings?.tradingAndDiplomacy?.forEach(tnd => {
            var item = tradingAndDiplomacy.find(s => s.title === tnd.title);
            item.isEnabled = tnd.isEnabled;
            item.value = tnd.value;
        });

        this.setState({ isLoading: false, game: this.props.game, gameSettings: game.settings?.gameSettings, gameModes: game.settings?.gameModes, victoryTypes: game.settings?.victoryTypes, tradingAndDiplomacy: game.settings?.tradingAndDiplomacy });
    }

    displayGameSettingsBody = () => {
        var { gameSettings } = this.state;
        if (!gameSettings) { return null }

        if (this.state.isLoading) {
            return (
                <Spinner animation="border" role="status" variant="secondary" >
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            );
        }
        else {
            return (
                gameSettings.map((setting, index) => (
                    <div key={index}><strong>{setting.title}: </strong>{setting.value != null ? setting.value : setting.defaultValue}</div>
                ))
            );
        }
    }

    displayGameModesBody = () => {
        var { gameModes } = this.state;
        if (!gameModes) { return null }

        if (this.state.isLoading) {
            return (
                <Spinner animation="border" role="status" variant="secondary" >
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            );
        }
        else {
            return (
                gameModes.map((setting, index) => (
                    <div key={index}><strong>{setting.title}: </strong>{setting.value != null ? setting.value ? "Yes" : "No" : setting.defaultValue ? "Yes" : "No"}</div>
                ))
            );
        }
    }

    displayVictoryTypesBody = () => {
        var { victoryTypes } = this.state;
        if (!victoryTypes) { return null }

        if (this.state.isLoading) {
            return (
                <Spinner animation="border" role="status" variant="secondary" >
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            );
        }
        else {
            return (
                victoryTypes.map((setting, index) => (
                    <div key={index}><strong>{setting.title}: </strong>{setting.value != null ? setting.value : setting.defaultValue}</div>
                ))
            );
        }
    }

    displayTradingAndDiplomacyBody = () => {
        var { tradingAndDiplomacy } = this.state;
        if (!tradingAndDiplomacy) { return null }

        if (this.state.isLoading) {
            return (
                <Spinner animation="border" role="status" variant="secondary" >
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            );
        }
        else {
            return (
                tradingAndDiplomacy.map((setting, index) => (
                    <div key={index}><strong>{setting.title}: </strong>{setting.value != null ? setting.value ? "Yes" : "No" : setting.defaultValue ? "Yes" : "No"}</div>
                ))
            );
        }
    }

    render() {
        //const { gameSettings } = this.state;

        if (this.state.isLoading) {
            return null;
        }
        else {
            return (
                <div className="py-3 px-3">


                    <Card >
                        <Card.Header>
                            Game Settings
                        </Card.Header>
                        <Card.Body>
                            <Row xs={1} sm={1} md={1} lg={4}>
                                <Col>
                                    <Card.Title>Map and Game Options</Card.Title>
                                    {this.displayGameSettingsBody()}
                                </Col>
                                <Col>
                                    <Card.Title>Game Modes</Card.Title>
                                    {this.displayGameModesBody()}
                                </Col>
                                <Col>
                                    <Card.Title>Victory Conditions</Card.Title>
                                    {this.displayVictoryTypesBody()}
                                </Col>
                                <Col>
                                    <Card.Title>Trading and Diplomacy</Card.Title>
                                    {this.displayTradingAndDiplomacyBody()}
                                </Col>

                            </Row>

                        </Card.Body>
                    </Card>
                </div>
            );
        }
    }
}

GameSettingsTab.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(GameSettingsTab);