import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import restService from '../../../../utils/restService';
import _ from 'lodash';

import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
// import Navbar from 'react-bootstrap/Navbar';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// import Table from 'react-bootstrap/Table';

class UserLeaderAgenda extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            show: false,
            errors: {},
            game: {},
            members: [],
            userLeaderAgenda: "",
            edit: false,
            value: "",
        };
    }

    componentDidMount() {
        this.getUserNotes();
    }

    getUserNotes = () => {
        restService.civilization.getCivPreferences(this.props.groupID, this.props.auth.user.id).then((response) => {
            const data = response.data;

            if (data != null) {
                this.setState({ userLeaderAgenda: data.userLeaderAgenda });
            }

            
            this.setState({ isLoading: false });
        }).catch((err) => { console.log("Error retrieving user notes data: ", err) });
    }

    //edit current game
    onSubmitEdit = e => {
        e.preventDefault();

        this.setState({ edit: true });
    };

    onSubmitSave = e => {
        e.preventDefault();
        this.setState({ isLoading: true });

        var payload = {
            userLeaderAgenda: this.state.userLeaderAgenda
        };

        restService.civilization.postUserLeaderAgenda(this.props.groupID, this.props.auth.user.id, payload).then((response) => {
            this.setState({ userLeaderAgenda: response.data.userLeaderAgenda, edit: false });
        }).catch((err) => {
            console.log("Error posting User Leader Agenda: ", err)
        }).then(() => {
            this.setState({ isLoading: false });
        });

        // axios.post(`/api/civilization/postUserLeaderAgenda/`, payload).then((response) => {
        //     this.setState({ notes: response.data.notes, edit: false });
        // }).catch((err) => {
        //     console.log("Error posting notes: ", err)
        // }).then(() => {
        //     this.setState({ isLoading: false });
        // });
    };

    onChange = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        this.setState({ [name]: value });
    };


    displayUserLeaderAgenda = () => {
        if (this.state.edit) {
            return (
                <Form>
                    <Form.Group className="mb-3" controlId="userLeaderAgenda">
                        <Form.Control as="textarea" rows={4} value={this.state.userLeaderAgenda} onChange={this.onChange} />
                    </Form.Group>
                </Form>
            );
        }
        else {
            var text = this.state.userLeaderAgenda;

            return (
                <div>
                    {text.split("\n").map((i, key) => {
                        return <div key={key}>{i}</div>;
                    })}
                </div>
            );
        }
    }

    displaySubmit = () => {
        if (this.state.edit) {
            return (
                <Button type="submit" onClick={this.onSubmitSave}>
                    Save
                </Button>
            );
        }
        else {
            return (
                <Button type="submit" onClick={this.onSubmitEdit}>
                    Edit
                </Button>
            );
        }
    }

    render() {
        if (this.state.isLoading) {
            return null;
        }
        else {
            return (
                <div className="py-3 px-3">
                    <Card>
                        <Card.Header>User Leader Agenda</Card.Header>
                        <Card.Body>{this.displayUserLeaderAgenda()}</Card.Body>
                        <Card.Footer>{this.displaySubmit()}</Card.Footer>
                    </Card>
                </div>
            );
        }
    }
}

UserLeaderAgenda.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(UserLeaderAgenda);