import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from 'axios';

import { showAlert, hideAlert } from '../../actions/alertActions';
import AlertRoot from "../components/AlertRoot";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            email: "",
            subject: "",
            message: "",
            messageType: "Feedback",
        };
    }

    componentDidMount() {
        this.props.history.push("/contact");
    }

    //OPEN SUCCESS Alert
    openSuccessAlert(message, title, type) {
        this.props.showAlert({
            open: true,
            title: title,
            message: message,
            closeAlert: this.closeAlert
        }, type)
    }

    //CLOSE Alert
    closeAlert = () => {
        this.props.hideAlert()
    }

    //creates a new group
    onSubmit = e => {
        e.preventDefault();

        var payload = {
            email: this.state.email,
            subject: this.state.subject,
            message: this.state.message,
            messageType: this.state.messageType
        };

        axios.post("/api/admin/message", payload).then(res => {
            if (res.status === 200) {
                var successMessage = "Successfully sent message to SquadMatchMake";
                var title = "Success";
                var type = 'success';

                //show success
                this.openSuccessAlert(successMessage, title, type);

                //close success after 5 seconds
                setTimeout(() => {
                    this.closeAlert()
                }, 5000);
            }
        }).catch(err => {
            console.log("Unexpected error while sending message: ", err);
            try {
                this.setState({ errors: err.response.data });
            }
            catch (err) {
                console.log("Unexpected error while trying to set error to state: ", err);
            }
        });

        this.setState({
            email: "",
            subject: "",
            message: "",
            messageType: ""
        });
    };

    onChange = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        this.setState({ [name]: value });
    };


    render() {
        const { errors } = this.state;

        return (
            <div>
                <AlertRoot hideAlert={this.props.hideAlert} />
                <div>
                    <Card className="mx-3 my-3">
                        <Card.Header>Join our Discord Server</Card.Header>
                        <Card.Body>
                            <a href="https://discord.gg/PB2ChxbjdN">https://discord.gg/PB2ChxbjdN</a>

                        </Card.Body>
                    </Card>

                    <Card className="mx-3 my-3">
                        <Card.Header>Send us an email</Card.Header>
                        <Card.Body>
                            <Card.Title></Card.Title>
                            {/* <Card.Text>
                                    StartUp Company will get back 
                                </Card.Text>
                                <Card.Text>
                                    1-833-429-1190
                                </Card.Text> */}
                            <Form>
                                <Form.Group controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control required type="email" value={this.state.email}
                                        onChange={this.onChange} placeholder="Email" error={errors.email}
                                        isInvalid={errors.email} />
                                    <Form.Control.Feedback type='invalid'> {errors.email} </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group style={{ marginTop: '5px' }}>
                                    <Form.Label>Message Type</Form.Label>
                                    <Form.Control as="select" id="messageType" aria-label="Message Type"
                                        value={this.state.messageType} onChange={this.onChange} >
                                        <option value="Feedback">Feedback</option>
                                        <option value="Error/Bug">Error/Bug</option>
                                        <option value="Support">Support</option>
                                        <option value="Suggestion">Suggestion</option>
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="subject">
                                    <Form.Label>Subject</Form.Label>
                                    <Form.Control required type="text" value={this.state.subject}
                                        onChange={this.onChange} placeholder="Subject" error={errors.subject}
                                        isInvalid={errors.subject} />
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.subject}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="message">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control required as="textarea" rows={4}
                                        value={this.state.message} onChange={this.onChange}
                                        placeholder="Message" error={errors.mesage}
                                        isInvalid={errors.message} />
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.message}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Button variant="primary" type="submit" onClick={this.onSubmit}>
                                    Submit
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>


                </div>
            </div>
        );
    }
}

Contact.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => ({
    hideAlert: () => dispatch(hideAlert()),
    showAlert: (alertProps, alertType) => {
        dispatch(showAlert({ alertProps, alertType }))
    }
});

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Contact);