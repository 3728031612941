import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import restService from '../../../../utils/restService';
import _ from 'lodash';

import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
// import Form from 'react-bootstrap/Form';
// import Col from 'react-bootstrap/Col';
// import Row from 'react-bootstrap/Row';
//import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
// import Tab from 'react-bootstrap/Tab';
//import Card from 'react-bootstrap/Card';
// import Alert from 'react-bootstrap/Alert';
//import Navbar from 'react-bootstrap/Navbar';
import Spinner from 'react-bootstrap/Spinner';
import Accordion from 'react-bootstrap/Accordion';

class ArchivedGamesFFA extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            groupID: "",
            group: {},
            members: [],
            games: [],
            //render control
            isLoading: true,
            variant: "light",
            //filter modal options
            filteredGames: [],
            filteredMembers: false,
            filterType: "Is",
        };
    }

    componentDidMount() {
        this.setState({
            groupID: this.props.groupID,
        });

        this.getFullGroup(this.props.groupID);
    }

    //-----API FUNCTIONS-----//
    //#region API
    getFullGroup = (groupID) => {
        restService.groups.getFullGroup(groupID).then((response) => {
            const data = response.data;
            this.setState({ group: data });
        }).catch((err) => {
            console.log("Error retrieving games data: ", err);
        }).then(() => {
            this.getGameInfo(groupID);
        });
    }

    getGameInfo = (groupID) => {
        restService.civilization.getFinishedGames(groupID, "ffa").then((response) => {
            const data = response.data;
            this.setState({ games: data, filteredGames: _.cloneDeep(data) });
        }).catch((err) => {
            console.log("Unexpected error retrieving games data: ", err)
        }).then(() => {
            this.setState({ isLoading: false });
        });
    }

    //#endregion

    //-----EVENT FUNCTIONS-----//
    //#region EVENTS
    onChange = async e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        this.setState({ [name]: value });
    };
    //#endregion

    //-----HANDLER FUNCTIONS-----//
    //#region HANDLERS

    //#endregion

    //-----DISPLAY FUNCTIONS-----//
    //#region DISPLAY
    //populates tab main game data table
    displayGameData = (games) => {
        if (!games) {
            return null;
        }
        else if (!games.length) {
            return null
        }
        else {
            return games.map((game, index) => (
                <tr key={index}>
                    <td><strong><Button variant="link" href={`/civGroup/civGame?groupID=${this.state.group._id}&gameID=${game._id}`}>{game.gameName}</Button></strong></td>
                    <td>
                        {game.members.find(m => m.userID === game.winner).username}
                    </td>
                    <td>{game.victoryType}</td>
                    <td>{new Date(game.dateCreated).toDateString()}</td>
                </tr>
            ));
        }
    }

    displayBody = (filteredGames) => {
        if (!this.state.isLoading) {
            return (
                <Table responsive striped bordered hover variant={this.state.variant}>
                    <thead>
                        <tr>
                            <th>Game Name</th>
                            <th>Winner</th>
                            <th>Victory Type</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.displayGameData(filteredGames)}
                    </tbody>
                </Table>
            );
        }
        else {
            return (
                <Spinner animation="border" role="status" variant="secondary" >
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            );
        }
    }
    //#endregion

    render() {
        const { games } = this.state; //, variant

        return (
            <div className="py-3 px-3">
                <Accordion defaultActiveKey="1">
                    <Accordion.Item >
                        <Accordion.Header>Archived Games - FFA</Accordion.Header>
                        <Accordion.Body>
                            {this.displayBody(games)}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        );
    }
}

ArchivedGamesFFA.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(ArchivedGamesFFA);