import civilizations from '../Civilizations';
import mapTypes from '../MapTypes';
import _ from 'lodash'
import restService from '../../../../utils/restService';

import defaultGameSettings from '../GameSettings';

const civDraft = async (group, newGame) => {
    try {
        let groupSettings = group.settings;
        let maxNumberOfLeaderBans = parseInt(group.settings?.maxNumberOfLeaderBans);

        const removeDupNations = newGame.removeDupNations;
        const removeDupLeaders = newGame.removeDupLeaders;
        const bansRemovePicks = newGame.bansRemovePicks;
        const freeBansCount = parseInt(newGame.freeBans) ?? maxNumberOfLeaderBans;
        const minNumOfPicks = 1;
        const defaultSettings = defaultGameSettings[group.gameName];
        let civGameSettings = _.cloneDeep(defaultSettings);

        // AK: 2022-12-28 Enhanced Drafting Algorithm

        var civsPerPlayer = parseInt(newGame.numCiv) ?? 5;

        let gamePlayers = group.members.reduce(
            (r, member) => {
                if (!member.isCheckedAdd) return r;
                let currentMember = _.omit(member, ['isCheckedAdd', 'dateJoined', 'isBanned', 'role']);
                _.assign(currentMember, { civChoices: [], isActive: true, maxChoices: civsPerPlayer });
                r.push(currentMember);
                return r;
            }, []
        );

        const numberOfPlayers = gamePlayers.length;
        let map;
        let mapResults = [];
        let bannedLeaders = new Set();
        let dupLeaders = new Set();

        if (newGame.randomTeams) {
            RandomizePlayers(gamePlayers, newGame.teamCount);
        }

        // If draftMode is falsey, return a resolved promise with the ? undefined map ? and selected game players.
        if (!newGame.draftMode) return Promise.resolve({ map: map, result: gamePlayers, bannedLeaders: bannedLeaders, settings: civGameSettings });

        let civPool = _.cloneDeep(civilizations[group.gameName]);

        // Replaces the default case of the switch statement, which would return if the game name wasn't in the civ object.
        if (!civPool) {
            return Promise.reject({ error: "Failure to retrieve civilizations" });
        }

        let draftPool = Object.keys(civPool);


        if (numberOfPlayers * civsPerPlayer > civPool.length) civsPerPlayer = Math.floor(civPool.length / numberOfPlayers);

        // get all players preferences
        const preferencesData = await restService.civilization.getMemberCivPreferences(group._id, gamePlayers.map(p => p.userID));
        const preferences = preferencesData.data;

        preferences.forEach(playerPreference => {

            let player = gamePlayers.find(player => playerPreference.userID === player.userID);
            player.leaderBans = playerPreference.leaderBans;

            //console.log(player.username, player.leaderBans);

            player.leaderBans.forEach(bannedLeader => {
                let bannedIndex = draftPool.indexOf(String(bannedLeader.civID));
                if (bannedIndex === -1) return;
                draftPool.splice(bannedIndex, 1);
                bannedLeaders.add(`${bannedLeader.nation} - ${bannedLeader.leader}`);
            });

            if (bansRemovePicks) {
                player.maxChoices = civsPerPlayer - player.leaderBans.length + freeBansCount;
                player.maxChoices = Math.min(civsPerPlayer, player.maxChoices);
                player.maxChoices = Math.max(minNumOfPicks, player.maxChoices);
            }
        });

        for (let property in defaultSettings) {
            let result = preferences.map(p => { return p[property] }).flat(1);

            //let pool = [];
            defaultSettings[property].forEach(setting => {
                try {
                    //check if setting is locked
                    let isEnabled = group.settings[property]?.find(g => g.title === setting.title)?.isEnabled ?? true;

                    // if isEnabled -  pick value from setting based on player preferences and set
                    if (isEnabled) {
                        let pool = result.filter(r => r.title === setting.title).map(r => { return r.value });
                        if (pool.length !== gamePlayers.length) {
                            if (pool.length) {
                                _.fill(pool, setting.defaultValue, pool.length - 1, gamePlayers.length);
                            }
                            else {
                                pool.push(setting.defaultValue);
                            }
                        }

                        // pool has been created
                        // different algorithms can be applied here

                        // 1. Randomly pick from pool algorithm
                        _.shuffle(pool);
                        let value = pool[0];
                        let index = civGameSettings[property].findIndex(s => s.title === setting.title);
                        if (index > -1) {
                            civGameSettings[property][index].value = value;
                        }
                    }
                    else {
                        //else - set and continue
                        
                        let lockedValueIndex = groupSettings[property].findIndex(s => s.title === setting.title);
                        if (lockedValueIndex > -1) {
                            civGameSettings[property][lockedValueIndex].value = groupSettings[property][lockedValueIndex].lockedValue ?? setting.defaultValue;
                            
                            //console.log("Logging s.title: ", s.title);
                            // console.log("Logging setting.title: ", setting.title);
                            // console.log("Logging lockedValueIndex: ", lockedValueIndex);
                            // console.log("Logging property: ", property);
                            // console.log("Logging civGameSettings[property][lockedValueIndex].value: ", civGameSettings[property][lockedValueIndex].value);
                        }
                        else {
                            console.log("here instead")
                            let index = civGameSettings[property].findIndex(s => s.title === setting.title);
                            if (index > -1) {
                                civGameSettings[property][index].value = setting.defaultValue;
                            }
                        }

                    }
                }
                catch (err) {
                    console.warn("Logging error trying to set player preferences: ", err);
                }
            });
        }

        if (newGame.randomMap) {

            if (preferences?.length) {
                preferences?.forEach(pref => {
                    mapResults = mapResults.concat(pref.maps);
                });

                mapResults = mapResults.filter(m => m.minPlayers <= numberOfPlayers);

                //Mapresults with preferences
                if (mapResults?.length) {
                    map = mapResults[Math.floor(Math.random() * mapResults.length)];
                }
                //map results with preferences but no maps picked
                else {
                    mapResults = mapTypes[group.gameName].filter(m => m.minPlayers <= numberOfPlayers);
                    map = mapResults[Math.floor(Math.random() * mapResults.length)];
                }
            }
            //map results with no preferences 
            else {
                mapResults = mapTypes[group.gameName].filter(m => m.minPlayers <= numberOfPlayers);
                map = mapResults[Math.floor(Math.random() * mapResults.length)];
            }
        }

        while (!gamePlayers.every(player => player.civChoices.length === player.maxChoices) && draftPool.length > 0) {
            gamePlayers.reverse().forEach(player => {
                //console.log("Working on:", player.username, player.civChoices.length, "/", player.maxChoices, player.civChoices.length === player.maxChoices, "Civs Left", draftPool.length);

                if (player.civChoices.length === player.maxChoices) return;

                let pickedCivIndex = Math.floor((draftPool.length) * Math.random());
                let pickedCivID = draftPool[pickedCivIndex];
                let pickedCiv = civPool[pickedCivID];
                //console.log("Adding Civ to:", player.username, player.civChoices.length, pickedCivIndex, pickedCiv);
                player.civChoices.push({ leader: pickedCiv.leader, nation: pickedCiv.nation });
                draftPool.splice(pickedCivIndex, 1);

                if (removeDupNations) {
                    pickedCiv.dupNations?.forEach(nationIndex => {
                        let dupNationIndex = draftPool.indexOf(String(nationIndex));
                        if (dupNationIndex === -1) return;
                        let dupNation = civPool[nationIndex];
                        //console.log("Removing Dup Nation:", dupNationIndex, dupNation);
                        draftPool.splice(dupNationIndex, 1);
                        dupLeaders.add(`${dupNation.nation} - ${dupNation.leader}`);
                    });
                }

                if (removeDupLeaders) {
                    pickedCiv.dupLeaders?.forEach(leaderIndex => {
                        let dupLeaderIndex = draftPool.indexOf(String(leaderIndex));
                        if (dupLeaderIndex === -1) return;
                        let dupLeader = civPool[leaderIndex];
                        //console.log("Removing Dup Nation:", dupLeaderIndex, dupLeader);
                        draftPool.splice(dupLeaderIndex, 1);
                        dupLeaders.add(`${dupLeader.nation} - ${dupLeader.leader}`);
                    });
                }
            });
        }

        /*gamePlayers.forEach(player => console.log(player.username, player.civChoices));
    
        console.log(dupLeaders);*/
        //console.log("Logging bannedLeaders: ", bannedLeaders);

        var draft = { map: map, result: gamePlayers, bannedLeaders: bannedLeaders, settings: civGameSettings };
        return Promise.resolve(draft);
    }
    catch (err) {
        return Promise.resolve({ error: "Unexpected error while drafting: " + err });
    }
}

function RandomizePlayers(members, teamCount) {
    let pool = _.cloneDeep(members);
    let currentTeam = 1;

    // while pool has length
    while (pool.length > 0) {
        // randomly pick player from pool
        let i = Math.floor(Math.random() * pool.length)
        // set players team = currentTeam
        let player = pool[i];
        let index = _.findIndex(members, function (m) { return m.userID === player.userID; });
        members[index].team = currentTeam.toString();
        // remove player from pool 
        pool.splice(i, 1);
        currentTeam += 1;

        // check currentTeam count
        if (currentTeam > teamCount) {
            // set to 1 if >= teamCount
            currentTeam = 1;
        }
    }
}

export default civDraft;