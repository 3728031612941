import React, { Component } from "react";
import { connect } from "react-redux";
import Alert from 'react-bootstrap/Alert';

import { default as alertTypes } from '../alerts';

const ALERT_TYPES = {
  'danger': alertTypes.errorAlert,
  'success': alertTypes.successAlert
}

const mapStateToProps = state => ({
    ...state.alert
});

class AlertContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            alertIsOpen: props.alertProps.open,
            message: props.alertProps.message
        };

        this.closeAlert = this.closeAlert.bind(this);
    }

    // componentDidMount() {
    // }

    componentDidUpdate(prevProps) {
        if (prevProps.alertProps.open !== this.props.alertProps.open) {
            this.setState({
                alertIsOpen: this.props.alertProps.open,
                message: this.props.alertProps.message
            });
        }
    }
    
    closeAlert() {
        this.props.hideAlert()
    }

    render() {
        if (!this.props.alertType) {
            return null
        }
        const SpecifiedAlert = ALERT_TYPES[this.props.alertType]
        return (
            <div>
                <Alert show={ this.state.alertIsOpen } variant={ this.props.alertType }>
                    <SpecifiedAlert
                        closeAlert={this.closeAlert}
                        {...this.props.alertProps}
                    />
                </Alert>
          </div>
        );
    }
}

export default connect(
    mapStateToProps,
    null
)(AlertContainer);