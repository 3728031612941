import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import axios from 'axios';
// import _ from 'lodash';

import { showAlert, hideAlert } from '../../../actions/alertActions';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
// import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';

// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';

// import Filter from '../../components/Filter';

class MainTab extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            isLoading: true,
            showChangePassword: false,
            currentPassword: "",
            newPassword: "", 
            newPassword2: "",
        };


        this.closeAlert = this.closeAlert.bind(this);
        this.openSuccessAlert = this.openSuccessAlert.bind(this);
    }

    componentDidMount() {
        this.setState({
            isLoading: false
        });
    }

//OPEN SUCCESS Alert
openSuccessAlert(message, title, type) {
    this.props.showAlert({
        open: true,
        title: title,
        message: message,
        closeAlert: this.closeAlert
    }, type)
}

//CLOSE Alert
closeAlert = () => {
    this.props.hideAlert()
}

//creates a new group
onSubmitUpdatePassword = e => {
    e.preventDefault();
    
    var payload = {
        userID: this.props.auth.user._id,
        currentPassword: this.state.currentPassword,
        newPassword: this.state.newPassword,
        newPassword2: this.state.newPassword2
    }

    axios
        .post("/api/users/updatePassword", payload)
        .then(res => {
            if (res.status === 200){
                var successMessage = "Successfully saved new password to SquadMatchMake";
                var title = "Success";
                var type = 'success';

                //close modal
                this.handleCloseUpdatePassword();

                //show success
                this.openSuccessAlert(successMessage, title, type);

                //close success after 5 seconds
                setTimeout(() => {
                    this.closeAlert()
                }, 5000);
            }
        }) // re-direct to login on successful register
        .catch(err => {
            this.setState({ errors: err.response.data });
        });
};

//handlers
handleShowUpdatePassword = () => {
    this.setState({ showChangePassword: true });
}

handleCloseUpdatePassword = () => {
    //reset from variables
    this.setState({ 
        showChangePassword: false,
        currentPassword: "",
        newPassword: "", 
        newPassword2: "",
    });
}

onChange = e => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.id;

    this.setState({ [name]: value });
};

    render() {
        const { errors } = this.state;

        if (this.state.isLoading) {
            return (
                <div className="h-100">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            );
        }
        else {
            return (
                <div className="h-100">

                    <div className="container" style={{ marginTop: '20px' }}>
                        <Card style={{ margin: '10px' }}>
                            <Card.Header>
                                Profile Information
                        </Card.Header>
                            <Card.Body>
                                <p>Username: {this.props.auth.user.username}</p>
                                <p>Email: {this.props.auth.user.email}</p>
                            </Card.Body>
                        </Card>
                        <Card style={{ margin: '10px' }}>
                            <Card.Header>
                                Security
                        </Card.Header>
                            <Card.Body>
                                Click update to change your password.
                        </Card.Body>
                            <Card.Footer>
                                <button type="button" onClick={this.handleShowUpdatePassword} className="btn btn-primary d-flex">Update</button>
                            </Card.Footer>
                        </Card>

                        <Modal show={this.state.showChangePassword} onHide={this.handleCloseUpdatePassword} animation={true} backdrop="static" keyboard={false}>

                            <Modal.Header>
                                <Modal.Title>Change Password</Modal.Title>
                                <button type="button" onClick={this.handleCloseUpdatePassword} className="btn-close" aria-label="Close"></button>
                            </Modal.Header>

                            <Modal.Body style={{ margin: '5px' }}>
                                <Form>
                                    <Form.Group controlId="currentPassword" >
                                        <Form.Label>Current Password</Form.Label>
                                        <Form.Control
                                            required
                                            type="password"
                                            value={this.state.currentPassword}
                                            onChange={this.onChange}
                                            error={errors.currentPassword}
                                            isInvalid={errors.currentPassword || errors.passwordincorrect}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.currentPassword}
                                            {errors.passwordincorrect}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="newPassword" >
                                        <Form.Label>New Password</Form.Label>
                                        <Form.Control
                                            required
                                            type="password"
                                            value={this.state.newPassword}
                                            onChange={this.onChange}
                                            error={errors.newPassword}
                                            isInvalid={errors.newPassword}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.newPassword}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="newPassword2" >
                                        <Form.Label>Confirm New Password</Form.Label>
                                        <Form.Control
                                            required
                                            type="password"
                                            value={this.state.newPassword2}
                                            onChange={this.onChange}
                                            error={errors.newPassword2}
                                            isInvalid={errors.newPassword2}
                                        />
                                        <Form.Control.Feedback type='invalid'>
                                            {errors.newPassword2}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button type="submit" onClick={this.onSubmitUpdatePassword} as={Col} xs={2}>
                                    Submit
                                </Button>
                                <Button onClick={this.handleCloseUpdatePassword} as={Col} xs={2} style={{ marginLeft: '10px' }}>
                                    Cancel
                                </Button>
                            </Modal.Footer>
                        </Modal>

                    </div>

                </div>
            );
        }
    }
}

MainTab.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

const mapDispatchToProps = dispatch => ({
    hideAlert: () => dispatch(hideAlert()),
    showAlert: (alertProps, alertType) => {
        dispatch(showAlert({ alertProps, alertType }))
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MainTab);