import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import MainTab from './Tabs/MainTab';
import SteamTab from './Tabs/SteamTab';

import AlertRoot from "../components/AlertRoot";
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
// import Form from 'react-bootstrap/Form';
// import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Navbar from 'react-bootstrap/Navbar';
// import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
// import Alert from 'react-bootstrap/Alert';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            auth: {},
        };
    }

    componentDidMount() {
        this.props.history.push("/profile");
    }
    
    displaySteamTab = () => {
        if (this.state.isLoading) {
            return null;
        }
        else {
            return <SteamTab/>
        }
    }

    displayMainTab = () => {
        if (this.state.isLoading) {
            return null;
        }
        else {
            return <MainTab/>
        }
    }

    render() {
        // const { errors } = this.state;
        // const { auth } = this.state;

        return (
            <div className="h-100">
                <Navbar expand="lg" variant="light" bg="light">
                    <Container>
                        <Navbar.Brand>Profile</Navbar.Brand>
                    </Container>
                </Navbar>
                <AlertRoot hideAlert={this.props.hideAlert} />

                <Tabs defaultActiveKey="main" id="activeTab" className="mb-3">
                    <Tab eventKey="main" title="Main Profile">
                        { this.displayMainTab() }
                    </Tab>
                    <Tab eventKey="steam" title="Steam Profile" > 
                        { this.displaySteamTab() }
                    </Tab>
                    {/* <Tab eventKey="userPreferences" title="User Preferences">
                        { this.displayUserPreferencesTab() }
                    </Tab> */}
                </Tabs>

                

               
            </div>
        );
    }
}

Profile.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});



export default connect(
    mapStateToProps
)(Profile);