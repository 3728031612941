import React, { Component } from "react";
// import PropTypes from "prop-types";
// import { connect } from "react-redux";
// import axios from 'axios';

// import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

class Support extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
        };
    }

    componentDidMount() {
        this.props.history.push("/support");
    }

    render() {

        return (
            <div className="h-100">
                <Navbar expand="lg" variant="light" bg="light">
                    <Container>
                        <Navbar.Brand>Support</Navbar.Brand>
                    </Container>
                </Navbar>
                <Container>
                    <Card style={{ marginTop:"10px" }}>
                        <Card.Header>
                            We currently only support these games listed below. We are always looking to add more games to our list so
                            let us know what games you want supported! Head over to Contact Us and drop us a message on the games you want and 
                            give us some details on features you would like to have and we'll do our best to build it out for you. 
                        </Card.Header>
                        <Card.Body>

                            <Table responsive striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Game</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* <tr>
                                        <td>Sid Meier's Civilization V</td>
                                    </tr> */}
                                    <tr>
                                        <td>Sid Meier's Civilization VI</td>
                                    </tr>
                                    <tr>
                                        <td>RuneScape</td>
                                    </tr>
                                    <tr>
                                        <td>Old School RuneScape</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>

                </Container>
            </div>
        );
    }
}

export default Support;