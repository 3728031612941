import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import restService from '../../../../utils/restService';
// import civDraft from './CivDraft';
import civFilter from './CivFilter';
import SMMToast from "../../../components/SMMToast";
import _ from 'lodash';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import Pagination from 'react-bootstrap/Pagination';
import DraftUI from "./DraftUI";

class ActiveGames extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            groupID: "",
            group: {},
            games: [],
            //render control
            isLoading: true,
            variant: "light",
            //modals
            showAdd: false,
            showFilter: false,
            showToast: false,
            title: "",
            message: "",
            filteredGames: [],
            filterOptions: { //filter modal options
                filteredMembers: false,
                filterType: "Is",
            },
            newGame: { //new game object
                gameName: "",
                host: "",
                anonymousMode: false,
                draftMode: false,
                numCiv: "3",
                randomMap: false,
                bansRemovePicks: false,
                removeDupNations: false,
                removeDupLeaders: false,
                freeBans: null
            },
            //pagination
            totalPages: 1,
            activePage: 1,
            itemsPerPage: 10,
            direction: false
        };
    }

    componentDidMount() {
        this.setState({ groupID: this.props.groupID });
        this.getFullGroup(this.props.groupID);
    }

    //-----API FUNCTIONS-----//
    //#region API
    getFullGroup = (groupID) => {
        restService.groups.getFullGroup(groupID).then((response) => {
            const data = response.data;
            this.setState({ group: data });
        }).catch((err) => {
            console.log("Error retrieving games data: ", err);
        }).then(() => {
            this.getGameInfo(groupID);
        });
    }

    getGameInfo = () => {
        const { groupID } = this.state;
        restService.civilization.getActiveGames(groupID).then((response) => {
            const data = response.data;
            var filteredGames = _.cloneDeep(data).sort((a, b) => { return new Date(b.dateCreated) - new Date(a.dateCreated); });

            this.setState({ games: data, filteredGames: filteredGames });
        }).catch((err) => {
            console.log("Unexpected error retrieving games data: ", err)
        }).then(() => {
            this.setState({ isLoading: false });
        });
    }

    //#endregion

    //-----EVENT FUNCTIONS-----//
    //#region EVENTS

    //creates a new civ game
    onSubmitFilter = async e => {
        e.preventDefault();

        var { games, group, filterOptions } = this.state;
        var members = group.members.filter(m => m.isCheckedAdd);
        var filterResults = await civFilter(games, members, filterOptions.filterType);

        this.setState({ filteredGames: filterResults });
        this.handleCloseFilter();
    };

    onChangeFilter = e => {
        const target = e.target;
        const value = target.value;
        const name = target.id;

        var { filterOptions } = this.state;
        filterOptions[name] = value;
        this.setState({ filterOptions: filterOptions });
    }


    onChangeNewGame = async e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        var { newGame } = this.state;
        newGame[name] = value;
        this.setState({ newGame: newGame });
    };

    onChangePlayerSelect = e => {
        const target = e.target;
        const value = target.checked;
        const name = JSON.parse(target.id);

        var { group } = this.state;
        var members = group.members;
        var index = members.findIndex(m => m.userID === name.userID);
        group.members[index].isCheckedAdd = value;
        this.setState({ group: group });
    }

    //#endregion

    //-----HANDLER FUNCTIONS-----//
    //#region HANDLERS
    handleShowAdd = () => {
        this.setState({ showAdd: true });
    }

    handleCloseAdd = () => {
        var group = this.state.group;

        group.members.forEach(member => {
            member.isCheckedAdd = false;
        });

        //reset from variables
        this.setState({
            showAdd: false,
            group: group,
        });
    }

    handleShowFilter = () => {
        this.setState({ showFilter: true });
    }

    handleCloseFilter = () => {
        this.setState({ showFilter: false });
    }
    //#endregion

    //-----DISPLAY FUNCTIONS-----//
    //#region DISPLAY
    //populates tab main game data table
    displayGameData = (games, totalPages, activePage) => {
        if (!games?.length) {
            return null;
        }
        else {
            var numItems = this.state.itemsPerPage
            var pagination = [];
            for (let i = 0; i < totalPages; i++) {
                var item = {
                    elements: games.slice(i * numItems, i * numItems + numItems),
                    pageNumber: i + 1
                }

                pagination.push(item);
            }

            return pagination[activePage - 1].elements.map((game, index) => (
                <tr key={index}>
                    <td><strong><Button variant="link" href={`/civGroup/civGame?groupID=${this.state.group._id}&gameID=${game._id}`}>{game.gameName}</Button></strong></td>
                    <td>
                        {game.members.map((member, index) => (<div key={index}>{(member.isActive) && (member.username)}</div>))}
                    </td>
                    <td>{game.host}</td>
                    <td>{game.gameType === "ffa" ? "FFA" : "Team"}</td>
                    <td>{new Date(game.dateCreated).toDateString()}</td>
                </tr>
            ));
        }
    }

    sort = (property) => {
        var games = this.state.filteredGames;
        var direction = this.state.direction;
        let sortDirection = direction ? 1 : -1;

        games = games.sort(function (a, b) {
            if (a[property] < b[property]) {
                return -1 * sortDirection;
            }
            else if (a[property] > b[property]) {
                return 1 * sortDirection;
            }
            else {
                return 0;
            }
        });

        this.setState({ games: games, direction: !direction });
    }

    displayBody = (filteredGames) => {
        if (!this.state.isLoading && filteredGames != null) {
            var totalPages = Math.ceil(filteredGames.length / this.state.itemsPerPage);

            return (
                <div>
                    <Table responsive striped bordered hover variant={this.state.variant}>
                        <thead>
                            <tr>
                                <th>
                                    <span style={{ cursor: "pointer" }} onClick={() => this.sort('gameName')}>Game Name</span>
                                    {/* <span innerHTML="arrow('CategoryID')"></span> */}
                                </th>
                                <th>Active Players</th>
                                <th>Host</th>
                                <th>Game Type</th>
                                <th>
                                    <span style={{ cursor: "pointer" }} onClick={() => this.sort('dateCreated')}>Date</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.displayGameData(filteredGames, totalPages, this.state.activePage)}
                        </tbody>
                    </Table>
                    <Pagination style={{ justifyContent: "center" }} >
                        {this.displayPagination(filteredGames, totalPages)}
                    </Pagination>
                </div>
            );
        }
        else {
            return (
                <Spinner animation="border" role="status" variant="secondary" >
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            );
        }
    }

    displayPagination = (games, totalPages) => {
        if (games?.length <= 0) {
            return null;
        }
        else {
            var numItems = this.state.itemsPerPage
            var pagination = [];
            for (let i = 0; i < totalPages; i++) {
                var item = {
                    elements: games.slice(i * numItems, i * numItems + numItems),
                    pageNumber: i + 1
                }

                pagination.push(item);
            }

            return pagination.map((page, index) => (
                <Pagination.Item key={index} onClick={() => this.setState({ activePage: page.pageNumber })} active={page.pageNumber === this.state.activePage} activeLabel={''}
                >{page.pageNumber}</Pagination.Item>
            ));
        }
    }

    displayHeaderButtons = (group, games) => {
        if (!group || !games) {
            return null;
        }
        else if (group.members?.some(m => m.userID === this.props.auth.user.id)) {
            return (
                <Form className="d-flex justify-content-end">
                    <Button className="mx-1" onClick={this.handleShowAdd}>Add</Button>
                    <Button className="mx-1" onClick={this.handleShowFilter}>Filter</Button>
                    <Button className="mx-1" onClick={() => { this.setState({ filteredGames: games, filteredMembers: false }) }}>Clear</Button>
                </Form>
            );
        }
        else {
            return null;
        }
    }

    //display member options in selectbox
    memberOptions = (members) => {
        if (!members?.length) {
            return null;
        }
        else {
            return members.map((member, index) => (
                <option key={index} value={member.username}>{member.username}</option>
            ));
        }
    }

    mapMemberCheckbox = (members) => {
        if (!members?.length) {
            return null;
        }
        else {
            return <Row xs={1} md={3} lg={5} className="g-3">
                {members.map((member, index) => (
                    <Col key={index} >
                        <Form.Check type="checkbox" id={JSON.stringify(member)} label={member.username}
                            onChange={this.onChangePlayerSelect} checked={member.isCheckedAdd} disabled={members?.filter(m => m.isCheckedAdd).length >= 12 && !member.isCheckedAdd} />
                    </Col>
                ))}
            </Row>
        }
    }

    showDraftOptions = (newGame) => {
        if (newGame.draftMode) {
            return (
                <div>
                    {/* Draft Options Select Box */}
                    <Form.Group style={{ marginTop: '5px' }}>
                        <Form.Label>Number of Civilizations</Form.Label>
                        <Form.Control as="select" id="numCiv" aria-label="Number Of Civilizations"
                            value={newGame.numCiv} onChange={this.onChangeNewGame} >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </Form.Control>

                        <Form.Check type="checkbox" id="randomMap" label="Random Map" onChange={this.onChangeNewGame}
                            checked={newGame.randomMap} className="py-2" />

                        {/* <Form.Check type="checkbox" id="includeBans" label="Random Map" onChange={this.onChangeNewGame}
                            checked={newGame.randomMap} className="py-2" /> */}

                        <Form.Check type="checkbox" id="bansRemovePicks" label="Bans Remove Picks" onChange={this.onChangeNewGame}
                            checked={newGame.bansRemovePicks} className="py-2" />

                        {newGame.bansRemovePicks ?
                            <div>
                                <Form.Label>Number of Free Bans</Form.Label>
                                <Form.Control as="select" id="freeBans" aria-label="Number of Free Bans"
                                    value={newGame.freeBans} onChange={this.onChangeNewGame} >
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                    <option value={5}>5</option>
                                </Form.Control>
                            </div>
                            : null}

                        <Form.Check type="checkbox" id="removeDupNations" label="Remove Duplicate Nations" onChange={this.onChangeNewGame}
                            checked={newGame.removeDupNations} className="py-2" />

                        <Form.Check type="checkbox" id="removeDupLeaders" label="Remove Duplicate Leaders" onChange={this.onChangeNewGame}
                            checked={newGame.removeDupLeaders} className="py-2" />

                    </Form.Group>
                </div>
            );
        }
        else {
            return null;
        }
    }
    //#endregion

    render() {
        const { filteredGames, variant, group, games, groupID } = this.state; // errors,

        return (
            <div className="py-3 px-3">
                <Card bg={variant}>
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        Active Games
                        {this.displayHeaderButtons(group, games)}
                    </Card.Header>
                    <Card.Body>
                        {this.displayBody(filteredGames)}
                    </Card.Body>
                </Card>

                {/* SHOW CREATE GAME MODAL! */}
                <Modal show={this.state.showAdd} size="lg" onHide={this.handleCloseAdd} animation={true} backdrop="static" keyboard={false} centered>
                    <Modal.Header>
                        <Modal.Title>Add Game</Modal.Title>
                        <button type="button" onClick={this.handleCloseAdd} className="btn-close" aria-label="Close"></button>
                    </Modal.Header>

                    <Modal.Body className="py-2">
                        <Form noValidate>
                            <Form.Group className="py-3">
                                <Form.Label>Select Members</Form.Label>
                                {this.mapMemberCheckbox(group.members)}
                            </Form.Group>
                        </Form>
                        <DraftUI groupID={groupID} members={group.members?.filter(m => m.isCheckedAdd)} gameName="" modalEvent={null}
                            handleCloseModal={this.handleCloseAdd} reload={this.getGameInfo} />

                    </Modal.Body>
                    {/* <Modal.Footer>
                        <Form>
                            <Button type="submit" onClick={this.onSubmitAdd} className="mx-2">
                                Submit
                            </Button>
                            <Button onClick={this.handleCloseAdd} className="mx-2">
                                Cancel
                            </Button>
                        </Form>
                    </Modal.Footer> */}
                </Modal>

                {/* SHOW FILTER MODAL! */}
                <Modal show={this.state.showFilter} size="lg" onHide={this.handleCloseFilter} centered animation={true} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <Modal.Title>Filter Members</Modal.Title>
                        <button type="button" onClick={this.handleCloseFilter} className="btn-close" aria-label="Close"></button>
                    </Modal.Header>

                    <Modal.Body className="py-2">
                        <Form.Group className="py-3">
                            <Form.Label>Filter Type</Form.Label>
                            <Form.Control
                                as="select"
                                id="filterType"
                                aria-label="Filter Type"
                                value={this.state.filterOptions.filterType}
                                onChange={this.onChangeFilter}
                            >
                                <option value="Is">Is</option>
                                <option value="Includes">Includes</option>
                            </Form.Control>
                        </Form.Group>

                        {/* Select members */}
                        {this.mapMemberCheckbox(group.members)}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.onSubmitFilter} as={Col} xs={2}>
                            Submit
                        </Button>
                        <Button onClick={this.handleCloseFilter} as={Col} xs={2} style={{ marginLeft: '10px' }}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>

                <SMMToast show={this.state.showToast} title={this.state.title} message={this.state.message} />
            </div>
        );
    }
}

ActiveGames.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(ActiveGames);

