

const civilization1 = [
    
];

const civilization2 = [
    
];

const civilization3 = [
    
];

const civilization4 = [
    
];

const civilization5 = [
    
];

const civilization6 = [
    { name: "Archipelago", minPlayers: 1, isChecked: false },
    { name: "Continents and Islands", minPlayers: 1, isChecked: false},
    { name: "Continents", minPlayers: 1, isChecked: false},
    { name: "Earth", minPlayers: 6, isChecked: false},
    { name: "East Asia", minPlayers: 6, isChecked: false},
    { name: "Europe", minPlayers: 6, isChecked: false},
    { name: "Fractal", minPlayers: 1, isChecked: false},
    { name: "Highlands", minPlayers: 1, isChecked: false},
    { name: "Inland Sea", minPlayers: 6, isChecked: false},
    { name: "Island Plates", minPlayers: 1, isChecked: false},
    { name: "Lakes", minPlayers: 1, isChecked: false},
    { name: "Pangea", minPlayers: 1, isChecked: false},
    { name: "Perfect World", minPlayers: 1, isChecked: false},
    { name: "Primordial", minPlayers: 1, isChecked: false},
    { name: "Seven Seas", minPlayers: 1, isChecked: false},
    { name: "Shuffle", minPlayers: 1, isChecked: false},
    { name: "Small Continents", minPlayers: 1, isChecked: false},
    { name: "Splintered Fractal", minPlayers: 1, isChecked: false},
    { name: "Tilted Axis", minPlayers: 1, isChecked: false}
];

const mapTypes = {
    "Civilization 1": civilization1, 
    "Civilization 2": civilization2, 
    "Civilization 3": civilization3, 
    "Civilization 4": civilization4, 
    "Civilization 5": civilization5, 
    "Civilization 6": civilization6,
};

export default mapTypes;