import React, { Component } from "react";
// import PropTypes from "prop-types";
// import { connect } from "react-redux";
// import axios from 'axios';

// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

class Upcoming extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
        this.props.history.push("/upcoming");
    }

    render() {

        return (
            <div className="h-100">
                <Navbar expand="lg" variant="light" bg="light">
                    <Container>
                        <Navbar.Brand>Upcoming</Navbar.Brand>
                    </Container>
                </Navbar>
                <Container>
                    <Card style={{ marginTop:"10px" }}>
                        <Card.Header>
                            This is where we will show what is being worked on and coming next.
                        </Card.Header>
                        <Card.Body>

                            <Table responsive striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Item</th>
                                        <th>State</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            More to General Website
                                            <ul>
                                                <li>Dark Mode</li>
                                            </ul>
                                        </td>
                                        <td>Development</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Civilizations
                                            <ul>
                                                <li>Teams</li>
                                                <li>Civilization 1, 2, 3, 4</li>
                                                <li>Group chat</li>
                                            </ul>
                                        </td>
                                        
                                        <td>Development</td>
                                    </tr>
                                    <tr>
                                        <td>RuneScape/Old School RuneScape Clans</td>
                                        <td>Development</td>
                                    </tr>
                                    <tr>
                                        <td>Fortnite</td>
                                        <td>Planning</td>
                                    </tr>
                                    <tr>
                                        <td>Dota</td>
                                        <td>Design</td>
                                    </tr>
                                    <tr>
                                        <td>League of Legends</td>
                                        <td>Design</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>

                </Container>
            </div>
        );
    }
}

export default Upcoming;