import React, { Component } from "react";

import Toast from 'react-bootstrap/Toast';

class SMMToast extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            variant: "light",
            show: false,
            title: "",
            message: ""
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.show !== this.props.show) {
            this.setState({ show: true, title: this.props.title, message: this.props.message });
        }
    }

    handleClose = () => { this.setState({ show: false }); }

    //-----API FUNCTIONS-----//
    //#region API

    //#endregion

    //-----EVENT FUNCTIONS-----//
    //#region EVENTS

    //#endregion

    //-----HANDLER FUNCTIONS-----//
    //#region HANDLERS
    handleShow = () => { this.setState({ show: true }); }

    //#endregion

    //-----DISPLAY FUNCTIONS-----//
    //#region DISPLAY

    //#endregion

    render() {
        var { title, message } = this.state; //, variant

        return (
            <div >
                <Toast onClose={this.handleClose} show={this.state.show} delay={3000} autohide animation={true} style={{ position: "fixed", bottom: "20px", right: "20px", zIndex: 9999, float: "left" }}>
                    <Toast.Header className="closeButton">
                        <strong className="me-auto">{title}</strong>
                    </Toast.Header>
                    <Toast.Body>{message}</Toast.Body>
                </Toast>
            </div>
        );
    }
}

export default SMMToast;