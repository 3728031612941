import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { matchPath } from "react-router";

import { loginUser } from "../../actions/authActions";
import { registerUser } from "../../actions/authActions";
import { showAlert, hideAlert } from '../../actions/alertActions';

import AlertRoot from "../components/AlertRoot";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
// import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Navbar from 'react-bootstrap/Navbar';
// import CardGroup from 'react-bootstrap/CardGroup';
import Container from 'react-bootstrap/Container';
import restService from "../../utils/restService";

//import civ6Icon from '../../images/icons/civ6Icon.png';

class Invite extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            showLogin: false,
            showRegister: false,
            inviteLink: "",
            group: {},
            members: [],
            isMember: false,
            isLoading: true,
            username: "",
            password: "",
            password2: "",
            email: "",
        };

        this.closeAlert = this.closeAlert.bind(this);
        this.openErrorAlert = this.openErrorAlert.bind(this);
    }

    //COMPONENT CYCLES
    componentDidMount() {
        var history = this.props.history.location.pathname + this.props.history.location.search;
        const match = matchPath(history, {
            path: "*/invite?groupID=:groupID&inviteLink=:inviteLink",
            strict: false
        });

        //get leagueID from history URL
        var groupID = match.params.groupID;

        this.getFullGroup(groupID);
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            if (this.props.errors) {
                this.setState({
                    errors: this.props.errors
                });

                if (this.state.showLogin && this.props.auth.isAuthenticated) {
                    this.handleCloseLogin();
                }

                if (this.state.showRegister && this.props.auth.registerSuccess) {
                    this.handleCloseRegister();
                    this.handleShowLogin();
                }
            }
        }
    }

    //OPEN SUCCESS Alert
    openErrorAlert(message, title, type) {
        this.props.showAlert({
            open: true,
            title: title,
            message: message,
            closeAlert: this.closeAlert
        }, type)
    }

    //CLOSE Alert
    closeAlert = () => {
        this.props.hideAlert()
    }

    getFullGroup = (groupID) => {
        restService.groups.getFullGroup(groupID).then((response) => {
            const data = response.data;
            this.setState({ group: data, isLoading: false });
        }).catch((err) => {
            console.log("Error retrieving group data: ", err);
            this.setState({ pageError: true });
        });
    }

    handleShowRegister = () => {
        this.setState({ showRegister: true });
    }

    handleCloseRegister = () => {
        this.setState({
            errors: {},
            username: "",
            email: "",
            password1: "",
            password2: "",
            showRegister: false
        });
    }

    handleShowLogin = () => {
        this.setState({ showLogin: true });
    }

    handleCloseLogin = () => {
        this.setState({
            errors: {},
            password: "",
            email: "",
            showLogin: false
        });
    }

    handleSwitchToRegister = () => {
        this.setState({
            showRegister: true,
            showLogin: false
        });
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSubmitLogin = e => {
        e.preventDefault();
        const userData = {
            email: this.state.email,
            password: this.state.password
        };

        this.props.loginUser(userData);
    };

    onSubmitRegister = e => {
        e.preventDefault();
        const newUser = {
            username: this.state.username,
            email: this.state.email,
            password: this.state.password,
            password2: this.state.password2
        };

        this.props.registerUser(newUser, this.props.history);
    };

    //creates a new group
    onSubmit = e => {
        e.preventDefault();

        var memberCheck = this.state.isMember; //check to see if the member is already in the group

        if (!this.props.auth.isAuthenticated) {
            this.handleShowLogin();
        }
        else {
            if (memberCheck) {
                console.log("You are already a member of this group!");
                var errorMessage = "You are already a member of this group!";
                var title = "Error";
                var type = 'danger';
                this.openErrorAlert(errorMessage, title, type);

                //close success after 5 seconds
                setTimeout(() => {
                    this.closeAlert()
                }, 5000);
            }
            else {
                var payload = {
                    groupID: this.state.group._id,
                    userID: this.props.auth.user.id
                };

                restService.groups.joinGroup(payload).then((response) => {
                    if (response.status === 200) {
                        this.props.history.push(`/${this.state.group.linkURL}/welcome?groupID=${this.state.group._id}`);
                    }
                }).catch((err) => { console.log("Error joining group: ", err) });
            }
        }
    };

    displayGroupMembers = (members) => {
        if (!members) {
            return null;
        }
        else if (!members.length) {
            return null;
        }
        else {
            return members.map((member, index) => (
                <p key={index}>{member.username}</p>
            ))
        }
    }

    render() {
        const { errors, group } = this.state;

        return (
            <div className="h-100">
                <Navbar expand="lg" variant="light" bg="light">
                    <Container>
                        <Navbar.Brand>{this.state.group.groupName}</Navbar.Brand>
                    </Container>
                </Navbar>
                <AlertRoot hideAlert={this.props.hideAlert} />

                <Container>

                    <Card style={{ marginTop: '20px' }}>
                        <Card.Header>
                            You've Been Invited to Join {group.groupName} Group
                        </Card.Header>
                        <Card.Body>
                            {!group?.members?.some(m => m.userID === this.props.auth.user.id) ? <Button className="my-3" onClick={this.onSubmit} disabled={this.state.isLoading}>Accept Invite</Button> : null }
                            <h6>Group Members</h6>
                            {this.displayGroupMembers(group.members)}
                        </Card.Body>
                    </Card>


                </Container>

                {/* LOGIN MODAL!!! */}
                <Modal show={this.state.showLogin} onHide={this.handleCloseLogin} animation={true} backdrop="static" keyboard={false}>

                    <Modal.Header>
                        <Modal.Title>Login</Modal.Title>
                        <button type="button" onClick={this.handleCloseLogin} className="btn-close" aria-label="Close"></button>
                    </Modal.Header>

                    <Modal.Body style={{ margin: '5px' }}>
                        <Form noValidate onSubmit={this.onSubmitLogin} >
                            <Form.Group controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    required
                                    type="email"
                                    value={this.state.email}
                                    onChange={this.onChange}
                                    error={errors.email}
                                    isInvalid={errors.email || errors.emailnotfound}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.email}
                                    {errors.emailnotfound}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="password" >
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    required
                                    type="password"
                                    value={this.state.password}
                                    onChange={this.onChange}
                                    error={errors.password}
                                    isInvalid={errors.password || errors.passwordincorrect}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.password}
                                    {errors.passwordincorrect}
                                </Form.Control.Feedback>
                            </Form.Group>

                        </Form>
                        <div>
                            <Button variant="link" onClick={this.handleSwitchToRegister}>
                                Sign Up
                            </Button>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" onClick={this.onSubmitLogin} as={Col} xs={2}>
                            Login
                        </Button>
                        <Button onClick={this.handleCloseLogin} as={Col} xs={2} style={{ marginLeft: '10px' }}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* REGISTER MODAL!!! */}
                <Modal show={this.state.showRegister} onHide={this.handleCloseRegister} animation={true} backdrop="static" keyboard={false}>

                    <Modal.Header>
                        <Modal.Title>Register</Modal.Title>
                        <button type="button" onClick={this.handleCloseRegister} className="btn-close" aria-label="Close"></button>
                    </Modal.Header>

                    <Modal.Body style={{ margin: '5px' }}>
                        <Form noValidate onSubmit={this.onSubmitRegister}>
                            <Form.Group controlId="username">
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    value={this.state.username}
                                    onChange={this.onChange}
                                    error={errors.username}
                                    isInvalid={errors.username}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.username}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    required
                                    type="email"
                                    value={this.state.email}
                                    onChange={this.onChange}
                                    error={errors.email}
                                    isInvalid={errors.email}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.email}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="password" >
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    required
                                    type="password"
                                    value={this.state.password}
                                    onChange={this.onChange}
                                    error={errors.password}
                                    isInvalid={errors.password || errors.passwordincorrect}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.password}
                                    {errors.passwordincorrect}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="password2" >
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control
                                    required
                                    type="password"
                                    value={this.state.password2}
                                    onChange={this.onChange}
                                    error={errors.password2}
                                    isInvalid={errors.password2}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.password2}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" onClick={this.onSubmitRegister} as={Col} xs={2}>
                            Register
                        </Button>
                        <Button onClick={this.handleCloseRegister} as={Col} xs={2} style={{ marginLeft: '10px' }}>
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

Invite.propTypes = {
    loginUser: PropTypes.func.isRequired,
    registerUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => ({
    loginUser: (userData) => dispatch(loginUser(userData)),
    registerUser: (userData, history) => {
        dispatch(registerUser(userData, history))
    },
    hideAlert: () => dispatch(hideAlert()),
    showAlert: (alertProps, alertType) => {
        dispatch(showAlert({ alertProps, alertType }))
    }
});

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Invite);