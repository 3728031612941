import React, { Component } from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

class Login extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            password: "",
            errors: {}
        };
    }

    componentDidMount() {
        // If logged in and user navigates to Login page, should redirect them to dashboard
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/dashboard");
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.auth.isAuthenticated) {
            this.props.history.push("/dashboard"); // push user to dashboard when they login
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };

    onSubmit = e => {
        e.preventDefault();
        const userData = {
            email: this.state.email,
            password: this.state.password
        };
        
        this.props.loginUser(userData);
    };

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="container align-items-center" style={{ marginTop: "5%", maxWidth:"350px" }}>
                    <Card>
                        <Card.Body>
                            <div className="row">
                                <div className="col">
                                    <Link to="/" className="btn-flat waves-effect">
                                        Back to home
                                    </Link>
                                    <div>
                                        <h4> <b>Login</b> below </h4>
                                        <p className="grey-text text-darken-1">
                                            Don't have an account? <Link to="/register">Register</Link>
                                        </p>
                                    </div>

                                    <Form noValidate onSubmit={this.onSubmit} >
                                        <Form.Group controlId="email">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                required
                                                type="email"
                                                value={this.state.email}
                                                onChange={this.onChange}
                                                error={errors.email}
                                                isInvalid={errors.email || errors.emailnotfound}
                                            />
                                            <Form.Control.Feedback type='invalid'>
                                                {errors.email}
                                                {errors.emailnotfound}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId="password" >
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control
                                                required
                                                type="password"
                                                value={this.state.password}
                                                onChange={this.onChange}
                                                error={errors.password}
                                                isInvalid={errors.password || errors.passwordincorrect}
                                            />
                                            <Form.Control.Feedback type='invalid'>
                                                {errors.password}
                                                {errors.passwordincorrect}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Button type="submit" onClick={this.onSubmit} style={{ marginTop: '10px' }}>
                                            Login
                                        </Button>
                                    </Form>
                                </div>
                            </div>
                        </Card.Body>
                        <Card.Footer>
                            <Link to="/resetPassword">Forgot Password?</Link>
                        </Card.Footer>
                    </Card>
                </div>
            </div>
        );
    }
}

Login.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { loginUser }
)(Login);