const renderFuncs = {
    numberedOptions: (length, minNum) => {
        var options = []
        var index = minNum ? minNum : 1;
        for (let i = index; i <= length; i++) {
            options.push(<option key={i} value={i}>{i}</option>);
        }
    
        return options;
    },
    selectOptions: (array) => {
        var options = [];
        if (!Array.isArray) {
            return options;
        }

        for (let i = 0; i < array.length; i++) {
            let name = "";
            var value;
            
            if (typeof array[i] === "boolean") {
                name = array[i] ? "Yes" : "No";
                value = array[i] ? true : false;
            }

            if (typeof array[i] === "string") {
                name = array[i];
                value = array[i]
            }

            options.push(<option key={i} value={value}>{name}</option>);
        }

        return options;
    }
}

export default renderFuncs;