import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { matchPath } from "react-router";
// import axios from 'axios';

import { linkSteamID } from "../../actions/authActions";

// import Alert from 'react-bootstrap/Alert';

class SteamLink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            auth: {},
        };
    }

    componentDidMount() {
        var history = this.props.location.pathname + this.props.location.search;
        const match = matchPath(history, {
            path: "/steamlink?steamID=:steamID",
            exact: true,
            strict: false
        });
        this.props.history.push(match.url);

        this.saveSteamID(match.params.steamID);
    }

    componentDidUpdate(prevProps){
        if (prevProps !== this.props){
            if (this.props.auth.steamID !== ""){
                this.props.history.push("/");
            }
        }
    }

    saveSteamID = (steamID) => {
        this.props.linkSteamID(steamID, this.props.auth.user.id);
    }

    render() {
        return (
            <div className="h-100">
                Your profile has now been linked to steam.
            </div>
        );
    }
}

SteamLink.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { linkSteamID }
)(SteamLink);