import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import restService from '../../../../utils/restService';
import moment from "moment";
import axios from 'axios';
import renderFuncs from "../../../global/Render";

import Datetime from 'react-datetime';
import Comments from '../../../components/Comments';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';

class GameDetailsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            show: false,
            showConfirm: false,
            showEventModal: false,
            game: {},
            group: {},
            gameID: "",
            groupID: "",
            gameName: "",
            host: "",
            lobbyLink: "",
            victoryTypeSelect: "Science",
            teamGame: false,
            members: [],
            errors: {},
            modalEvent: {
                eventName: "",
                startDate: null,
                endDate: null,
                maxNumberOfMembers: 0
            },
        };
    }

    componentDidMount() {
        let members = this.props.game.members?.map((member) => ({
            ...member,
            team: member.team ? member.team : "1"
        }));

        this.setState({
            groupID: this.props.groupID, gameID: this.props.game._id, game: this.props.game,
            members: members, teamGame: this.props.game.gameType === "team" ? true : false
        });

        this.getFullGroup(this.props.groupID);
    }

    getFullGroup = (groupID) => {
        restService.groups.getFullGroup(groupID).then((response) => {
            const data = response.data;
            this.setState({ group: data, isLoading: false });
        }).catch((err) => {
            console.log("Error retrieving games data: ", err);
        });
    }

    //steam://joinlobby/289070/109775242178955331/76561198268997818
    //getHostInfo = ()

    validStart = (current) => {
        var start = moment().subtract(1, "day");
        return current.isAfter(start);
    }

    validEnd = (current) => {
        var start = moment().subtract(1, "day");
        if (!this.state.startDate) {
            return current.isAfter(start);
        }
        else {
            start = moment(this.state.startDate).subtract(1, "day")
            return current.isSameOrAfter(start);
        }
    }

    changeStartDate = (e) => {
        var { modalEvent } = this.state;
        modalEvent.startDate = new Date(e._d);
        this.setState({ modalEvent: modalEvent });
    }

    changeEndDate = (e) => {
        var { modalEvent } = this.state;
        modalEvent.endDate = new Date(e._d);
        this.setState({ modalEvent: modalEvent });
    }

    onChangeModalEvent = async e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        var { modalEvent } = this.state;
        modalEvent[name] = value;

        console.log("Logging modalEvent: ", modalEvent);
        this.setState({ modalEvent: modalEvent });
    };

    onSubmitEdit = e => {
        e.preventDefault();

        var payload = {
            gameID: this.state.gameID,
            gameName: this.state.gameName,
            members: this.state.members,
            host: this.state.host,
            gameType: this.state.teamGame ? "team" : "ffa",
            winner: "",
            victoryType: "",
            lobbyLink: this.state.lobbyLink
        };

        axios.post(`/api/civilization/updateGame`, payload).then((response) => {
            var data = response.data;
            this.setState({ game: data });
        }).catch((err) => { console.log("Error update current Civilization Game: ", err) }).then(() => {
            this.handleClose();
        });
    };

    onSubmitAddEvent = e => {
        e.preventDefault();
        
        var { modalEvent, members } = this.state;
        modalEvent.gameMembers = this.state.members.filter(gm => gm.isActive);
        
        var payload = modalEvent;

        console.log("Logging payload: ", payload);

        // axios.post(`/api/civilization/updateGame`, payload).then((response) => {
        //     var data = response.data;
        //     this.setState({ game: data });
        // }).catch((err) => { console.log("Error update current Civilization Game: ", err) }).then(() => {
        //     this.handleClose();
        // });
    }

    //delete the game
    onSubmitDelete = e => {
        e.preventDefault();

        axios.post(`/api/civilization/deleteGame?gameID=${this.state.gameID}`).then((response) => {
            this.setState({
                show: false,
                showConfirm: false
            })
            this.props.history.push(`/civGroup/main?groupID=${this.state.groupID}`);
        }).catch((err) => { console.log("Error retrieving group data: ", err) });
    }

    onSubmitWinner = e => {
        e.preventDefault();

        let members = this.state.members.filter(m => m.isActive);
        var winner = "";

        if (this.state.game.gameType === "team") {
            const unique = [...new Set(members.map(member => member.team))];
            winner = unique[0];
        }
        else {
            winner = members[0].userID;
        }

        if (winner) {
            var payload = {
                gameID: this.state.gameID,
                gameName: this.state.gameName,
                members: this.state.members,
                host: this.state.game.host,
                gameType: this.state.teamGame ? "team" : "ffa",
                winner: winner,
                victoryType: this.state.victoryTypeSelect,
                lobbyLink: this.state.lobbyLink
            };

            axios.post(`/api/civilization/updateGame`, payload).then(res => {
                var data = res.data;
                this.setState({ game: data });
            }).catch((err) => {
                console.log("Error update current Civilization Game: ", err)
                this.setState({ isLoading: false });
            }).then(() => {
                this.handleClose();
                this.setState({ isLoading: false });
            });
        }
        else {
            //TODO: show error
        }
    }

    // onSubmitConcede = () => {
    //     console.log("Conceded");

    //     axios
    //         .post("api/civilization/concede", { userID: this.props.auth.user.id })
    //         .then(res => {
    //             const data = res.data;
    //             this.setState({ members: data.members });
    //         })
    //         .catch(err => {
    //             console.log("Unexpected error while trying to concede: ", err);
    //         });
    // }

    //handlers
    handleShowEventModal = () => {
        this.setState({ showEventModal: true });
    }

    handleCloseEventModal = () => {
        this.setState({ showEventModal: false });
    }

    handleShow = () => {
        this.setState({ show: true });
        this.setState({ gameName: this.state.game.gameName, host: this.state.game.host, lobbyLink: this.state.game.lobbyLink });
    }

    handleClose = () => {
        this.setState({ show: false });

        //reset variables
    }

    handleShowConfirm = () => {
        this.setState({ showConfirm: true });
    }

    handleCloseConfirm = () => {
        this.setState({ showConfirm: false });
    }

    onChangeActiveMember = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        var { members } = this.state;
        var index = members.findIndex(m => m.userID === name);
        members[index].isActive = value;

        this.setState({ members: members });

        if (this.state.host === members[index].username && !value) {
            this.setState({ host: members.find(m => m.isActive).username });
        }
    };

    // onChangeGame = e => {

    //     const target = e.target;
    //     const value = target.type === 'checkbox' ? target.checked : target.value;
    //     const name = target.id;

    //     var { game } = this.state;
    //     game[name] = value;

    //     this.setState({ game: game });
    // };

    onChange = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        this.setState({ [name]: value });
    };

    onChangeTeam = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        var { members } = this.state;
        var index = members.findIndex(m => m.userID === name);
        members[index].team = value;

        this.setState({ members: members });
    };

    displayGameData = (players) => {
        var { teamGame } = this.state;
        var playerTeam = players.find(player => player.userID === this.props.auth.user.id)?.team;

        if (!players) {
            return null;
        }
        else if (!players.length) {
            return null;
        }
        else {
            return players.map((player, index) => {
                var showDraftChoices = false;

                if (player.userID === this.props.auth.user.id) {
                    showDraftChoices = true;
                }
                else if (teamGame && playerTeam === player.team) {
                    showDraftChoices = true;
                }

                return (
                    <tr key={index}>
                        <td>{player.username}</td>
                        {this.state.teamGame ? <td>{player.team}</td> : null}
                        <td>{player.isActive ? "Active" : "Eliminated"}</td>
                        <td>{showDraftChoices ? this.displayCivChoices(player) : ""}</td>

                        {/* <td>{ player.id === this.props.auth.user.id && player.isActive && !player.hasConceded ? this.displayVoteConcedeButton(player) : "" }</td> */}
                    </tr>
                );
            });
        }
    }

    displayCivChoices = (player) => {
        return player.civChoices.map((choice, index) => (
            <p key={index}>
                {choice.leader} ({choice.nation})
            </p>
        ));
    }

    // displayVoteConcedeButton = (player) => {
    //     return <Button onClick={() => this.onSubmitConcede() }>Concede</Button>
    // }

    memberOptions = (members) => {
        if (!members?.length) {
            return null;
        }
        else {
            return members.map((member, index) => (
                <option key={index} value={member.username}>{member.username}</option>
            ));
        }
    }

    mapMemberCheckbox = (members) => {
        if (!members) {
            return null
        }
        else if (!members.length) {
            return null;
        }
        else {
            return members.map((member, index) => (
                <div key={index}>
                    <Row key={index} className="py-1">
                        <Col>
                            <Form.Check type="checkbox" id={member.userID} label={member.username}
                                onChange={this.onChangeActiveMember} checked={member.isActive} style={{ margin: '5px' }} />
                        </Col>
                        {this.state.teamGame ?
                            <Col>
                                {this.state.teamGame ? <Form.Select id={member.userID} aria-label="Select Player Team"
                                    value={member.team} onChange={this.onChangeTeam}>
                                    {renderFuncs.numberedOptions(12)}
                                </Form.Select> : null}
                            </Col>
                            : null
                        }
                    </Row>


                </div>
            ));
        }
    }

    showVictoryType = () => {
        var { teamGame, members } = this.state;
        var activeMembers = members.filter(e => e.isActive);
        var activeTeams = [...new Set(activeMembers.map(member => member.team))];

        if (activeMembers.length === 1 || (teamGame && activeTeams.length === 1)) {
            return (
                <Form.Group style={{ marginTop: '5px' }}>
                    <Form.Label>Select Victory Type</Form.Label>
                    <Form.Select id="victoryTypeSelect" aria-label="Select Victory Type"
                        value={this.state.victoryTypeSelect} onChange={this.onChange} >
                        <option value="Science">Science</option>
                        <option value="Culture">Culture</option>
                        <option value="Domination">Domination</option>
                        <option value="Religion">Religion</option>
                        <option value="Diplomatic">Diplomatic</option>
                        <option value="Territorial">Territorial</option>
                        <option value="Score">Score</option>
                    </Form.Select>
                </Form.Group>
            );
        }
        else {
            return null;
        }
    }

    showDeclareWinner = () => {
        var members = this.state.members.filter(e => e.isActive)


        if (this.state.teamGame) {
            const unique = [...new Set(members.map(member => member.team))];
            if (unique.length === 1) {
                return (
                    <Button disabled={true} onClick={this.onSubmitWinner} as={Col} xs={4} >{this.state.isLoading ? 'Loading…' : 'Declare Winner'}</Button>
                );
            }
            else {
                return (
                    <Button type="submit" onClick={this.onSubmitEdit} as={Col} xs={2}>Submit</Button>
                );
            }
        }
        else {
            if (members.length === 1) {
                return (
                    <Button disabled={true} onClick={this.onSubmitWinner} as={Col} xs={4} >{this.state.isLoading ? 'Loading…' : 'Declare Winner'}</Button>
                );
            }
            else {
                return (
                    <Button type="submit" onClick={this.onSubmitEdit} as={Col} xs={2}>Submit</Button>
                );
            }
        }

    }

    render() {
        const { errors, game, isLoading, members, teamGame, modalEvent } = this.state;

        if (isLoading) {
            return null;
        }
        else {
            return (
                <div className="py-3 px-3">
                    <Card>
                        <Card.Header className="d-flex justify-content-between align-items-center">
                            {game.gameName}
                            <Form className="d-flex">
                                {/* <Button className="mx-1" onClick={this.handleShowEventModal}>Create Event</Button> */}
                                <Button className="mx-1" onClick={this.handleShow}>Edit</Button>
                            </Form>
                        </Card.Header>
                        <Card.Body>
                            <Card.Title>Date: {new Date(game.dateCreated).toDateString()}</Card.Title>
                            {this.state.game.lobbyLink ? <Card.Subtitle><a href={this.state.game.lobbyLink}>Lobby Link</a></Card.Subtitle> : null}
                            <Card.Text>Host: {game.host}</Card.Text>
                            {game.map ? <Card.Text>Map: {game.map}</Card.Text> : null}
                            {game.bannedLeaders?.length ? <Card.Text>Banned Leaders: {game.bannedLeaders.join(", ")}</Card.Text> : null}
                            {/* { game.winner ? <Card.Text>Winner: { game.winner }</Card.Text> :  null } */}
                            <Table responsive striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Players</th>
                                        {this.state.teamGame ? <td>Team</td> : null}
                                        <th>Status</th>
                                        <th>Draft</th>

                                        {/* <th>Vote Concede</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.displayGameData(members)}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>

                    <Comments gameID={this.state.gameID} groupID={this.state.groupID} />

                    {/* EDIT MODAL */}
                    <Modal show={this.state.show} onHide={this.handleClose} centered animation={true} backdrop="static" keyboard={false}>
                        <Modal.Header className="d-flex">
                            <Modal.Title>Edit Game</Modal.Title>
                            <Form>
                                <Button variant="link" onClick={this.handleShowConfirm} style={{ marginLeft: 'auto' }}>Delete</Button>
                                <button type="button" onClick={this.handleClose} className="btn-close" aria-label="Close"></button>
                            </Form>

                        </Modal.Header>

                        <Modal.Body style={{ margin: '5px' }}>

                            <Form noValidate>
                                <Form.Group>
                                    <Form.Label>Game Name</Form.Label>
                                    <Form.Control required type="text" id="gameName" value={this.state.gameName}
                                        onChange={this.onChange} error={errors.gameName} isInvalid={errors.gameName} />
                                    <Form.Control.Feedback type='invalid'>
                                        {errors.gameName}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Check type="checkbox" id="teamGame" label="Team Game" onChange={this.onChange}
                                    checked={teamGame} className="py-2" />

                                <Form.Group style={{ marginTop: '10px' }}>
                                    <Form.Label>Active Members</Form.Label>
                                    {this.mapMemberCheckbox(this.state.members)}
                                </Form.Group>

                                {/* Host Select Box */}
                                <Form.Group className="py-2">
                                    <Form.Label>Select Host</Form.Label>
                                    <Form.Select id="host" aria-label="Host Select" value={this.state.host} onChange={this.onChange} >
                                        {this.memberOptions(game.members?.filter(m => m.isActive))}
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label>Lobby Link</Form.Label>
                                    <Form.Control required type="text" id="lobbyLink" value={this.state.lobbyLink} onChange={this.onChange} />
                                </Form.Group>

                                {this.showVictoryType()}

                            </Form>
                        </Modal.Body>

                        <Modal.Footer>
                            {this.showDeclareWinner()}

                            <Button onClick={this.handleClose} as={Col} xs={2} style={{ marginLeft: '10px' }}>
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* CONFIRM DELETE MODAL */}
                    <Modal show={this.state.showConfirm} onHide={this.handleCloseConfirm} centered animation={true} backdrop="static" keyboard={false}>
                        <Modal.Header>
                            <Modal.Title>Delete Game</Modal.Title>
                            <button type="button" onClick={this.handleCloseConfirm} className="btn-close" aria-label="Close"></button>
                        </Modal.Header>

                        <Modal.Body style={{ margin: '5px' }}>
                            Are you sure you wish to delete this game?
                        </Modal.Body>

                        <Modal.Footer>
                            <Button type="submit" onClick={this.onSubmitDelete} as={Col} xs={2}>
                                Submit
                            </Button>
                            <Button onClick={this.handleCloseConfirm} as={Col} xs={2} style={{ marginLeft: '10px' }}>
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/* CREATE GAME EVENT */}
                    <Modal show={this.state.showEventModal} onHide={this.handleCloseEventModal} animation={true} backdrop="static" keyboard={false} centered>
                        <Modal.Header>
                            <Modal.Title>Add Event</Modal.Title>
                            <button type="button" onClick={this.handleCloseEventModal} className="btn-close" aria-label="Close"></button>
                        </Modal.Header>

                        <Modal.Body >
                            <Form>
                                <Form.Group>
                                    <Form.Label>Event Name</Form.Label>
                                    <Form.Control required type="text" id="eventName" value={modalEvent.eventName} onChange={this.onChangeModalEvent} />
                                </Form.Group>

                                <Form.Label>Start Time</Form.Label>
                                <Datetime ref="datetime" isValidDate={this.validStart} id="startDate"
                                    value={modalEvent.startDate} onChange={this.changeStartDate} />

                                <Form.Label>End Time</Form.Label>
                                <Datetime ref="datetime" isValidDate={this.validEnd} id="endDate"
                                    value={modalEvent.endDate} onChange={this.changeEndDate} />
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            {/* {this.displayAlertError()} */}
                            <Form>
                                <Button type="submit" onClick={this.onSubmitAddEvent} className="mx-1">
                                    Submit
                                </Button>
                                <Button onClick={this.handleCloseEventModal} className="mx-1">
                                    Cancel
                                </Button>
                            </Form>
                        </Modal.Footer>
                    </Modal>
                </div>
            );
        }
    }
}

GameDetailsTab.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(GameDetailsTab);