import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import 'bootstrap/dist/css/bootstrap.min.css';

import { Provider } from "react-redux";
import store from "./store";

import PrivateRoute from "./components/private-route/PrivateRoute";
//Main Layout
import Landing from "./components/layout/Landing";
import TopNavbar from "./components/layout/TopNavbar";

//Authorization
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import ResetPassword from "././components/auth/ResetPassword";
import ChangePassword from "././components/auth/ChangePassword";

//Support Routes
import Invite from "./components/invite/Invite";
import Contact from "./components/support/Contact";
import ReleaseNotes from "./components/support/ReleaseNotes";
import FAQ from "./components/support/FAQ";
import Support from "./components/support/Support";
import Upcoming from "./components/support/Upcoming";

//Private routes
import Profile from "./components/profile/Profile";
import Search from "./components/search/Search";
import SteamLink from "./components/profile/SteamLink";

import Dashboard from "./components/dashboard/Dashboard";

// Civilization
import CivGroup from "./components/group/Civilization/CivGroup";
import CivGame from "./components/group/Civilization/CivGame";
import CivWelcome from "./components/group/Civilization/CivGroupTabs/CivWelcome";
import CivMembers from "./components/group/Civilization/CivGroupTabs/CivMembers";
import CivMain from "./components/group/Civilization/CivGroupTabs/CivMain";
import CivRankings from "./components/group/Civilization/CivGroupTabs/CivRankings";
import CivEvents from "./components/group/Civilization/CivGroupTabs/CivEvents";
import CivPreferences from "./components/group/Civilization/CivGroupTabs/CivPreferences";
import CivUserSettings from "./components/group/Civilization/CivGroupTabs/CivUserSettings";
import CivGroupSettings from "./components/group/Civilization/CivGroupTabs/CivGroupSettings";

// Runescape
import OSRSStats from "./components/stats/OSRSStats";
import RuneScapeGroup from "./components/group/RuneScape/RuneScapeGroup";
import RuneScapeWelcome from "./components/group/RuneScape/RuneScapeTabs/RuneScapeWelcome";
import RuneScapeEvents from "./components/group/RuneScape/RuneScapeTabs/RuneScapeEvents";
import RuneScapeUserSettings from "./components/group/RuneScape/RuneScapeTabs/RuneScapeUserSettings";
import RuneScapeGroupSettings from "./components/group/RuneScape/RuneScapeTabs/RuneScapeGroupSettings";


// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);

  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "./login";
  }
}

class App extends Component {
  render() {
    return (
      <Provider store={ store }>
        <Router>
          <div className="App">
            <TopNavbar/>
            <Route exact path="/" component={Landing} />
            {/* Authorization */}
            <Route exact path="/register" component={Register} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/resetPassword" component={ResetPassword} />
            <Route exact path="/changePassword" component={ChangePassword} />
            
            {/* Public */}
            <Route exact path="/invite" component={Invite} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/releaseNotes" component={ReleaseNotes} />
            <Route exact path="/support" component={Support} />
            <Route exact path="/faq" component={FAQ} />
            <Route exact path="/upcoming" component={Upcoming} />

            {/* Private */}
            <Switch>
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/search" component={Search} />
              <PrivateRoute exact path="/profile" component={Profile} />
              {/* OSRSStats */}
              <PrivateRoute exact path="/osrsStats" component={OSRSStats} />

              {/* Civilization */}
              <Route path="/civGroup">
                <div>
                  <CivGroup />
                  <Switch>
                    <PrivateRoute exact path="/civGroup/welcome" component={CivWelcome} />
                    <PrivateRoute exact path="/civGroup/members" component={CivMembers} />
                    <PrivateRoute exact path="/civGroup/main" component={CivMain} />
                    <PrivateRoute exact path="/civGroup/rankings" component={CivRankings} />
                    <PrivateRoute exact path="/civGroup/events" component={CivEvents} />
                    <PrivateRoute exact path="/civGroup/preferences" component={CivPreferences} />
                    <PrivateRoute exact path="/civGroup/userSettings" component={CivUserSettings} />
                    <PrivateRoute exact path="/civGroup/groupSettings" component={CivGroupSettings} />
                    <PrivateRoute exact path="/civGroup/civGame" component={CivGame} />
                  </Switch>
                </div>
              </Route>
              
              <PrivateRoute exact path="/steamlink" component={SteamLink} />
              {/* RuneScape */}
              {/* <PrivateRoute exact path="/osrsStats" component={OSRSStats} /> */}
              {/* <PrivateRoute exact path="/runeScapeClan" component={RuneScapeClan} /> */}
              <Route path="/runeScapeClan">
                <div>
                  <RuneScapeGroup />
                  <Switch>
                    <PrivateRoute exact path="/runeScapeClan/welcome" component={RuneScapeWelcome} />
                    <PrivateRoute exact path="/runeScapeClan/events" component={RuneScapeEvents} />
                    <PrivateRoute exact path="/runeScapeClan/userSettings" component={RuneScapeUserSettings} />
                    <PrivateRoute exact path="/runeScapeClan/groupSettings" component={RuneScapeGroupSettings} />
                  </Switch>
                </div>
              </Route>
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;