import React, { Component } from "react";
// import PropTypes from "prop-types";
// import { connect } from "react-redux";
// import axios from 'axios';

// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

class FAQ extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.props.history.push("/faq");
    }

    render() {

        return (
            <div className="h-100">
                <Navbar expand="lg" variant="light" bg="light">
                    <Container>
                        <Navbar.Brand>Frequent Asked Questions</Navbar.Brand>
                    </Container>
                </Navbar>
                <Container>
                    <Card style={{ marginTop: "10px" }}>
                        <Card.Header>How do I add a new group?</Card.Header>
                        <Card.Body>
                            Navigate to the dashbaord and click add in the top right corner of your screen.
                        </Card.Body>
                    </Card>

                    <Card style={{ marginTop: "10px" }}>
                        <Card.Header>How do I invite people to my group?</Card.Header>
                        <Card.Body>
                            Navigate to the group you would like to add new members to. Then navigate to the "Group Settings" tab and copy the invite link in "Settings." Send this link to the new member you would like to invite. 
                        </Card.Body>
                    </Card>
                </Container>
            </div>
        );
    }
}

export default FAQ;