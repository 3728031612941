import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { matchPath } from "react-router";

// import Container from 'react-bootstrap/Container';

import MapTypes from "../CivComponents/MapTypes";
import LeaderBans from "../CivComponents/LeaderBans";
import GameSettings from "../CivComponents/GameSettings";
import UserLeaderAgenda from "../CivComponents/UserLeaderAgenda";

class CivPreferences extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            groupID: ""
        };
    }

    componentDidMount() {
        //get group data
        try {
            var history = this.props.location.pathname + this.props.location.search;
            const match = matchPath(history, {
                path: "/civGroup/*?groupID=:groupID",
                strict: false
            });
            var groupID = match.params.groupID;
            this.setState({ groupID: groupID });
        }
        catch (err) {
            console.log("Unexpected error trying to setup Group Page: ", err);
            this.props.history.push("/");
        }
    }

    render() {
        const { groupID } = this.state;

        return (
            <div>
                { groupID != null && groupID !== "" ? <UserLeaderAgenda groupID={groupID}/> : null }
                { groupID != null && groupID !== "" ? <GameSettings groupID={groupID}/> : null }
                { groupID != null && groupID !== "" ? <MapTypes groupID={groupID}/> : null }
                { groupID != null && groupID !== "" ? <LeaderBans groupID={groupID}/> : null }
            </div>
        );
    }
}

CivPreferences.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(CivPreferences);