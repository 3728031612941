import React, { Component } from "react";
// import PropTypes from "prop-types";
// import { connect } from "react-redux";
// import axios from 'axios';

// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

class ReleaseNotes extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
        this.props.history.push("/releaseNotes");
    }

    render() {

        return (
            <div className="h-100">
                <Navbar expand="lg" variant="light" bg="light">
                    <Container>
                        <Navbar.Brand>Release Notes</Navbar.Brand>
                    </Container>
                </Navbar>
                <Container>
                    <Card style={{ marginTop: "10px" }}>
                        <Card.Header>April 9th, 2023</Card.Header>
                        <Card.Body>
                            Civilization - Teams are now fully supported
                            <ul>
                                <li>Team games show up in the new team rankings</li>
                                <li>Declare team winner by eliminating all other team players in game details tab</li>
                                <li>Draft/Edit teams</li>
                                <li>Random teams now available in the draft</li>
                            </ul>
                        </Card.Body>
                    </Card>

                    <Card style={{ marginTop: "10px" }}>
                        <Card.Header> January 15th, 2023</Card.Header>
                        <Card.Body>
                            RuneScape Groups
                            <ul>
                                <li>RuneScape and Old School RuneScape are now supported games! Keep an eye out for lot's of new content coming!</li>
                            </ul>
                            Civilization Groups
                            <ul>
                                <li>Welcome pages are now customizable in group settings.</li>
                            </ul>
                        </Card.Body>
                    </Card>

                    <Card style={{ marginTop: "10px" }}>
                        <Card.Header> December 24th, 2022</Card.Header>
                        <Card.Body>
                            Civilization Event Enhancements
                            <ul>
                                <li>New draft and redraft functionality on the events</li>
                                <li>Bug fix on UI - email no longer include start time and end time</li>
                                <li>Bug fix on UI - typo update from even to event</li>
                            </ul>
                            Civilization Groups
                            <ul>
                                <li>Groups are now role based with some control</li>
                                <li>New Group settings page to customize your group</li>
                            </ul>
                        </Card.Body>
                    </Card>

                    <Card style={{ marginTop: "10px" }}>
                        <Card.Header> April 17th, 2022</Card.Header>
                        <Card.Body>
                            <ul>
                                <li>Welcome Tabs - New groups come with welcome tabs as the main tab when entering</li>
                            </ul>
                        </Card.Body>
                    </Card>

                    <Card style={{ marginTop: "10px" }}>
                        <Card.Header> March 27th, 2022</Card.Header>
                        <Card.Body>
                            <ul>
                                <li>General - Can link steam account in the profile tab. Will be adding features around this in the future but for now it does not do much</li>
                            </ul>
                        </Card.Body>
                    </Card>

                    <Card style={{ marginTop: "10px" }}>
                        <Card.Header> February 26th, 2022</Card.Header>
                        <Card.Body>
                            <ul>
                                <li>General - SquadMatchMake is now fully responsive making it much more user friendly on you mobile devices</li>
                                <li>Civilization - New tab "Civilization Preferences." You can choose you map type preferences. You can also choose to have the random draft pick a map type for you based on all members selected map types</li>
                                <li>Filter Bug - Fixed filter bug where zero results would show a spinning wheel. Zero results now shows nothing.</li>
                            </ul>
                        </Card.Body>
                    </Card>

                    <Card style={{ marginTop: "10px" }}>
                        <Card.Header> February 13th, 2022</Card.Header>
                        <Card.Body>
                            <ul>
                                <li>Active Games - Revamped component now loads smoother and supports dark mode theme.</li>
                                <li>Archived Games - New component that shows a list of finished games you can go back and edit if need be.</li>
                                <li>Rankings - Revamped component now loads smoother and supports dark mode theme.</li>
                                <li>
                                    General - Internal structural imporvements to boost performance. Still non-profitable so will take 15-30 seconds to reload
                                    when idle for too long. This is due to free tier hosting environment, not code.
                                </li>
                            </ul>
                        </Card.Body>
                    </Card>

                    <Card style={{ marginTop: "10px" }}>
                        <Card.Header> January 23rd, 2022</Card.Header>
                        <Card.Body>
                            General
                            <ul>
                                <li>
                                    New public search - Any group that is public will now show up in the public search. Users can navigate to your public group
                                    and join
                                </li>
                                <li>
                                    Groups - GroupOwners can now kick any member from the group.
                                </li>
                            </ul>
                        </Card.Body>
                    </Card>

                    <Card style={{ marginTop: "10px" }}>
                        <Card.Header> January 16th, 2022</Card.Header>
                        <Card.Body>
                            Civilization Groups
                            <ul>
                                <li>
                                    Bug fix - Some emails don't get sent to selected members if the filter was run just before adding a new game. The add games
                                    and sending emails now works consistently.
                                </li>
                                <li>
                                    Bug fix - Deleting a group would push you back to the dashboard before the site has a chance to delete the group showing the
                                    group still in the dashboard. Deleting a group now waits for all event, comments, notes, games, and the group itself to be
                                    deleted before routing you back to the dashboard.
                                </li>
                                <li>Added table option - Added option to view your draft in game details if anything funky happens again.</li>
                                <li>Added filter option - Now able to filter on "Is." This will find all games where selected member are active exactly</li>
                            </ul>
                        </Card.Body>
                    </Card>

                    <Card style={{ marginTop: "10px" }}>
                        <Card.Header> January 9th, 2022</Card.Header>
                        <Card.Body>
                            General
                            <ul>
                                <li>Civilization 5 now supported!</li>
                                <li>Fixed events tab UI</li>
                            </ul>
                            Civilization Groups
                            <ul>
                                <li>More security checks on private groups</li>
                                <li>Groups now have administrators and members</li>
                                <li>Admins can change group settings and promote other members to admin</li>
                                <li>Group owner may promote or demote any other admin/member</li>
                                <li>Members/Admins may now leave groups</li>
                                <li>Group owner cannot leave a group. However, group owners may delete groups. Only group owner can delete</li>
                            </ul>
                        </Card.Body>
                    </Card>

                    <Card style={{ marginTop: "10px" }}>
                        <Card.Header> December 30th, 2021</Card.Header>
                        <Card.Body>
                            <ul>
                                <li>General - New Events Feature</li>
                                <li>General - Landing page updates</li>
                                <li>Civilization 6 - Added player relationships to Game Notes tab</li>
                            </ul>
                        </Card.Body>
                    </Card>
                    <Card style={{ marginTop: "10px" }}>
                        <Card.Header> December 24th, 2021</Card.Header>
                        <Card.Body>
                            <ul>
                                <li>Civilization 6 - Fixed filter and added clear filter button. </li>
                                <li>Civilization 6 - Added comments to game details, game notes tab to record personal notes on the game.</li>
                                <li>Civilization 6 - Main group page to navigate to game now more intuitve, simply click on the game name.</li>
                            </ul>
                        </Card.Body>
                    </Card>

                    <Card style={{ marginTop: "10px" }}>
                        <Card.Header> December 12th, 2021</Card.Header>
                        <Card.Body>
                            <ul>
                                <li>Civilization 6 - Fixed missing diplomatic victory in rankings. </li>
                                <li>Civilization 6 - Fixed deletion of games bug. It now navigates back to the group page properly. </li>
                                <li>Civilization 6 - Fixed Some UI issues. (Delete button, Close button) </li>
                            </ul>
                        </Card.Body>
                    </Card>
                </Container>
            </div>
        );
    }
}

export default ReleaseNotes;