import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from 'axios';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Navbar from 'react-bootstrap/Navbar';

import civ4Icon from '../../images/icons/civ4Icon.png';
import civ5Icon from '../../images/icons/civ5Icon.png';
import civ6Icon from '../../images/icons/civ6Icon.png';
import dota2Icon from '../../images/icons/dota2Icon.png';
import osrsIcon from '../../images/icons/osrsIcon.png';
import rsIcon from '../../images/icons/rsIcon.png';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            variant: "light",
            show: false, //modal
            groups: [], //user data
            games: [], //form
            groupName: "", //new group object
            isPrivate: true,
            gameName: "",
            linkURL: "",
            errors: {} //errors
        };
    }

    componentDidMount() {
        if (!this.props.auth.isAuthenticated) {
            this.props.history.push("/login");
        }
        else {
            this.props.history.push("/dashboard");
        }
        //get user data
        this.getUserGroups();
    }

    //API CALLS
    //get all groups the user is associated with
    getUserGroups = () => {
        axios
            .get(`/api/groups/getUserGroups?userID=${this.props.auth.user.id}`)
            .then((response) => {
                const data = response.data;
                this.setState({ groups: data });
            })
            .catch((err) => { console.log("Error retrieving user group data: ", err) });
    }

    //creates a new group
    onSubmit = e => {
        e.preventDefault();
        this.handleClose();

        var gameObject = this.state.games.find(game => game.gameName === this.state.gameName);
        var url = gameObject.linkURL;

        const payload = {
            userID: this.props.auth.user.id,
            groupOwner: this.props.auth.user.id,
            groupName: this.state.groupName,
            isPrivate: this.state.isPrivate,
            gameName: this.state.gameName,
            admins: [this.props.auth.user.id],
            members: [this.props.auth.user.id],
            linkURL: url
        }

        axios.post("/api/groups/createGroup", payload).then(() => { 
            this.getUserGroups(); 
        }).catch((err) => { 
            console.log("Error retrieving games data: ", err) 
        });

    };

    //handlers
    handleShow = () => {
        //grab game data for options first, then show modal
        axios
            .get("api/games/getGames")
            .then((response) => {
                const data = response.data;
                this.setState({ games: data });
                this.setState({ gameName: data[0].gameName });
            })
            .catch((err) => { console.log("Error retrieving games data: ", err) })
            .then(() => { this.setState({ show: true }); });
    }

    handleClose = () => {
        //reset from variables
        this.setState({ show: false, groupName: "", isPrivate: true, gameName: "", linkURL: "" });
    }

    onChange = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        this.setState({ [name]: value });
    };

    renderSwitch(name) {
        switch (name) {
            case "Civilization 4":
                return <Card.Img variant="top" src={civ4Icon} />
            case "Civilization 5":
                return <Card.Img variant="top" src={civ5Icon} />
            case "Civilization 6":
                return <Card.Img variant="top" src={civ6Icon} />
            case "Dota 2":
                return <Card.Img variant="top" src={dota2Icon} />
            case "Old School RuneScape":
                return <Card.Img variant="top" src={osrsIcon} />
            case "RuneScape":
                return <Card.Img variant="top" src={rsIcon} />
            default:
                return null;
        }
    }

    //display each group card
    groupCards = (groups) => {

        if (!groups.length) {
            return null;
        }
        else {
            return (
                <Row xs={1} sm={2} md={4} lg={6} className="g-4">
                    {this.state.groups.map((group, index) => (
                        <Col key={index} >

                            <Card className="box" style={{ maxWidth: '300px' }} variant={this.state.variant} bg={this.state.variant} onClick={() => this.props.history.push(`/${group.linkURL}/welcome?groupID=${group._id}`)}> 
                                <Card.Body>
                                    <Card.Title>
                                        {group.groupName}
                                    </Card.Title>
                                    <Card.Subtitle>
                                        {group.gameName}
                                    </Card.Subtitle>
                                    {this.renderSwitch(group.gameName)}
                                </Card.Body>
                                {/* <Card.Footer>
                                    <Button style={{ verticalAlign: "center" }} href={}>
                                        Navigate to Group
                                    </Button>
                                </Card.Footer> */}
                            </Card>
                        </Col>
                    ))}
                </Row>
            );
        }
    }

    //display game options in selectbox
    gameOptions = (games) => {
        if (!games.length) {
            return null;
        }
        else {
            return games.map((game, index) => (
                <option key={index} value={game.gameName}>{game.gameName}</option>
            ));
        }
    }

    render() {
        const { errors } = this.state;

        return (

            <div className="h-100">
                <Navbar expand="lg" variant={this.state.variant} bg={this.state.variant}>
                        <Navbar.Brand className="px-3">Dashboard</Navbar.Brand>
                        <Button className="ms-auto mx-3" onClick={this.handleShow}>Add</Button>
                </Navbar>

                <div className="mx-3 my-3">
                    {this.groupCards(this.state.groups)}
                </div>

                {/* ADD MODAL */}
                <Modal show={this.state.show} onHide={this.handleClose} centered animation={true} backdrop="static" keyboard={false}>
                    <Modal.Header>
                        <Modal.Title>Create New group</Modal.Title>
                        <button type="button" onClick={this.handleClose} className="btn-close" aria-label="Close"></button>
                    </Modal.Header>
                    <Modal.Body style={{ margin: '5px' }}>

                        <Form noValidate>
                            <Form.Group
                                controlId="groupName"
                                style={{ margin: '5px' }}
                            >
                                <Form.Label>Group Name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    value={this.state.groupName}
                                    onChange={this.onChange}
                                    error={errors.groupName}
                                    isInvalid={errors.groupName}
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.groupName}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group style={{ margin: '5px' }}>
                                <Form.Control
                                    as="select"
                                    aria-label="Game Select"
                                    id="gameName"
                                    value={this.state.gameName}
                                    onChange={this.onChange}
                                >
                                    {this.gameOptions(this.state.games)}
                                </Form.Control>
                            </Form.Group>

                            <Form.Check
                                type="checkbox"
                                id="isPrivate"
                                label="Private group"
                                onChange={this.onChange}
                                checked={this.state.isPrivate}
                                style={{ margin: '5px' }}
                            />

                            <Form.Group styel={{ margin: '5px' }}>
                                <Button type="submit" onClick={this.onSubmit} as={Col} xs={2}>
                                    Submit
                                </Button>
                                <Button onClick={this.handleClose} as={Col} xs={2} style={{ marginLeft: '10px' }}>
                                    Cancel
                                </Button>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                </Modal>

            </div>
        );
    }
}

Dashboard.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(Dashboard);