import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from 'axios';

//import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
// import CardGroup from 'react-bootstrap/CardGroup';


class SteamTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            auth: {},
            errors: {},
            steamID: "",
            steamProfile: {},
            steamGames: []
        };
    }

    componentDidMount() {
        if (this.props.auth.user.steamID !== "") {
            this.setState({ steamID: this.props.auth.user.steamID });

            this.getSteamProfile(this.props.auth.user.steamID);
            this.getSteamGames(this.props.auth.user.steamID);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.auth.user.steamID !== "") {
                this.setState({ steamID: this.props.auth.user.steamID });
            }
        }
    }

    getSteamProfile = (steamID) => {
        axios
            .get("/api/steam/getProfile/" + steamID)
            .then(res => {
                this.setState({ steamProfile: res.data });
            })
            .catch(err => {
                console.log("Unexpected error with loading steam profile: ", err);
            });
    }

    getSteamGames = (steamID) => {
        axios
            .get("/api/steam/getGames/" + steamID)
            .then(res => {
                this.setState({ steamGames: res.data });
            })
            .catch(err => {
                console.log("Unexpected error with loading steam profile: ", err);
            });
    }

    //attempts to link steam account
    onSubmit = e => {
        e.preventDefault();
    };

    //handlers
    handleShow = () => {
        this.setState({ show: true });
    }

    handleClose = () => {
        //reset from variables
        this.setState({ show: false });
    }

    onChange = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        this.setState({ [name]: value });
    };

    onClickRow = (row) => {
        // console.log("Logging row: ", row);
    }

    displayProfilePic = (state) => {
        if (state) {
            return (
                <Card.Img variant="top" src={this.state.steamProfile.avatar.large} />
            );
        }
        else {
            return null;
        }
    }

    displayGamesList = (games) => {
        if (!games) {
            return null;
        }
        else if (!games.length) {
            return null;
        }
        else {
            return games.map((game, index) => (
                <tr key={index} onClick={() => this.onClickRow(game)}>
                    <td><Image src={game.iconURL} thumbnail /></td>
                    <td>{game.name}</td>
                    {/* <td>{game.host}</td>
                    <td>{new Date(game.dateCreated).toDateString()}</td> */}
                </tr>
            ));
        }
    }

    render() {
        // const { errors } = this.state;

        if (this.state.steamID !== "" && this.state.steamID !== null) {
            return (
                <div className="h-100">
                    <div className="container" style={{ marginTop: '20px' }}>
                        <Container>
                            <Row>
                                <Col >
                                    <Card style={{ width: '18rem' }}>
                                        {this.displayProfilePic(this.state.steamProfile.avatar)}

                                        <Card.Body>
                                            <Card.Title>{this.state.steamProfile.nickname}</Card.Title>
                                            <a href={this.state.steamProfile.url}> Steam Profile </a>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs lg="9">
                                    <Card>
                                        <Card.Header>
                                            Games
                                        </Card.Header>
                                        <Card.Body>
                                            <Table responsive striped bordered hover>
                                                <tbody>
                                                    {this.displayGamesList(this.state.steamGames)}
                                                </tbody >
                                            </Table>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>

                            </Row>
                        </Container>


                    </div>
                </div>
            );
        }
        else {
            return (
                <div className="h-100">
                    <div className="container" style={{ marginTop: '20px' }}>
                        <a href="https://squadmatchmake.ca/api/auth/steam">Link Steam Account</a>
                    </div>
                </div>
            );
        }


    }
}

SteamTab.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(SteamTab);