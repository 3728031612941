const victoryOptions = ["Earliest", "Early", "Standard", "Off"];
const boolOptions = [true, false];

const civilization1 = [

];

const civilization2 = [

];

const civilization3 = [

];

const civilization4 = [

];

const civilization5 = [
];

const civilization6 = {
    gameModes: [
        {
            title: "Apocalypse Mode",
            defaultValue: false,
            options: boolOptions,
            component: "Checkbox",
            value: null,
            isEnabled: true
        },
        {
            title: "Barbarian Clans Mode",
            defaultValue: false,
            options: boolOptions,
            component: "Checkbox",
            value: null,
            isEnabled: true
        },
        {
            title: "Dramatic Ages Mode",
            defaultValue: false,
            options: boolOptions,
            component: "Checkbox",
            value: null,
            isEnabled: true
        },
        {
            title: "Heroes & Legends Mode",
            defaultValue: false,
            options: boolOptions,
            component: "Checkbox",
            value: null,
            isEnabled: true
        },
        {
            title: "Monopolies and Corporations Mode",
            defaultValue: false,
            options: boolOptions,
            component: "Checkbox",
            value: null,
            isEnabled: true
        },
        {
            title: "Secret Societies Mode",
            defaultValue: false,
            options: boolOptions,
            component: "Checkbox",
            value: null,
            isEnabled: true
        },
        {
            title: "Tech and Civic Shuffle Mode",
            defaultValue: false,
            options: boolOptions,
            component: "Checkbox",
            value: null,
            isEnabled: true
        },
        {
            title: "Zombie Defense Mode",
            defaultValue: false,
            options: boolOptions,
            component: "Checkbox",
            value: null,
            isEnabled: true
        },
        {
            title: "Sudden Death Mode",
            defaultValue: false,
            options: boolOptions,
            component: "Checkbox",
            value: null,
            isEnabled: true
        },
        {
            title: "Anonymous Mode",
            defaultValue: false,
            options: boolOptions,
            component: "Checkbox",
            value: null,
            isEnabled: true
        }
    ],
    victoryTypes: [
        {
            title: "Science",
            defaultValue: "Standard",
            options: victoryOptions,
            component: "Selectbox",
            value: null,
            isEnabled: true
        },
        {
            title: "Diplomacy",
            defaultValue: "Standard",
            options: victoryOptions,
            component: "Selectbox",
            value: null,
            isEnabled: true
        },
        {
            title: "Domination",
            defaultValue: "Standard",
            options: victoryOptions,
            component: "Selectbox",
            value: null,
            isEnabled: true
        },
        {
            title: "Religious",
            defaultValue: "Standard",
            options: victoryOptions,
            component: "Selectbox",
            value: null,
            isEnabled: true
        },
        {
            title: "Culture",
            defaultValue: "Standard",
            options: victoryOptions,
            component: "Selectbox",
            value: null,
            isEnabled: true
        },
        {
            title: "Territorial",
            defaultValue: "Standard",
            options: victoryOptions,
            component: "Selectbox",
            value: null,
            isEnabled: true
        }
    ],
    gameSettings: [
        {
            title: "Turn Timer",
            defaultValue: "Standard Turn Timer",
            options: ["No Turn Timer", "Standard Turn Timer", "Dynamic Turn Timer"], 
            component: 'Selectbox',
            value: null,
            isEnabled: true
        },
        {
            title: "Turn Mode",
            defaultValue: "Dynamic Turns",
            options: ["Simultaneous Turns", "Dynamic Turns"], 
            component: 'Selectbox',
            value: null,
            isEnabled: true
        },
        {
            title: "Smart-Timer",
            defaultValue: "Moderate",
            options: ["Off", "Casual", "Classic", "Moderate", "Competitive", "Insane", "Blitz", "2VI2 HOT"], 
            component: 'Selectbox',
            value: null,
            isEnabled: true
        },
        {
            title: "Start Era",
            defaultValue: "Ancient Era",
            options: ["Ancient Era", "Classical Era", "Medieval Era", "Renaissance Era", "Industrial Era", "Modern Era", "Atomic Era", "Information Era"], 
            component: 'Selectbox',
            value: null,
            isEnabled: true
        },
        {
            title: "Game Speed",
            defaultValue: "Online",
            options: ["Online", "Quick", "Standard", "Epic", "Marathon"], 
            component: 'Selectbox',
            value: null,
            isEnabled: true
        },
        // cityStates: {

        // },
        {
            title: "Disaster Intensity",
            defaultValue: "2",
            options: ["0", "1", "2", "3", "4"], 
            component: 'Selectbox',
            value: null,
            isEnabled: true
        },
        {
            title: "Map Size",
            defaultValue: "Standard",
            options: ["Undersized", "Standard", "Oversized"], 
            component: 'Selectbox',
            value: null,
            isEnabled: true
        },
        {
            title: "Resources",
            defaultValue: "Standard",
            options: ["Sparse", "Standard", "Abundant", "Random"], 
            component: 'Selectbox',
            value: null,
            isEnabled: true
        },
        {
            title: "Strategic Resources",
            defaultValue: "Standard",
            options: ["Standard", "Abundant", "Epic", "Spawn Guaranteed"], 
            component: 'Selectbox',
            value: null,
            isEnabled: true
        },
        {
            title: "Natural Wonder Density",
            defaultValue: "Standard",
            options: ["Scarce", "Standard", "Abundant"], 
            component: 'Selectbox',
            value: null,
            isEnabled: true
        },
        {
            title: "World Age",
            defaultValue: "Standard",
            options: ["New", "Standard", "Old", "Random"], 
            component: 'Selectbox',
            value: null,
            isEnabled: true
        },
        {
            title: "Ridges Definition",
            defaultValue: "Standard",
            options: ["Standard", "Classic", "Large Opening", "Impenetrable"], 
            component: 'Selectbox',
            value: null,
            isEnabled: true
        },
        {
            title: "Start Position",
            defaultValue: "Balanced",
            options: ["Balanced", "Standard", "Legendary"], 
            component: 'Selectbox',
            value: null,
            isEnabled: true
        },
        {
            title: "Temperature",
            defaultValue: "Standard",
            options: ["Hot", "Standard", "Cold", "Random"], 
            component: 'Selectbox',
            value: null,
            isEnabled: true
        },
        {
            title: "Rainfall",
            defaultValue: "Standard",
            options: ["Arid", "Standard", "Wet", "Random"], 
            component: 'Selectbox',
            value: null,
            isEnabled: true
        },
        {
            title: "Sea Level",
            defaultValue: "Standard",
            options: ["Low", "Standard", "High", "Random"], 
            component: 'Selectbox',
            value: null,
            isEnabled: true
        }
    ],
    tradingAndDiplomacy: [
        {
            title: "No diplomatic deals",
            defaultValue: false,
            options: boolOptions,
            component: "Checkbox",
            value: null,
            isEnabled: true
        },
        {
            title: "No surprise war",
            defaultValue: false,
            options: boolOptions,
            component: "Checkbox",
            value: null,
            isEnabled: true
        },
        {
            title: "No friendship declaration",
            defaultValue: false,
            options: boolOptions,
            component: "Checkbox",
            value: null,
            isEnabled: true
        },
        {
            title: "No favor trading",
            defaultValue: false,
            options: boolOptions,
            component: "Checkbox",
            value: null,
            isEnabled: true
        },
        {
            title: "No gold trading",
            defaultValue: false,
            options: boolOptions,
            component: "Checkbox",
            value: null,
            isEnabled: true
        },
        {
            title: "No strategic resource trading",
            defaultValue: false,
            options: boolOptions,
            component: "Checkbox",
            value: null,
            isEnabled: true
        },
        {
            title: "No luxury resource trading",
            defaultValue: false,
            options: boolOptions,
            component: "Checkbox",
            value: null,
            isEnabled: true
        },
        {
            title: "No Agreements",
            defaultValue: false,
            options: boolOptions,
            component: "Checkbox",
            value: null,
            isEnabled: true
        },
        {
            title: "No city trading",
            defaultValue: false,
            options: boolOptions,
            component: "Checkbox",
            value: null,
            isEnabled: true
        },
        {
            title: "No great work trading",
            defaultValue: false,
            options: boolOptions,
            component: "Checkbox",
            value: null,
            isEnabled: true
        },
        {
            title: "No spy trading",
            defaultValue: false,
            options: boolOptions,
            component: "Checkbox",
            value: null,
            isEnabled: true
        },
        {
            title: "Cultural Alliance",
            defaultValue: false,
            options: boolOptions,
            component: "Checkbox",
            value: null,
            isEnabled: true
        },
        {
            title: "Economic Alliance",
            defaultValue: false,
            options: boolOptions,
            component: "Checkbox",
            value: null,
            isEnabled: true
        },
        {
            title: "Military Alliance",
            defaultValue: false,
            options: boolOptions,
            component: "Checkbox",
            value: null,
            isEnabled: true
        },
        {
            title: "Religious Alliance",
            defaultValue: false,
            options: boolOptions,
            component: "Checkbox",
            value: null,
            isEnabled: true
        },
        {
            title: "Research Alliance",
            defaultValue: false,
            options: boolOptions,
            component: "Checkbox",
            value: null,
            isEnabled: true
        }
    ]
};

const gameSettings = {
    "Civilization 1": civilization1,
    "Civilization 2": civilization2,
    "Civilization 3": civilization3,
    "Civilization 4": civilization4,
    "Civilization 5": civilization5,
    "Civilization 6": civilization6,
};

export default gameSettings;