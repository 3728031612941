const civilization1 = [
    
];

const civilization2 = [
    
];

const civilization3 = [
    
];

const civilization4 = [
    
];

const civilization5 = [
    { nation: "American", leader: "Washington", duplicate: false },
    { nation: "Arabian", leader: "Harun al-Rashid", duplicate: false },
    { nation: "Assyrian", leader: "Ashurbanipal", duplicate: false },
    { nation: "Austrian", leader: "Maria Theresa", duplicate: false },
    { nation: "Aztec", leader: "Montezuma", duplicate: false },
    { nation: "Babylonian", leader: "Nebuchadnezzar II", duplicate: false },
    { nation: "Brazilian", leader: "Pedro II", duplicate: false },
    { nation: "Byzantine", leader: "Theodora", duplicate: false },
    { nation: "Carthaginian", leader: "Dido", duplicate: false },
    { nation: "Celtic", leader: "Boudicca", duplicate: false },
    { nation: "Chinese", leader: "Wu Zetian", duplicate: false },
    { nation: "Danish", leader: "Harald Bluetooth", duplicate: false },
    { nation: "Dutch", leader: "William", duplicate: false },
    { nation: "Egyptian", leader: "Ramesses II", duplicate: false },
    { nation: "English", leader: "Elizabeth", duplicate: false },
    { nation: "Ethiopian", leader: "Haile Selassie", duplicate: false },
    { nation: "French", leader: "Napolean", duplicate: false },
    { nation: "German", leader: "Bismarck", duplicate: false },
    { nation: "Greek", leader: "Alexander", duplicate: false },
    { nation: "Hunnic", leader: "Attila", duplicate: false },
    { nation: "Incan", leader: "Pachacuti", duplicate: false },
    { nation: "Indian", leader: "Gandhi", duplicate: false },
    { nation: "Indonesian", leader: "Gajah Mada", duplicate: false },
    { nation: "Iroquois", leader: "Hiawatha", duplicate: false },
    { nation: "Japanese", leader: "Oda Nobunaga", duplicate: false },
    { nation: "Korean", leader: "Sejong", duplicate: false },
    { nation: "Mayan", leader: "Pacal", duplicate: false },
    { nation: "Mongolian", leader: "Genghis Khan", duplicate: false },
    { nation: "Moroccan", leader: "Ahmad al-Mansur", duplicate: false },
    { nation: "Ottoman", leader: "Suleiman", duplicate: false },
    { nation: "Persian", leader: "Darius I", duplicate: false },
    { nation: "Polish", leader: "Casimir III", duplicate: false },
    { nation: "Polynesian", leader: "Kamehameha", duplicate: false },
    { nation: "Portugese", leader: "Maria I", duplicate: false },
    { nation: "Roman", leader: "Augustus Caesar", duplicate: false },
    { nation: "Russian", leader: "Catherine", duplicate: false },
    { nation: "Shoshone", leader: "Pocatello", duplicate: false },
    { nation: "Siamese", leader: "Ramkhamhaeng", duplicate: false },
    { nation: "Songhai", leader: "Askia", duplicate: false },
    { nation: "Spanish", leader: "Isabella", duplicate: false },
    { nation: "Swedish", leader: "Gustavus Adolphus", duplicate: false },
    { nation: "Venetian", leader: "Enrico Dandolo", duplicate: false },
    { nation: "Zulu", leader: "Shaka", duplicate: false },
];

const civilization6 = {
    1: { civID: 1, nation: "American", leader: "Abraham Lincoln", dupNations: [2, 3] },
    2: { civID: 2, nation: "American", leader: "Teddy Roosevelt - Rough Rider", dupNations: [1, 3], dupLeaders: [3] },
    3: { civID: 3, nation: "American", leader: "Teddy Roosevelt - Bull Moose", dupNations: [1, 2], dupLeaders: [2] },
    4: { civID: 4, nation: "Arabian", leader: "Saladin (Sultan)", dupNations: [5], dupLeaders: [5] },
    5: { civID: 5, nation: "Arabian", leader: "Saladin (Vizier)", dupNations: [4], dupLeaders: [4] },
    6: { civID: 6, nation: "Australian", leader: "John Curtin" },
    7: { civID: 7, nation: "Aztec", leader: "Montezuma" },
    8: { civID: 8, nation: "Babylonian", leader: "Hammurabi" },
    9: { civID: 9, nation: "Brazilian", leader: "Pedro II" },
    10: { civID: 10, nation: "Byzantine", leader: "Basil II", dupNations: [65] },
    65: { civID: 65, nation: "Byzantine", leader: "Theodora", dupNations: [10] }, //done
    11: { civID: 11, nation: "Canadian", leader: "Wilfrid Laurier" },
    12: { civID: 12, nation: "Chinese", leader: "Qin (Mandate of Heaven)", dupNations: [13, 66, 72, 76] },
    72: { civID: 72, nation: "Chinese", leader: "Qin (Unifier)", dupNations: [66, 12, 13, 76] }, //done
    13: { civID: 13, nation: "Chinese", leader: "Kublai Khan", dupNations: [12, 72, 76, 66], dupLeaders: [46] },
    76: { civID: 76, nation: "Chinese", leader: "Yongle", dupNations: [12, 13, 72, 66] }, //done
    66: { civID: 66, nation: "Chinese", leader: "Wu Zetian", dupNations: [72, 12, 13, 76] }, //done
    14: { civID: 14, nation: "Cree", leader: "Poundmaker" },
    15: { civID: 15, nation: "Dutch", leader: "Wilhelmina" },
    16: { civID: 16, nation: "Egyptian", leader: "Cleopatra (Egyptian)", dupNations: [67], dupLeaders: [68] },
    68: { civID: 68, nation: "Egyptian", leader: "Cleopatra (Ptolemaic)", dupNations: [67], dupLeaders: [16] }, //done
    67: { civID: 67, nation: "Egyptian", leader: "Ramses II", dupNations: [68, 16] }, //done
    17: { civID: 17, nation: "English", leader: "Victoria (Age of Empire)", dupNations: [18, 69], dupLeaders: [75] },
    75: { civID: 75, nation: "English", leader: "Victoria (Age of Steam)", dupNations: [18, 69], dupLeaders: [17] }, //done
    69: { civID: 69, nation: "English", leader: "Elizabeth I", dupNations: [17, 18, 75] }, //done
    18: { civID: 18, nation: "English", leader: "Eleanor of Aquitane", dupNations: [17, 69, 75], dupLeaders: [22] },
    19: { civID: 19, nation: "Ethiopian", leader: "Menelik II" },
    20: { civID: 20, nation: "French", leader: "Catherine de Medici - Black Queen", dupNations: [21, 22], dupLeaders: [21] },
    21: { civID: 21, nation: "French", leader: "Catherine de Medici - Magnificence", dupNations: [20, 22], dupLeaders: [20] },
    22: { civID: 22, nation: "French", leader: "Eleanor of Aquitane", dupNations: [20, 21], dupLeaders: [18] },
    23: { civID: 23, nation: "Gallic", leader: "Ambiorix" },
    24: { civID: 24, nation: "Georgian", leader: "Tamar" },
    25: { civID: 25, nation: "German", leader: "Frederick Barbarossa", dupNations: [71] },
    71: { civID: 71, nation: "German", leader: "Ludwig II", dupNations: [25] }, //done
    26: { civID: 26, nation: "Gran Colombian", leader: "Simon Bolivar", expansion: "Maya & Gran Colombia Pack" },
    27: { civID: 27, nation: "Greek", leader: "Pericles", dupNations: [28] },
    28: { civID: 28, nation: "Greek", leader: "Gorgo", dupNations: [27] },
    29: { civID: 29, nation: "Hungarian", leader: "Matthias Cornivus" },
    30: { civID: 30, nation: "Incan", leader: "Pachacuti" },
    31: { civID: 31, nation: "Indian", leader: "Gandhi", dupNations: [32] },
    32: { civID: 32, nation: "Indian", leader: "Chandragupta", dupNations: [31] },
    33: { civID: 33, nation: "Indonesian", leader: "Gitarja" },
    34: { civID: 34, nation: "Japanese", leader: "Hojo Tokimune", dupNations: [35] },
    35: { civID: 35, nation: "Japanese", leader: "Tokugawa", dupNations: [34] },
    36: { civID: 36, nation: "Khmer", leader: "Jayavarman VII" },
    37: { civID: 37, nation: "Kongolese", leader: "Mvemba a Nzinga", dupNations: [38] },
    38: { civID: 38, nation: "Kongolese", leader: "Nzinga Mbande", dupNations: [37] },
    39: { civID: 39, nation: "Korean", leader: "Seondeok", dupNations: [73] },
    73: { civID: 73, nation: "Korean", leader: "Sejong", dupNations: [39] }, //done
    40: { civID: 40, nation: "Macedonian", leader: "Alexander" },
    41: { civID: 41, nation: "Malian", leader: "Mansa Musa", dupNations: [74] },
    74: { civID: 74, nation: "Malian", leader: "Sundiata Keita", dupNations: [41] }, //done
    42: { civID: 42, nation: "Maori", leader: "Kupe" },
    43: { civID: 43, nation: "Mapuche", leader: "Lautaro" },
    44: { civID: 44, nation: "Mayan", leader: "Lady Six Sky", expansion: "Maya & Gran Colombia Pack" },
    45: { civID: 45, nation: "Mongolian", leader: "Genghis Khan", dupNations: [46] },
    46: { civID: 46, nation: "Mongolian", leader: "Kublai Khan", dupNations: [45], dupLeaders: [13] },
    47: { civID: 47, nation: "Norwegian", leader: "Harald Hardrada (Konge)", dupLeaders: [70] },
    70: { civID: 70, nation: "Norwegian", leader: "Harald Hardrada (Varangian)", dupLeaders: [47] }, //done
    48: { civID: 48, nation: "Nubian", leader: "Amanitore" },
    49: { civID: 49, nation: "Ottoman", leader: "Suleiman (Kanuni)", dupNations: [50], dupLeaders: [50] },
    50: { civID: 50, nation: "Ottoman", leader: "Suleiman (Muhtesem)", dupNations: [49], dupLeaders: [49] },
    51: { civID: 51, nation: "Persian", leader: "Cyrus", dupNations: [52] },
    52: { civID: 52, nation: "Persian", leader: "Nader Shah", dupNations: [51] },
    53: { civID: 53, nation: "Phoenician", leader: "Dido" },
    54: { civID: 54, nation: "Polish", leader: "Jadwiga" },
    55: { civID: 55, nation: "Portugese", leader: "Joao III" },
    56: { civID: 56, nation: "Roman", leader: "Trajan" },
    57: { civID: 57, nation: "Russian", leader: "Peter" },
    58: { civID: 58, nation: "Scottish", leader: "Robert the Bruce" },
    59: { civID: 59, nation: "Scythian", leader: "Tomyris" },
    60: { civID: 60, nation: "Spanish", leader: "Philip II" },
    61: { civID: 61, nation: "Sumerian", leader: "Gilgamesh" },
    62: { civID: 62, nation: "Swedish", leader: "Kristina" },
    63: { civID: 63, nation: "Vietnamese", leader: "Ba Trieu" },
    64: { civID: 64, nation: "Zulu", leader: "Shaka" }
};

const civilizations = {
    "Civilization 1": civilization1, 
    "Civilization 2": civilization2, 
    "Civilization 3": civilization3, 
    "Civilization 4": civilization4, 
    "Civilization 5": civilization5, 
    "Civilization 6": civilization6,
};

export default civilizations;