import axios from 'axios';

const restService = {
    //games
    civilization: {
        getAllCivPreferences(groupID) {
            return axios.get(`/api/civilization/getAllCivPreferences?groupID=${groupID}`);
        },
        getMemberCivPreferences(groupID, members) {
            return axios.post(`/api/civilization/getMemberCivPreferences?groupID=${groupID}`, members);
        },
        getCivPreferences(groupID, userID) {
            return axios.get(`/api/civilization/getCivPreferences?groupID=${groupID}&userID=${userID}`);
        },
        postUserLeaderAgenda(groupID, userID, payload) {
            return axios.post(`/api/civilization/postUserLeaderAgenda/${groupID}/${userID}`, payload);
        },
        getUserLeaderAgenda(groupID, userID) {
            return axios.get(`/api/civilization/getUserLeaderAgenda/${groupID}/${userID}`);
        },
        getGroupLeaderAgendas(groupID) {
            return axios.get(`/api/civilization/getGroupLeaderAgendas/${groupID}`);
        },
        postCreateGame(payload) {
            return axios.post("/api/civilization/createGame", payload);
        },
        postCivMapPreference(groupID, userID, payload) {
            return axios.post(`/api/civilization/postCivMapPreference/${groupID}/${userID}`, payload);
        },
        postCivLeaderBanPreference(groupID, userID, payload) {
            return axios.post(`/api/civilization/postCivLeaderBanPreference/${groupID}/${userID}`, payload);
        },
        postGameSettingPreference(groupID, userID, payload) {
            return axios.post(`/api/civilization/postGameSettingPreference/${groupID}/${userID}`, payload);
        },
        postGroupGameSetting(groupID, payload) {
            return axios.post(`/api/civilization/postGroupGameSetting/${groupID}`, payload);
        },
        postGroupSettings(groupID, payload) {
            return axios.post(`/api/civilization/updateGroup?groupID=${groupID}`, payload);
        },
        postDeleteGame(gameID) {
            return axios.post(`/api/civilization/deleteGame?gameID=${gameID}`);
        },
        getActiveGames(groupID) {
            return axios.get(`/api/civilization/getActiveGames?groupID=${groupID}`);
        },
        getFinishedGames(groupID, gameType) {
            return axios.get(`/api/civilization/getFinishedGames?groupID=${groupID}&gameType=${gameType}`);
        },
        Members: {
            getGroupAvailability(groupID) {
                return axios.get(`/api/civilization/getGroupAvailability?groupID=${groupID}`);
            }
        },
        UserSettings: {
            postPlayerAvailability(groupID, userID, payload) {
                return axios.post(`/api/civilization/postPlayerAvailability/${groupID}/${userID}`, payload);
            },
            getPlayerAvailability(groupID, userID) {
                return axios.get(`/api/civilization/getPlayerAvailability/${groupID}/${userID}`);
            }
        }
        // postGameSettings(groupID) {
        //     return axios.post(`/api/groups/updateGroup?groupID=${groupID}`, payload);
        // }
    },
    //general
    comments: {
        getComments(gameID, groupID) {
            return axios.get(`/api/comments/getComments/${ gameID }/${ groupID }`)
        },
        postComment(payload) {
            return axios.post("/api/comments/postComment", payload);
        }
    },
    components: {
        getGroupCards(groupID) {
            return axios.get(`/api/components/getGroupCards?groupID=${groupID}`);
        },
        postGroupCard(payload) {
            return axios.post(`/api/components/postGroupCard`, payload)
        },
        postGroupCardOrder(groupID, payload) {
            return axios.post(`/api/components/postGroupCardOrder?groupID=${groupID}`, payload);
        },
        postRemoveGroupCard(payload) {
            return axios.post("/api/components/postRemoveGroupCard", payload);
        }
    },
    events: {
        civDraft(eventID, payload) {
            return axios.post(`/api/events/updateEvent?eventID=${eventID}`, payload);
        }
    },
    games: {
        
    },
    groups: {
        getGroup(groupID) {
            return axios.get(`/api/groups/getGroup?groupID=${groupID}`);
        },
        getFullGroup(groupID) {
            return axios.get(`/api/groups/getFullGroup?groupID=${groupID}`);
        },
        postGroupSettings(groupID, payload) {
            return axios.post(`/api/groups/postGroupSettings?groupID=${groupID}`, payload);
        },
        getMembers(params) {
            return axios.get(`/api/groups/getMembers`, { params });
        },
        joinGroup(payload) {
            return axios.post("/api/groups/joinGroup", payload);
        },
        updateMemberRole(groupID, payload) {
            return axios.post(`/api/groups/postPlayerMembership/${groupID}`, payload);
        },
        kickMember(payload) {
            return axios.post("/api/groups/kickMember", payload);
        },
        leaveGroup(payload) {
            return axios.post(`/api/groups/leaveGroup`, payload);
        },
        deleteGroup(groupID) {
            return axios.post(`/api/groups/deleteGroup/${groupID}`);
        }
    },
    members: {
        postMemberNotifications(groupID, userID, payload) {
            return axios.post(`/api/members/postMemberNotifications/${groupID}/${userID}`, payload)
        },
    },
    //getOSRSDetails
    runeScape: {
        getOSRSDetails(playerName, gameMode) {
            return axios.get("/api/runescape/getOSRSDetails/" + playerName + "/" + gameMode);
        }
    }
}

export default restService;