import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import axios from 'axios';

// react imports
// import Button from 'react-bootstrap/Button';
// import Container from 'react-bootstrap/Container';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
// import Navbar from 'react-bootstrap/Navbar';
// import Nav from 'react-bootstrap/Nav';
// import Modal from 'react-bootstrap/Modal';

//custom component imports
import GameDetailsTab from './CivGameTabs/GameDetailsTab';
import GameNotesTab from './CivGameTabs/GameNotesTab';
import GameSettingsTab from './CivGameTabs/GameSettingsTab';

class CivGame extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            show: false,
            showConfirm: false,
            game: {},
            gameID: "",
            groupID: "",
            errors: {}, 
        };
    }

    componentDidMount() {
        try {
            //get group data
            var history = this.props.location.pathname + this.props.location.search;
            //get leagueID from history URL
            const match = matchPath(history, {
                path: "/civGroup/civGame?groupID=:groupID&gameID=:gameID",
                exact: true,
                strict: false
            });

            if (this.props.auth.isAuthenticated) {
                this.props.history.push(history);
            }

            var groupID = match.params.groupID;
            var gameID = match.params.gameID;

            this.setState({ 
                gameID: gameID, 
                groupID: groupID
            });

            this.getGameInfo(gameID);
        }
        catch (err) {
            console.log("Unexpected error trying to setup Game Page: ", err);
            //this.props.history.push("/");
        }
    }

    getGameInfo = (gameID) => {
        axios
            .get(`/api/civilization/game?gameID=${gameID}`)
            .then((response) => {
                const data = response.data;
                this.setState({ game: data, isLoading: false });
            })
            .catch((err) => { console.log("Error retrieving game data: ", err) });
    }

    // componentDidUpdate(prevProps) {
    //     if (this.props !== prevProps){
    //         console.log("Logging this.props: ", this.props);
    //     }
    // }

    back = () => {
        this.props.history.push("/civGroup?groupID=" + this.state.groupID);
    }

    displayGameDetails = () => {
        if (this.state.groupID === "" || this.state.game === null || this.state.isLoading) {
            return null;
        }
        else {
            return <GameDetailsTab groupID={ this.state.groupID } game={ this.state.game } history={ this.props.history }/>
        }
    }

    displaGameSettingsTab = () => {
        if (this.state.groupID === "" || this.state.game === null || this.state.isLoading) {
            return null;
        }
        else {
            return <GameSettingsTab groupID={ this.state.groupID } game={ this.state.game } />
        }
    }
    
    displayGameNotesTab = () => {
        if (this.state.groupID === "" || this.state.game === null || this.state.isLoading) {
            return null;
        }
        else {
            return <GameNotesTab groupID={ this.state.groupID } game={ this.state.game } />
        }
    }

    render() {
        return (
            <div>
                <Tabs defaultActiveKey="gameDetails" id="activeTab" className="mb-3">
                    <Tab eventKey="gameDetails" title="Game Details">
                        { this.displayGameDetails() }
                    </Tab>
                    <Tab eventKey="gameSettings" title="Game Settings">
                        { this.displaGameSettingsTab() }
                    </Tab>
                    <Tab eventKey="gameNotes" title="Game Notes">
                        { this.displayGameNotesTab() }
                    </Tab>
                    
                </Tabs>
            </div>
        );
    }
}

CivGame.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(CivGame);