import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";

import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';

class TopNavbar extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  loggedInNav = () => {
    return (
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="px-1">
        <Navbar.Brand className="px-2">SMM</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="px-1">
          <Nav className="me-auto px-1">
            <Nav.Link href="/dashboard">Dashboard</Nav.Link>
            {/* <Nav.Link href="/search">Search</Nav.Link> */}
          </Nav>

          <Nav>
            <NavDropdown title="Support" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/contact">Contact Us</NavDropdown.Item>
              <NavDropdown.Item href="/releaseNotes">Release Notes</NavDropdown.Item>
              <NavDropdown.Item href="/faq">FAQ</NavDropdown.Item>
              <NavDropdown.Item href="/support">Support</NavDropdown.Item>
              <NavDropdown.Item href="/upcoming">Upcoming</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/profile">
              Profile
            </Nav.Link>
            <Nav.Link href="/login" onClick={this.onLogoutClick}>
              Logout
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }

  loggedOutNav = () => {
    return (
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container>
          <Navbar.Brand style={{ marginLeft: "10px" }}>Home</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav>
              <NavDropdown title="Support" id="collasible-nav-dropdown">
                <NavDropdown.Item href="/contact">Contact Us</NavDropdown.Item>
                <NavDropdown.Item href="/releaseNotes">Release Notes</NavDropdown.Item>
                <NavDropdown.Item href="/faq">FAQ</NavDropdown.Item>
                <NavDropdown.Item href="/support">Support</NavDropdown.Item>
                <NavDropdown.Item href="/upcoming">Upcoming</NavDropdown.Item>
              </NavDropdown>

              <Nav.Link href="/login">
                Login
              </Nav.Link>
              <Nav.Link href="/register">
                Register
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }

  render() {
    if (this.props.auth.isAuthenticated) {
      return this.loggedInNav();
    }
    return this.loggedOutNav();
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { logoutUser }
)(withRouter(TopNavbar));