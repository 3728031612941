import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from 'axios';
import _ from 'lodash';

import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
// import Navbar from 'react-bootstrap/Navbar';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// import Table from 'react-bootstrap/Table';

class GameNotesTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            show: false,
            errors: {},
            game: {},
            members: [],
            notes: "",
            edit: false,
            value: "",
        };
    }

    componentDidMount() {
        this.getUserNotes();

        var members = _.cloneDeepWith(this.props.game.members, this.customizer);

        this.setState({ isLoading: false, game: this.props.game, members: members });
    }

    customizer = (value) => {
        var payload = [];

        value.forEach(e => {
            if (!(e.userID === this.props.auth.user.id)) {
                payload.push({
                    userID: e.userID,
                    username: e.username,
                    relationship: "Neutral"
                });
            }
        });

        return payload;
    }

    // componentDidUpdate(prevProps) {
    //     if (this.props !== prevProps){
    //         console.log("Logging this.props: ", this.props);
    //     }
    // }

    getUserNotes = () => {
        axios.get(`/api/notes/getUserNotes/${this.props.auth.user.id}/${this.props.groupID}/${this.props.game._id}`).then((response) => {
            const data = response.data;

            var relationships = this.state.members.map(member1 => {
                let member2 = data.playerRelationships.find(m2 => m2.userID === member1.userID);
                return member2 ? { ...member1, ...member2 } : member1;
            });

            this.setState({ members: relationships });

            if (data != null) {
                this.setState({ notes: data.notes });
            }
        }).catch((err) => { console.log("Error retrieving user notes data: ", err) });
    }

    //edit current game
    onSubmitEdit = e => {
        e.preventDefault();

        this.setState({ edit: true });
    };

    onSubmitSave = e => {
        e.preventDefault();
        this.setState({ isLoading: true });

        var payload = {
            userID: this.props.auth.user.id,
            gameID: this.props.game._id,
            groupID: this.props.groupID,
            notes: this.state.notes,
        };

        axios.post(`/api/notes/postUserNotes/`, payload).then((response) => {
            this.setState({ notes: response.data.notes, edit: false });
        }).catch((err) => {
            console.log("Error posting notes: ", err)
        }).then(() => {
            this.setState({ isLoading: false });
        });
    };

    onChange = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        this.setState({ [name]: value });
    };

    onChangeRelationship = e => {
        const target = e.target;
        const value = target.value;
        const name = target.id;

        const member = this.state.members.find(e => e.username === name);
        var payload = {
            userID: member.userID,
            username: member.username,
            relationship: value
        }

        axios.post(`/api/notes/postPlayerRelationship/${this.props.auth.user.id}/${this.props.groupID}/${this.props.game._id}`, payload).then(res => {
            const data = res.data;
            var relationships = this.state.members.map(member1 => {
                let member2 = data.playerRelationships.find(m2 => m2.userID === member1.userID);
                return member2 ? { ...member1, ...member2 } : member1;
            });

            this.setState({ members: relationships });
        }).catch(err => {
            console.log("Unexpected error trying to change member relationship: ", err);
        });
    }

    displayNotes = () => {
        if (this.state.edit) {
            return (
                <Form>
                    <Form.Group className="mb-3" controlId="notes">
                        <Form.Control as="textarea" rows={8} value={this.state.notes} onChange={this.onChange} />
                    </Form.Group>
                </Form>
            );
        }
        else {
            var text = this.state.notes;

            return (
                <div>
                    {text.split("\n").map((i, key) => {
                        return <div key={key}>{i}</div>;
                    })}
                </div>
            );
        }
    }

    displaySubmit = () => {
        if (this.state.edit) {
            return (
                <Button type="submit" onClick={this.onSubmitSave}>
                    Save
                </Button>
            );
        }
        else {
            return (
                <Button type="submit" onClick={this.onSubmitEdit}>
                    Edit
                </Button>
            );
        }
    }

    displayPlayerRelationships = (members) => {
        //var players = members.filter(m => m.)

        if (!members) {
            return null;
        }
        else if (!members.length) {
            return null;
        }
        else {
            return members.map((member, index) => (
                <div key={index} style={{ marginTop: '5px' }}>
                    <Row>
                        <Col xs={2} className="d-flex align-items-center">
                            {member.username}
                        </Col>
                        <Col xs={3}>
                            <Form.Group style={{ marginTop: '5px' }}>
                                <Form.Control as="select" id={member.username} aria-label="Player Relationship"
                                    value={member.relationship} onChange={this.onChangeRelationship} >
                                    <option>Friends Forever</option>
                                    <option>Allies</option>
                                    <option>Friendly</option>
                                    <option>Neutral</option>
                                    <option>Suspicious</option>
                                    <option>Seeking Revenge</option>
                                    <option>Mortal Enemies</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
            ))
        }
    }

    render() {
        if (this.state.isLoading) {
            return null;
        }
        else {
            return (
                <div className="py-3 px-3">
                        <Card>
                            <Card.Header>
                                Notes
                            </Card.Header>
                            <Card.Body>
                                {this.displayNotes()}
                            </Card.Body>
                            <Card.Footer>
                                {this.displaySubmit()}
                            </Card.Footer>
                        </Card>

                        <Card style={{ marginTop: '20px' }}>
                            <Card.Header>
                                Player Relationships
                            </Card.Header>
                            <Card.Body>
                                {this.displayPlayerRelationships(this.state.members)}
                            </Card.Body>
                        </Card>
                </div>
            );
        }
    }
}

GameNotesTab.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(GameNotesTab);