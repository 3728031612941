import ActionTypes from '../actions/types';

export const showAlert = ({ alertProps, alertType }) => dispatch => {
    dispatch({
        type:  ActionTypes.SHOW_ALERT,
        alertProps, 
        alertType
    });
}

export const hideAlert = () => dispatch => {
    dispatch({
        type: ActionTypes.HIDE_ALERT
    });
}