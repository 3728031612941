import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from 'axios';

import Navbar from 'react-bootstrap/Navbar';
// import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
// import _ from "lodash";
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            games: [],
            gameName: "",
            searchList: []
        };
    }

    componentDidMount() {
        if (!this.props.auth.isAuthenticated) {
            this.props.history.push("/login");
        }
        else {
            this.props.history.push("/search");
        }

        this.getSupportedGames();
    }

    getSupportedGames = () => {
        axios
            .get("api/games/getGames")
            .then((response) => {
                const data = response.data;
                this.setState({ games: data, gameName: data[0].gameName });
            })
            .catch((err) => { console.log("Error retrieving games data: ", err) });
    }


    // //creates a new group
    // onSubmit = e => {
    //     e.preventDefault();

    // };

    // //handlers
    // handleShow = () => {
    //     this.setState({ show: true });
    // }

    // handleClose = () => {
    //     //reset from variables
    //     this.setState({ show: false });
    // }

    onChange = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        this.setState({ [name]: value });
    };

    onClickGameSearch = e => {
        axios
            .get(`api/groups/searchGroups?gameName=${this.state.gameName}`)
            .then(res => {
                const data = res.data;
                this.setState({ searchList: data });
            })
            .catch(err => {
                console.log("Unexpected error when searching for groups: ", err);
            })
    }

    //display game options in selectbox
    gameOptions = (games) => {
        if (!games) {
            return null;
        }
        else if (!games.length) {
            return null;
        }
        else {
            return games.map((game, index) => (
                <option key={index} value={game.gameName}>{game.gameName}</option>
            ));
        }
    }

//populates tab main game data table
    displaySearchData = (groups) => {
        if (!groups) {
            return null;
        }
        else if (!groups.length) {
            return null
        }
        else {
            return groups.map((group, index) => (
                <tr key={index}>
                    <td><strong><Button variant="link" href={`/${group.linkURL}/welcome?groupID=${group._id}`}>{group.groupName}</Button></strong></td>
                    <td>{ group.gameName }</td>
                    <td>{ group.members.length }</td>
                </tr>
            ));
        }
    }

    
    displayGameData = (games) => {
        
    }

    render() {
        const { searchList } = this.state;

        return (
            <div className="h-100">
                <Navbar expand="lg" variant="light" bg="light">
                    <Container>
                        <Navbar.Brand>Search</Navbar.Brand>
                    </Container>
                </Navbar>
                <Container>
                    <Card style={{ marginTop: "10px" }}>
                        <Card.Header>
                            <Navbar expand="lg" variant="light" bg="light">
                                <Container>
                                    <Navbar.Brand>Group search</Navbar.Brand>
                                    <Form className="d-flex">
                                        <Form.Group style={{ margin: '5px', minWidth: '200px' }}>
                                            <Form.Control
                                                as="select"
                                                aria-label="Game Select"
                                                id="gameName"
                                                value={this.state.gameName}
                                                onChange={this.onChange}
                                            >
                                                {this.gameOptions(this.state.games)}
                                            </Form.Control>
                                        </Form.Group>
                                        <Button style={{ margin: '5px', right: '0px' }} onClick={() => { this.onClickGameSearch() }}>
                                            Search
                                        </Button>
                                    </Form>
                                </Container>
                            </Navbar>
                        </Card.Header>
                        <Card.Body>
                            <Table responsive striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Group Name</th>
                                        <th>Game</th>
                                        <th>Members</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { this.displaySearchData(searchList) }
                                </tbody>

                            </Table>
                        </Card.Body>

                    </Card>
                </Container>

            </div>
        );
    }
}

Search.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(Search);