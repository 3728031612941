import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import restService from '../../../../utils/restService';
import _ from "lodash";

import civDraft from '../CivComponents/CivDraft';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
// import Table from 'react-bootstrap/Table';
// import SMMToast from "../../../components/SMMToast";
import renderFuncs from "../../../global/Render";
// import Table from "react-bootstrap/esm/Table";

//requires a groupID 
class DraftUI extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            groupID: props.groupID,
            group: {},
            members: props.members,
            gameNamePlaceholder: props.gameName,
            modalEvent: props.modalEvent,
            //render control
            isLoading: true,
            variant: "light",
            showToast: false,
            title: "",
            message: "",
            //newgame
            newGame: {
                gameName: "",
                host: "",
                anonymousMode: false,
                teamGame: false,
                randomTeams: false,
                teamCount: 2,
                draftMode: false,
                numCiv: "3",
                randomMap: false,
                bansRemovePicks: false,
                removeDupNations: false,
                removeDupLeaders: false,
                freeBans: "1"
            }
        };
    }

    componentDidMount() {
        this.getFullGroup(this.props.groupID);
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {

            let members = this.props.members?.map((member) => ({
                ...member,
                team: "1"
            }));

            this.setState({ members: members });
        }
    }

    //-----API FUNCTIONS-----//
    //#region API
    getFullGroup = (groupID) => {
        restService.groups.getFullGroup(groupID).then((response) => {
            const data = response.data;
            this.setState({ group: data });
        }).catch((err) => {
            console.log("Error retrieving games data: ", err);
        });
    }
    //#endregion

    //-----EVENT FUNCTIONS-----//
    //#region EVENTS
    onSubmitDraftEvent = async e => {
        e.preventDefault();
        // const form = e.currentTarget;
        // if(form.checkValidity === false){
        //     e.preventDefault();
        // }


        try {
            this.setState({ isDisabled: true });
            var { groupID, modalEvent, newGame, group, members } = this.state;

            if (modalEvent?.isDrafted) {
                console.log("Was drafted");
                restService.civilization.postDeleteGame(modalEvent.draftGameID).catch(err => {
                    console.log("Unexpected error while removing previously drafted game: ", err);
                    this.setState({ title: "Error!", message: "Unexpected error while attempting to draft", showToast: true });
                });
            }

            var civGroup = {
                _id: groupID,
                gameName: "Civilization 6",
                members: members,
                settings: group.settings
            };

            var draftResult = await civDraft(civGroup, newGame);

            if (draftResult.error) {
                throw draftResult.error;
            }

            if (!newGame.host) {
                newGame.host = draftResult.result[0].username;
            }

            var gamePayload = {
                groupID: groupID,
                members: draftResult.result,
                host: newGame.host,
                gameName: newGame.gameName,
                anonymousMode: newGame.anonymousMode,
                draftMode: newGame.draftMode,
                numCiv: newGame.numCiv,
                map: draftResult.map?.name ?? "",
                gameType: newGame.teamGame ? "team" : "ffa",
                bannedLeaders: draftResult.bannedLeaders?.size !== 0 ? [...draftResult.bannedLeaders] : "",
                settings: draftResult?.settings
            };

            restService.civilization.postCreateGame(gamePayload).then(res => {
                if (modalEvent?._id != null) {
                    var eventPayload = {
                        eventID: modalEvent._id,
                        groupID: modalEvent.groupID,
                        userID: modalEvent.userID,
                        eventName: modalEvent.eventName,
                        startDate: modalEvent.startDate,
                        endDate: modalEvent.endDate,
                        isDrafted: true,
                        maxNumberOfMembers: modalEvent.maxNumberOfMembers,
                        draftGameID: res.data._id
                    };

                    restService.events.civDraft(modalEvent._id, eventPayload);
                }
            }).catch(err => {
                console.log("Unexpected error trying to create a new game: ", err)
                this.setState({ title: "Error!", message: "Unexpected error while attempting to draft", showToast: true, isDisabled: false });
            }).then(() => {
                this.props.handleCloseModal();
                this.props.reload();
                this.setState({ isDisabled: false });
            });
        }
        catch (err) {
            console.log("Unexpected error trying to draft a new game: ", err);
            this.setState({ title: "Error!", message: "Unexpected error while attempting to draft: " + err?.error, showToast: true, isDisabled: false });
        }
    }

    onChange = e => {

        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        var { newGame } = this.state;
        newGame[name] = value;

        this.setState({ newGame: newGame });
    };

    onChangeTeam = (e, member) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        member[name] = value;

        var { members } = this.state;
        var index = _.findIndex(members, { "userID": member.userID });
        members[index] = member;

        this.setState({ members: members });
    };
    //#endregion

    //-----HANDLER FUNCTIONS-----//
    //#region HANDLERS

    //#endregion

    //-----DISPLAY FUNCTIONS-----//
    //#region DISPLAY

    showDraftOptions = (newGame) => {
        if (newGame.draftMode) {
            return (
                <div>
                    {/* Draft Options Select Box */}
                    <Form.Group className="py-2">
                        <Form.Label>Number of Civilizations</Form.Label>
                        <Form.Select id="numCiv" aria-label="Number Of Civilizations"
                            value={newGame.numCiv} onChange={this.onChange} >
                            {renderFuncs.numberedOptions(5)}
                        </Form.Select>
                    </Form.Group>



                    <Form.Check type="checkbox" id="randomMap" label="Random Map" onChange={this.onChange}
                        checked={newGame.randomMap} className="py-2" />

                    {/* <Form.Check type="checkbox" id="includeBans" label="Random Map" onChange={this.onChangeNewGame}
                            checked={newGame.randomMap} className="py-2" /> */}

                    <Form.Check type="checkbox" id="bansRemovePicks" label="Bans Remove Picks" onChange={this.onChange}
                        checked={newGame.bansRemovePicks} className="py-2" />

                    {newGame.bansRemovePicks ?
                        <div>
                            <Form.Label>Number of Free Bans</Form.Label>
                            <Form.Select id="freeBans" aria-label="Number of Free Bans"
                                value={newGame.freeBans} onChange={this.onChange} >
                                {renderFuncs.numberedOptions(5)}
                            </Form.Select>
                        </div>
                        : null}

                    <Form.Check type="checkbox" id="removeDupNations" label="Remove Duplicate Nations" onChange={this.onChange}
                        checked={newGame.removeDupNations} className="py-2" />

                    <Form.Check type="checkbox" id="removeDupLeaders" label="Remove Duplicate Leaders" onChange={this.onChange}
                        checked={newGame.removeDupLeaders} className="py-2" />

                </div>
            );
        }
        else {
            return null;
        }
    }

    showTeamOptions = (newGame, members) => {

        if (newGame.teamGame && members && newGame.randomTeams) {
            return (
                <div>
                    <Form.Label>Number of Teams</Form.Label>
                    <Form.Select id="teamCount" aria-label="Team" value={newGame.teamCount} onChange={this.onChange} >
                        {renderFuncs.numberedOptions(members.length, 2)}
                    </Form.Select>
                </div>

            );
        }
        else if (newGame.teamGame && members ) {
            return (
                <Container>
                    <Row>
                        <Col><strong>Player</strong></Col>
                        <Col><strong>Team</strong></Col>
                    </Row>

                    {members?.map((member, index) => (
                        <Row key={index} className="py-1">
                            <Col>{member.username}</Col>
                            <Col>
                                <Form.Select id="team" aria-label="Team"
                                    value={member.team} onChange={e => this.onChangeTeam(e, member)} >
                                    {renderFuncs.numberedOptions(12)}
                                </Form.Select>
                            </Col>
                        </Row>
                    ))}


                </Container>
            );

        }
        else {
            return null;
        }
    }

    displayDraftButton = () => {
        var { members, modalEvent, isDisabled, newGame } = this.state;
        if (members?.length > 0) {
            if (modalEvent?.isDrafted) {
                return (
                    <Button type="submit" disabled={isDisabled || newGame.gameName === ""} onClick={this.onSubmitDraftEvent}>Redraft</Button>
                );
            }
            else if (newGame.draftMode) {
                return (
                    <Button type="submit" disabled={isDisabled || newGame.gameName === ""} onClick={this.onSubmitDraftEvent}>Draft</Button>
                );
            }
            else {
                return (
                    <Button type="submit" disabled={isDisabled || newGame.gameName === ""} onClick={this.onSubmitDraftEvent}>Add Game</Button>
                );
            }
        }
        else {
            return null;
        }
    }

    memberOptions = (members) => {
        if (!members?.length) {
            return null;
        }
        else {
            return members.map((member, index) => (
                <option key={index} value={member.username}>{member.username}</option>
            ));
        }
    }
    //#endregion

    render() {
        const { newGame, gameNamePlaceholder, members } = this.state;

        return (
            <Accordion defaultActiveKey="0" className="py-2">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Game Options</Accordion.Header>
                    <Accordion.Body>
                        <Form noValidate>
                            <Form.Group>
                                <Form.Label>Game Name</Form.Label>
                                <Form.Control required type="text" id="gameName" value={newGame.gameName}
                                    onChange={this.onChange} placeholder={gameNamePlaceholder} />
                            </Form.Group>

                            <Form.Label column="lg">Teams</Form.Label>
                            <Form.Group className="d-flex">
                                <Form.Check type="checkbox" id="teamGame" label="Team Game" onChange={this.onChange}
                                    checked={newGame.teamGame} className="py-2" />

                                {/* Random teams */}
                                {newGame.teamGame && members.length > 0 ?
                                    <Form.Check type="checkbox" id="randomTeams" label="Random Teams" onChange={this.onChange}
                                        checked={newGame.randomTeams} className="py-2 mx-2" /> : null

                                }

                            </Form.Group>

                            {this.showTeamOptions(newGame, members)}

                            <Form.Label column="lg">Draft</Form.Label>
                            <Form.Group>
                                <Form.Check type="checkbox" id="draftMode" label="Random Draft" onChange={this.onChange}
                                    checked={newGame.draftMode} className="py-2" />
                            </Form.Group>

                            {this.showDraftOptions(newGame)}

                        </Form>

                        {/* Host Select Box */}
                        <Form.Group className="py-2">
                            <Form.Label>Select Host</Form.Label>
                            <Form.Select id="host" aria-label="Host Select" value={newGame.host} onChange={this.onChange} >
                                {this.memberOptions(members)}
                            </Form.Select>
                        </Form.Group>

                        {this.displayDraftButton()}
                    </Accordion.Body>

                </Accordion.Item>
            </Accordion>
        );
    }
}

DraftUI.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(DraftUI);