import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import restService from '../../../../../utils/restService';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import Card from 'react-bootstrap/Card';
// import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import Toast from 'react-bootstrap/Toast';

class WelcomePageSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            groupID: "",
            group: {},
            cards: [],
            currentCard: {
                title: "",
                body: "",
                footer: ""
            },
            mode: "add",
            //render control
            isLoading: true,
            isDeleting: false,
            variant: "light",
            show: false,
            showEditOrder: false,
            showToast: false,
            showConfirm: false,
            title: "",
            message: ""
        };
    }

    componentDidMount() {
        this.setState({ groupID: this.props.groupID });
        this.getGroupCards(this.props.groupID);

    }

    getGroupCards = (groupID) => {
        restService.components.getGroupCards(groupID).then((response) => {
            const data = response.data?.sort(function (a, b) { return a.cardOrder - b.cardOrder; });
            this.setState({ cards: data, isLoading: false });
        }).catch((err) => {
            console.log("Error retrieving group cards data: ", err);
        });
    }

    handleShow = (mode, card) => {
        if (mode === "add") {
            card = {
                title: "",
                body: "",
                footer: ""
            }
        }

        this.setState({ show: true, currentCard: card, mode: mode });
    }

    handleShowConfirm = () => {
        this.setState({ showConfirm: true });
    }

    handleClose = () => {
        //reset from variables
        this.setState({
            show: false,
            showEditOrder: false,
            showConfirm: false,
            isDeleting: false,
            currentCard: { title: "", body: "", footer: "" }
        });
    }
    handleCloseToast = () => {
        this.setState({ showToast: false });
    }

    handleShowEditOrder = () => {
        this.setState({ showEditOrder: true });
    }

    handleDragEnd = (e) => {
        var { cards } = this.state;

        if (!e.destination) return;
        let tempData = Array.from(cards);
        let [source_data] = tempData.splice(e.source.index, 1);
        tempData.splice(e.destination.index, 0, source_data);

        tempData.forEach((card, index) => {
            card.cardOrder = index;
        });

        this.setState({ cards: tempData });
    };

    onSubmitCard = e => {
        //e.preventDefault();
        var { currentCard, mode } = this.state;
        var payload = {}

        if (mode === "add") {
            payload = {
                groupID: this.state.groupID,
                cardOrder: this.state.cards.length,
                title: currentCard.title,
                body: currentCard.body,
                footer: currentCard.footer
            }
        }
        else {
            payload = currentCard;
        }

        restService.components.postGroupCard(payload).then(res => {
            this.handleClose();
        }).catch(err => {
            console.warn("Unexpected error while trying to save card: ", err);
        }).then(() => {
            this.getGroupCards(this.state.groupID);
        });
    }

    onSubmitOrder = e => {
        var { groupID, cards } = this.state;

        restService.components.postGroupCardOrder(groupID, cards).then(res => {
            this.setState({ title: "Success!", message: "Successfully card order", showToast: true });
            this.handleClose();
        }).catch(err => {
            console.warn("Unexpected error while saving card order: ", err);
            this.setState({ title: "Error!", message: "Unexpected error while saving card order. Please submit issue in contact us.", showToast: true });
        });
    }

    onSubmitDelete = e => {
        var { currentCard } = this.state;
        this.setState({ isDeleting: true })

        restService.components.postRemoveGroupCard(currentCard).then(res => {
            this.setState({ title: "Success!", message: "Successfully removed card", showToast: true });
            this.handleClose();
        }).catch(err => {
            console.warn("Unexpected error deleting card: ", err);
            this.setState({ title: "Error!", message: "Unexpected error deleting card. Please submit issue in contact us.", showToast: true });
        }).then(() => {
            this.getGroupCards(this.state.groupID);
        });
    }

    onChange = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        var { currentCard } = this.state;
        currentCard[name] = value;

        this.setState({ currentCard: currentCard });
    }

    displayCardDetails = () => {
        var { currentCard } = this.state;
        return (
            <Modal.Body>
                <Form>
                    <Form.Group className="my-1" controlId="title">
                        <Form.Label>Title</Form.Label>
                        <Form.Control as="input" value={currentCard.title} onChange={this.onChange} />
                    </Form.Group>
                    <Form.Group className="my-1" controlId="body">
                    <Form.Label>Body</Form.Label>
                        <Form.Control as="textarea" rows={20} value={currentCard.body} onChange={this.onChange} />
                    </Form.Group>
                </Form>
            </Modal.Body>
        );

    }

    displayBody = (cards, isLoading) => {
        if (isLoading) {
            return (
                <div>
                    <Spinner animation="border" role="status" variant="secondary" >
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            );
        }
        else {
            return (
                <div>
                    <Table responsive striped bordered hover variant={this.state.variant} >
                        <thead>
                            <tr>
                                <th>Title</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cards.map((card, index) => (
                                <tr key={index}>
                                    <th><Button variant="link" onClick={() => { this.handleShow("edit", card) }}>{card.title}</Button></th>
                                </tr>))}
                        </tbody>
                    </Table>
                </div>
            )
        }
    }

    displayEditOrderBody = (cards) => {
        return <div>
            <DragDropContext onDragEnd={this.handleDragEnd}>
                <Table responsive striped bordered hover variant={this.state.variant} >
                    <thead>
                        <tr>
                            <th></th>
                            <th>Title</th>
                        </tr>
                    </thead>
                    <Droppable droppableId="droppable-1">
                        {(provider) => (
                            <tbody className="text-capitalize" ref={provider.innerRef} {...provider.droppableProps} >
                                {cards.map((card, index) => (
                                    <Draggable key={card.title} draggableId={card._id} index={index}>
                                        {(provider) => (
                                            <tr {...provider.draggableProps} ref={provider.innerRef}>
                                                <td {...provider.dragHandleProps}> = </td>
                                                <td><Button variant="link" onClick={() => { this.handleShow("edit", card) }}>{card.title}</Button></td>
                                            </tr>)}
                                    </Draggable>
                                ))}
                                {provider.placeholder}
                            </tbody>
                        )}
                    </Droppable>
                </Table>
            </DragDropContext>
        </div>
    }

    render() {
        const { cards, isLoading, currentCard, mode } = this.state;

        return (
            <div className="py-3 px-3">
                <Card bg={this.state.variant}>
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        Cards
                        <Form>
                            <Button className="mx-2" onClick={() => this.handleShow("add")}>Add</Button>
                            <Button className="mx-2" onClick={() => this.handleShowEditOrder()}>Edit Order</Button>
                        </Form>
                    </Card.Header>
                    <Card.Body>
                        {this.displayBody(cards, isLoading)}
                    </Card.Body>
                </Card>

                {/* Show members modal */}
                <Modal size="xl" show={this.state.show} onHide={this.handleClose} centered animation={true} backdrop="static" keyboard={false} >
                    <Modal.Header >
                        {mode === "edit" ? <Modal.Title>Edit Card - {currentCard.title}</Modal.Title> : <Modal.Title>Add Card</Modal.Title>}
                        <div >
                            <Button variant="link" onClick={() => { this.handleShowConfirm() }}>Delete</Button>
                            <Button variant="close" onClick={() => { this.handleClose() }} />
                        </div>

                    </Modal.Header>

                    {this.displayCardDetails()}
                    <Modal.Footer>
                        <Button onClick={() => this.onSubmitCard()}>Save</Button>
                    </Modal.Footer>
                </Modal>

                {/* Show members modal */}
                <Modal size="md" show={this.state.showEditOrder} onHide={this.handleClose} centered animation={true} backdrop="static" keyboard={false} >
                    <Modal.Header className="d-flex justify-content-between align-items-center" closeButton>Edit Card Order</Modal.Header>

                    {this.displayEditOrderBody(cards)}
                    <Modal.Footer>
                        <Button onClick={() => this.onSubmitOrder()}>Save</Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showConfirm} onHide={this.handleClose} centered animation={true} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>Delete Card</Modal.Header>

                    <Modal.Body>Are you sure you wish to permanently delete this card?</Modal.Body>

                    <Modal.Footer>
                        <Button onClick={this.onSubmitDelete} disabled={this.state.isDeleting}>Submit</Button>
                        <Button onClick={this.handleClose}>Cancel</Button>
                    </Modal.Footer>
                </Modal>

                <Toast onClose={this.handleCloseToast} show={this.state.showToast} delay={3000} autohide animation={true} style={{ position: "fixed", bottom: "20px", right: "20px", zIndex: 9999, float: "left" }}>
                    <Toast.Header className="closeButton">
                        <strong className="me-auto">{this.state.title}</strong>
                    </Toast.Header>
                    <Toast.Body>{this.state.message}</Toast.Body>
                </Toast>
            </div>
        );
    }

}

WelcomePageSettings.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(WelcomePageSettings);