import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import restService from '../../../../utils/restService';

// import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
// import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
// import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import Toast from 'react-bootstrap/Toast';

import mapTypes from '../MapTypes';
import _ from "lodash";

//requires a groupID 

class MapTypes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            groupID: "",
            group: {},
            civPreferences: [],
            index: 0,
            //render control
            isLoading: true,
            variant: "light",
            maps: [],
            showToast: false,
            title: "",
            message: "",
        };
    }

    componentDidMount() {
        this.setState({ groupID: this.props.groupID, });
        this.getGroupInfo(this.props.groupID);
    }

    handleCloseToast = () => { this.setState({ showToast: false }); }

    //-----API FUNCTIONS-----//
    //#region API
    getGroupInfo = (groupID) => {
        restService.groups.getGroup(groupID).then((response) => {
            const data = response.data;

            // Replaces the default case of the switch statement, which would return if the game name wasn't in the civ object.
            if (!mapTypes[data.gameName]) return;

            this.setState({ group: data, mapTypes: mapTypes[data.gameName] });
        }).catch((err) => {
            console.log("Error retrieving games data: ", err);
        }).then(() => {
            this.getCivPreferences(groupID);
        });
    }

    getCivPreferences = (groupID) => {
        restService.civilization.getCivPreferences(groupID, this.props.auth.user.id).then((response) => {
            const data = response.data;
            const maps = _.cloneDeep(this.state.mapTypes);

            data?.maps.forEach(map => {
                maps.filter(m => m.name === map.name).map(obj => { return obj.isChecked = true });
            });

            this.setState({ civPreferences: data, isLoading: false, maps: maps });
        }).catch((err) => {
            console.log("Error retrieving games data: ", err);
        });
    }
    //#endregion

    //-----EVENT FUNCTIONS-----//
    //#region EVENTS
    onSubmit = e => {
        e.preventDefault();

    };

    onChange = e => {
        //this.setState({ isLoading :true });
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        this.setState(this.state.maps.filter(m => m.name === name).map(obj => { return obj.isChecked = value }));

        var map = this.state.maps.find(m => m.name === name);

        var payload = {
            map: map,
            operation: value
        }

        restService.civilization.postCivMapPreference(this.props.groupID, this.props.auth.user.id, payload).then(res => {
            if (res.status === 200) {
                this.setState({ title: "Success!", message: "Successfully saved map choice", showToast: true });
            }
        }).catch(err => {
            console.log("Unexpected error while setting map type preference: ", err);
        });
    };
    //#endregion

    //-----HANDLER FUNCTIONS-----//
    //#region HANDLERS
    handleShow = () => {
        this.setState({ show: true });
    }

    handleClose = () => {
        //reset from variables
        this.setState({ show: false });
    }
    //#endregion

    //-----DISPLAY FUNCTIONS-----//
    //#region DISPLAY
    displayBody = (maps) => {
        if (this.state.isLoading) {
            return (
                <Spinner animation="border" role="status" variant="secondary" >
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            );
        }
        else {


            return (<Row xs={1} md={5} className="g-3">
                {maps.map((map, index) => (
                    <Col key={index} >
                        <Form.Check
                            type="checkbox"
                            id={map.name}
                            label={map.name}
                            onChange={this.onChange}
                            checked={map.isChecked}
                        />
                    </Col>
                ))}
            </Row>
            );
        }
    }
    //#endregion

    render() {
        const { maps } = this.state;
        return (
            <div className="py-3 px-3" >
                <Card bg={this.state.variant}>
                    <Card.Header>Map Types</Card.Header>
                    <Card.Body>
                        {this.displayBody(maps)}
                    </Card.Body>
                </Card>

                <Toast onClose={this.handleCloseToast} show={this.state.showToast} delay={3000} autohide animation={true} style={{ position: "fixed", bottom: "20px", right: "20px", zIndex: 9999, float: "left" }}>
                    <Toast.Header className="closeButton">
                        <strong className="me-auto">{this.state.title}</strong>
                    </Toast.Header>
                    <Toast.Body>{this.state.message}</Toast.Body>
                </Toast>
            </div>
        );
    }
}

MapTypes.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(MapTypes);