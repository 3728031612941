import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import restService from '../../../../utils/restService';
// import _ from 'lodash';

// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
// import Form from 'react-bootstrap/Form';
// import Col from 'react-bootstrap/Col';
// import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';

const Weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

class PlayerAvailability extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            groupID: "",
            group: {},
            playerAvailability: [],
            //render control
            isLoading: true,
            variant: "light",
        };
    }

    componentDidMount() {
        this.setState({ groupID: this.props.groupID });
        this.getFullGroup(this.props.groupID);
    }

    //-----API FUNCTIONS-----//
    //#region API
    getFullGroup = (groupID) => {
        restService.groups.getFullGroup(groupID).then((response) => {
            const data = response.data;
            this.setState({ group: data });
        }).catch((err) => {
            console.log("Error retrieving games data: ", err);
        }).then(() => {
            this.geMemberInfo();
        });
    }

    geMemberInfo = () => {
        const { groupID } = this.state;
        restService.civilization.Members.getGroupAvailability(groupID).then((res) => {
            const data = res.data;

            this.setState({ playerAvailability: data });
        }).catch((err) => {
            console.log("Unexpected error retrieving games data: ", err)
        }).then(() => {
            this.setState({ isLoading: false });
        });
    }

    //#endregion

    //-----EVENT FUNCTIONS-----//
    //#region EVENTS


    //#endregion

    //-----HANDLER FUNCTIONS-----//
    //#region HANDLERS

    //#endregion

    //-----DISPLAY FUNCTIONS-----//
    //#region DISPLAY
    //populates tab main game data table




    displayBody = () => {
        const { playerAvailability, group } = this.state;

        if (!this.state.isLoading) {
            return (
                <div>
                    <Table responsive striped bordered hover>
                        <thead>
                            <tr>
                            <th></th>
                                {Weekdays.map((day, index) => (
                                    <th key={index}>{day}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Morning</td>
                                {Weekdays.map((day, index) => (
                                    <td key={index}>
                                        {playerAvailability.filter(pa => pa.day === day && pa.time === "Morning").map((m, i) => {
                                            return <div key={i}>{group.members?.find(u => u.userID === m.userID)?.username}</div>
                                        })}
                                    </td>
                                ))}
                            </tr>
                            <tr>
                            <td>Afternoon</td>
                                {Weekdays.map((day, index) => (
                                    <td key={index}>
                                        {playerAvailability.filter(pa => pa.day === day && pa.time === "Afternoon").map((m, i) => {
                                            return <div key={i}>{group.members?.find(u => u.userID === m.userID)?.username}</div>
                                        })}
                                    </td>
                                ))}
                            </tr>
                            <tr>
                            <td>Evening</td>
                                {Weekdays.map((day, index) => (
                                    <td key={index}>
                                        {playerAvailability.filter(pa => pa.day === day && pa.time === "Evening").map((m, i) => {
                                            return <div key={i}>{group.members?.find(u => u.userID === m.userID)?.username}</div>
                                        })}
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </Table>
                </div>
            );
        }
        else {
            return (
                <Spinner animation="border" role="status" variant="secondary" >
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            );
        }
    }





    //#endregion

    render() {
        const { variant } = this.state; // errors, group, games, groupID

        return (
            <div className="py-3 px-3">
                <Card bg={variant}>
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        Player Availability
                    </Card.Header>
                    <Card.Body>
                        {this.displayBody()}
                    </Card.Body>
                </Card>

            </div>
        );
    }
}

PlayerAvailability.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(PlayerAvailability);

