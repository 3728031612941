import ActionTypes from '../actions/types';

const initialState = {
    alertType: null, 
    alertProps: {
        open: false,
    }
}

export default function alertState(state = initialState, action) {
    switch (action.type){
        case ActionTypes.SHOW_ALERT:
            return {
                alertProps: action.alertProps, 
                alertType: action.alertType,
                type: action.type
            }
        case ActionTypes.HIDE_ALERT:
            return initialState;
        default:
            return state;
    }
}