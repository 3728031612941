import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import restService from '../../../../utils/restService';
import renderFuncs from "../../../global/Render";

// import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
// import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
// import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import SMMToast from "../../../components/SMMToast";

import defaultGameSettings from '../GameSettings';
// import _ from "lodash";

//requires a groupID 

class GameSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            groupID: props.groupID,
            group: {},
            civPreferences: [],
            //render control
            isLoading: true,
            variant: "light",
            showToast: false,
            title: "",
            message: "",
            gameSettings: null,
            gameModes: null,
            victoryTypes: null,
            tradingAndDiplomacy: null
        };
    }

    componentDidMount() {
        this.getGroupInfo();
    }

    handleCloseToast = () => { this.setState({ showToast: false }); }

    //-----API FUNCTIONS-----//
    //#region API
    getGroupInfo = () => {
        var { groupID } = this.state;

        restService.groups.getGroup(groupID).then((response) => {
            const data = response.data;
            
            // Replaces the default case of the switch statement, which would return if the game name wasn't in the civ object.
            if (!defaultGameSettings[data.gameName]) throw new Error("Cannot get default settings.");

            var gameSettings = defaultGameSettings[data.gameName]?.gameSettings;
            var gameModes = defaultGameSettings[data.gameName]?.gameModes;
            var victoryTypes = defaultGameSettings[data.gameName]?.victoryTypes;
            var tradingAndDiplomacy = defaultGameSettings[data.gameName]?.tradingAndDiplomacy;

            data?.settings?.gameSettings?.forEach(setting => {
                var item = gameSettings.find(s => s.title === setting.title);
                item.isEnabled = setting.isEnabled;
                item.lockedValue = setting.lockedValue;
            });

            data?.settings?.gameModes?.forEach(mode => {
                var item = gameModes.find(s => s.title === mode.title);
                item.isEnabled = mode.isEnabled;
                item.lockedValue = mode.lockedValue;
            });

            data?.settings?.victoryTypes?.forEach(victory => {
                var item = victoryTypes.find(s => s.title === victory.title);
                item.isEnabled = victory.isEnabled;
                item.lockedValue = victory.lockedValue;
            });
            //tradingAndDiplomacy
            data?.settings?.tradingAndDiplomacy?.forEach(tnd => {
                var item = tradingAndDiplomacy.find(s => s.title === tnd.title);
                item.isEnabled = tnd.isEnabled;
                item.lockedValue = tnd.lockedValue;
            });

            this.setState({ group: data, gameSettings: gameSettings, gameModes: gameModes, victoryTypes: victoryTypes, tradingAndDiplomacy: tradingAndDiplomacy });
        }).catch((err) => {
            console.log("Error retrieving games data: ", err);
        }).then(() => {
            this.getCivPreferences();
        });
    }

    getCivPreferences = () => {
        var { groupID } = this.state;

        restService.civilization.getCivPreferences(groupID, this.props.auth.user.id).then((response) => {
            const data = response.data;
            let { gameSettings, gameModes, victoryTypes, tradingAndDiplomacy } = this.state;

            data?.gameSettings?.forEach(setting => {
                var item = gameSettings.find(s => s.title === setting.title);
                item.value = setting.value;
            });

            data?.gameModes?.forEach(mode => {
                var item = gameModes.find(s => s.title === mode.title);
                item.value = mode.value;
            });

            data?.victoryTypes?.forEach(victory => {
                var item = victoryTypes.find(s => s.title === victory.title);
                item.value = victory.value;
            });

            data?.tradingAndDiplomacy?.forEach(tnd => {
                var item = tradingAndDiplomacy.find(s => s.title === tnd.title);
                item.value = tnd.value;
            });

            this.setState({ isLoading: false, gameSettings: gameSettings, gameModes: gameModes, victoryTypes: victoryTypes, tradingAndDiplomacy: tradingAndDiplomacy });
        }).catch((err) => {
            console.log("Error retrieving games data: ", err);
        });
    }
    //#endregion

    //-----EVENT FUNCTIONS-----//
    //#region EVENTS
    onSubmit = e => {
        e.preventDefault();

    };

    onChange = (e, settingType) => {
        var settings = this.state[settingType];
     
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        var payload = {
            title: name,
            value: value,
            settingType: settingType
        }

        var item = settings.find(s => s.title === name);
        item.value = value;
        this.setState({ [this.state[settingType]]: settings });

        restService.civilization.postGameSettingPreference(this.props.groupID, this.props.auth.user.id, payload).then(res => {
            if (res.status === 200) {
                this.setState({ title: "Success!", message: "Successfully saved game setting preference.", showToast: true });
            }
        }).catch(err => {
            console.log("Unexpected error while setting map type preference: ", err);
        });
    };

    
    //#endregion

    //-----HANDLER FUNCTIONS-----//
    //#region HANDLERS
    handleShow = () => {
        this.setState({ show: true });
    }

    handleClose = () => {
        //reset from variables
        this.setState({ show: false });
    }
    //#endregion

    //-----DISPLAY FUNCTIONS-----//
    //#region DISPLAY
    displayGameSettingsBody = () => {
        var { gameSettings } = this.state;
        if (!gameSettings) { return null }

        if (this.state.isLoading) {
            return (
                <Spinner animation="border" role="status" variant="secondary" >
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            );
        }
        else {
            //return null;
            return (
                <Row xs={1} md={4} className="g-3">
                    {gameSettings.map((setting, index) => (
                        <Col key={index} >
                            <Form.Label>{setting.title}</Form.Label>
                            <Form.Select id={setting.title} onChange={ e => this.onChange(e, "gameSettings") }
                                value={setting.value != null && setting.isEnabled ? setting.value : setting.lockedValue ?? setting.defaultValue}
                                disabled={!setting.isEnabled}
                            >{renderFuncs.selectOptions(setting.options)}</Form.Select>
                        </Col>
                    ))}
                </Row>
            );
        }
    }

    displayGameModesBody = () => {
        var { gameModes } = this.state;
        if (!gameModes) { return null }

        if (this.state.isLoading) {
            return (
                <Spinner animation="border" role="status" variant="secondary" >
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            );
        }
        else {
            //return null;
            return (
                <Row sm={1} md={5} className="g-3">
                    {gameModes.map((setting, index) => (
                        <Col key={index} >
                            <Form.Check type="checkbox" id={setting.title} label={setting.title} onChange={e => this.onChange(e, "gameModes")}
                                checked={ setting.value != null && setting.isEnabled ? setting.value  : setting.lockedValue ?? setting.defaultValue }
                                disabled={!setting.isEnabled}
                            />
                        </Col>
                    ))}
                </Row>
            );
        }
    }

    displayVictoryTypesBody = () => {
        var { victoryTypes } = this.state;
        if (!victoryTypes) { return null }

        if (this.state.isLoading) {
            return (
                <Spinner animation="border" role="status" variant="secondary" >
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            );
        }
        else {
            //return null;
            return (
                <Row xs={1} md={3} className="g-3">
                    {victoryTypes.map((setting, index) => (
                        <Col key={index} >
                            <Form.Label>{setting.title}</Form.Label>
                            <Form.Select id={setting.title} onChange={e => this.onChange(e, "victoryTypes")}
                                value={ setting.value != null && setting.isEnabled ? setting.value : setting.lockedValue ?? setting.defaultValue }
                                disabled={!setting.isEnabled}
                            >{renderFuncs.selectOptions(setting.options)}</Form.Select>
                        </Col>
                    ))}
                </Row>
            );
        }
    }

    //displayTradingAndDiplomacyBody
    displayTradingAndDiplomacyBody = () => {
        var { tradingAndDiplomacy } = this.state;
        if (!tradingAndDiplomacy) { return null }

        if (this.state.isLoading) {
            return (
                <Spinner animation="border" role="status" variant="secondary" >
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            );
        }
        else {
            //return null;
            return (
                <Row sm={1} md={4} className="g-3">
                    {tradingAndDiplomacy.map((setting, index) => (
                        <Col key={index} >
                            <Form.Check type="checkbox" id={setting.title} label={setting.title} onChange={e => this.onChange(e, "tradingAndDiplomacy")}
                                checked={  setting.value != null && setting.isEnabled ? setting.value  : setting.lockedValue ?? setting.defaultValue }
                                disabled={!setting.isEnabled}
                            />
                        </Col>
                    ))}
                </Row>
            );
        }
    }
    //#endregion

    render() {
        //var { settings } = this.state.group;
        const { title, message } = this.state;
        return (
            <div className="py-3 px-3" >
                <Card bg={this.state.variant}>
                    <Card.Header>Game Settings</Card.Header>

                    <Card.Body>
                        <Card.Text>Game Settings</Card.Text>
                        {this.displayGameSettingsBody()}
                        <br></br>
                        <Card.Text>Game Modes</Card.Text>
                        {this.displayGameModesBody()}
                        <br></br>
                        <Card.Text>Victory Types</Card.Text>
                        {this.displayVictoryTypesBody()}
                        <br></br>
                        <Card.Text>Trading and Diplomacy</Card.Text>
                        {this.displayTradingAndDiplomacyBody()}
                    </Card.Body>
                </Card>

                <SMMToast show={this.state.showToast} title={title} message={message} />
            </div>
        );
    }
}

GameSettings.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(GameSettings);