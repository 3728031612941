import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import axios from 'axios';

import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// import Card from 'react-bootstrap/Card';
// import CardGroup from 'react-bootstrap/CardGroup';
import restService from "../../utils/restService";

class Comments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            gameID: "",
            groupID: "",
            comments: {},
            comment: ""
        };
    }

    componentDidMount() {
        this.setState({ gameID: this.props.gameID, groupID: this.props.groupID });
        this.getGameComments(this.props.gameID, this.props.groupID)
    }

    getGameComments = (gameID, groupID) => {
        restService.comments.getComments(gameID, groupID).then(res => {
            var data = res.data;
            this.setState({ comments: data });
        }).catch(err => {
            console.warn("Unexpected error while retrieving comments: ", err);
        })
    }

    //creates a new group
    onSubmit = e => {
        e.preventDefault();

        var payload = {
            comment: this.state.comment,
            username: this.props.auth.user.username,
            userID: this.props.auth.user.id,
            groupID: this.state.groupID,
            gameID: this.state.gameID,
            date: Date.now()
        }

        restService.comments.postComment(payload).then(res => {
            var data = res.data;
            this.setState({ comments: data, comment: "" });
        }).catch(err => {
            console.warn("Unexpected error while posting comment: ", err);
        });
    };


    onChange = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        this.setState({ [name]: value });
    };

    displayComments = (comments) => {
        const options = {
            year: "numeric",
            month: "short",
            day: "numeric",
        };

        if (!comments) { return null }

        if (comments.comments?.length > 0) {
            var result = comments?.comments?.sort(function (a, b) { return new Date(b.date) - new Date(a.date); });

            return result.map((comment, index) => (
                <Row key={index}>
                    <div>
                        <strong>{comment.username}</strong> - {new Date(comment.date).toLocaleDateString(undefined, options)}   {new Date(comment.date).toLocaleTimeString('en-US')}
                    </div>
                    <div>
                        {comment.comment}
                    </div>

                </Row>
            ));
        }
        else {
            return null;
        }

    }

    render() {
        const { errors, comments } = this.state;

        return (
            <div className="py-3">
                <Form noValidate>
                    <Form.Label>Comment</Form.Label>
                    <Row>
                        <Col>
                            <Form.Control
                                required
                                type="text"
                                id="comment"
                                value={this.state.comment}
                                onChange={this.onChange}
                                error={errors.comment}
                                isInvalid={errors.comment}
                            />
                            <Form.Control.Feedback type='invalid'>
                                {errors.comment}
                            </Form.Control.Feedback>
                        </Col>
                        <Col xs={2}>
                            <Button type="submit" onClick={this.onSubmit}>Send</Button>
                        </Col>
                    </Row>
                </Form>
                {this.displayComments(comments)}

            </div>
        );
    }
}

Comments.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(Comments);