import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { matchPath } from "react-router";

import MemberSettings from "../CivComponents/GroupSettings/MemberSettings";
import GameSettings from "../CivComponents/GroupSettings/GameSettings";
import GroupSettings from "../CivComponents/GroupSettings/GroupSettings";
import WelcomePageSettings from "../CivComponents/GroupSettings/WelcomePageSettings";

class CivGroupSettings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            groupID: ""
        };
    }

    componentDidMount() {
        //get group data
        try {
            var history = this.props.location.pathname + this.props.location.search;
            const match = matchPath(history, {
                path: "/civGroup/*?groupID=:groupID",
                strict: false
            });
            var groupID = match.params.groupID;
            this.setState({ groupID: groupID });
        }
        catch (err) {
            console.log("Unexpected error trying to setup Group Page: ", err);
            this.props.history.push("/");
        }
    }

    render() {
        const { groupID } = this.state;

        return (
            <div>
                { groupID != null && groupID !== "" ? <GroupSettings groupID={groupID}/> : null }
                { groupID != null && groupID !== "" ? <GameSettings groupID={groupID}/> : null }
                { groupID != null && groupID !== "" ? <MemberSettings groupID={groupID}/> : null }
                { groupID != null && groupID !== "" ? <WelcomePageSettings groupID={groupID}/> : null }
            </div>
        );
    }
}

CivGroupSettings.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(CivGroupSettings);

//{this.displaySettingsButton(group)}
//{!group.members.includes(this.props.auth.user.id) && !group.isPrivate ? <Button onClick={() => { this.joinPublicGroup() }}>Join</Button> : null}