import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import restService from '../../../../../utils/restService';
import renderFuncs from "../../../../global/Render";
import globalFuncs from "../../../../global/Functions";

import defaultGameSettings from '../../GameSettings';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import SMMToast from "../../../../components/SMMToast";
// import Table from 'react-bootstrap/Table';
// import Placeholder from 'react-bootstrap/Placeholder';

//import _ from "lodash";

//requires a groupID 
class GameSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            groupID: "",
            group: {},
            show: false,
            //render control
            isLoading: true,
            variant: "light",
            showToast: false,
            title: "",
            message: "",
            gameSettings: null,
            gameModes: null,
            victoryTypes: null,
            tradingAndDiplomacy: null
        };
    }

    componentDidMount() {
        this.setState({ groupID: this.props.groupID });
        this.getGroup(this.props.groupID);
    }

    //-----API FUNCTIONS-----//
    //#region API
    getGroup = (groupID) => {
        restService.groups.getGroup(groupID).then((response) => {
            const data = response.data;

            // set default settings if null
            if (data.settings == null) {
                var settings = {
                    maxNumberOfLeaderBans: 5,
                    //mapChoiceList: [],
                }
                data.settings = settings;
            }

            if (!defaultGameSettings[data.gameName]) throw new Error("Cannot get default settings.");

            var gameSettings = defaultGameSettings[data.gameName].gameSettings;
            var gameModes = defaultGameSettings[data.gameName].gameModes;
            var victoryTypes = defaultGameSettings[data.gameName].victoryTypes;
            var tradingAndDiplomacy = defaultGameSettings[data.gameName].tradingAndDiplomacy;

            data?.settings?.gameSettings?.forEach(setting => {
                var item = gameSettings.find(s => s.title === setting.title);
                item.isEnabled = setting.isEnabled;
                item.lockedValue = setting.lockedValue ?? item.defaultValue;
            });

            data?.settings?.gameModes?.forEach(mode => {
                var item = gameModes.find(s => s.title === mode.title);
                item.isEnabled = mode.isEnabled;
                item.lockedValue = mode.lockedValue ?? item.defaultValue;
            });

            data?.settings?.victoryTypes?.forEach(victory => {
                var item = victoryTypes.find(s => s.title === victory.title);
                item.isEnabled = victory.isEnabled;
                item.lockedValue = victory.lockedValue ?? item.defaultValue;
            });

            data?.settings?.tradingAndDiplomacy?.forEach(tnd => {
                var item = tradingAndDiplomacy.find(s => s.title === tnd.title);
                item.isEnabled = tnd.isEnabled;
                item.lockedValue = tnd.lockedValue ?? item.defaultValue;
            });

            this.setState({ group: data, isLoading: false, gameSettings: gameSettings, gameModes: gameModes, victoryTypes: victoryTypes, tradingAndDiplomacy: tradingAndDiplomacy });
        }).catch((err) => {
            console.log("Error retrieving games data: ", err);
        });
    }
    //#endregion

    //-----EVENT FUNCTIONS-----//
    //#region EVENTS
    onSubmit = e => {
        e.preventDefault();

    };

    onChange = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        var { settings } = this.state.group;

        settings[name] = value;
        this.setState({ settings: settings });
    };

    onChangeGameSettings = (e, settingType) => {
        var settings = this.state[settingType];
     
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        var payload = {
            title: name,
            isEnabled: value,
            settingType: settingType,
            property: "isEnabled"
        };

        var item = settings.find(s => s.title === name);
        item.isEnabled = value;
        this.setState({ [this.state[settingType]]: settings });

        console.log("Logging payload: ", payload);

        restService.civilization.postGroupGameSetting(this.props.groupID, payload).then(res => {
            if (res.status === 200) {
                this.setState({ title: "Success!", message: "Successfully saved game setting.", showToast: true });
            }
        }).catch(err => {
            console.log("Unexpected error while setting map type preference: ", err);
        });
    };

    setLockedValue = (e, settingType) => {
        var settings = this.state[settingType];
     
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        var payload = {
            title: name,
            lockedValue: globalFuncs.isBoolean(value) ? value === "true" : value,
            settingType: settingType,
            property: "lockedValue"
        };

        var item = settings.find(s => s.title === name);
        item.lockedValue = value;
        this.setState({ [this.state[settingType]]: settings });

        console.log("Logging payload: ", payload);

        restService.civilization.postGroupGameSetting(this.props.groupID, payload).then(res => {
            if (res.status === 200) {
                this.setState({ title: "Success!", message: "Successfully saved game setting.", showToast: true });
            }
        }).catch(err => {
            console.log("Unexpected error while setting map type preference: ", err);
        });
    }
    //#endregion

    //-----HANDLER FUNCTIONS-----//
    //#region HANDLERS
    handleShow = () => {
        this.setState({ show: true });
    }

    handleClose = () => {
        //reset from variables
        this.setState({ show: false });
    }

    onSubmitUpdateGameSettings = e => {
        var { group } = this.state;

        try {
            group.settings.maxNumberOfLeaderBans = parseInt(group.settings.maxNumberOfLeaderBans);
        }
        catch (err) {
            console.log("Unexpected error trying to update an group: ", err);
        }

        restService.civilization.postGroupSettings(group._id, group).then(res => {
            this.handleClose();
            const data = res.data;
            this.setState({ group: data });
        }).catch(err => {
            console.log("Unexpected error trying to update an group: ", err);
        });
    }
    //#endregion

    //-----DISPLAY FUNCTIONS-----//
    //#region DISPLAY
    displayBody = (group, isLoading) => {
        if (isLoading) {
            return (
                null
            );
        }
        else {
            return (
                <div>
                    <Card.Text><strong>Max Number of Leader Bans: </strong> {group.settings?.maxNumberOfLeaderBans}</Card.Text>
                    <Card.Text>Game Settings</Card.Text>
                    {this.displayGameSettingsBody()}
                    <br></br>
                    <Card.Text>Game Modes</Card.Text>
                    {this.displayGameModesBody()}
                    <br></br>
                    <Card.Text>Victory Types</Card.Text>
                    {this.displayVictoryTypesBody()}
                    <br></br>
                    <Card.Text>Trading and Diplomacy</Card.Text>
                    {this.displayTradingAndDiplomacyBody()}
                </div>
            );
        }
    }

    displayGameSettingsBody = () => {
        var { gameSettings } = this.state;
        if (!gameSettings) { return null }

        if (this.state.isLoading) {
            return (
                <Spinner animation="border" role="status" variant="secondary" >
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            );
        }
        else {
            //return null;
            return (
                <Row xs={1} md={4} className="g-3">
                    {gameSettings.map((setting, index) => (
                        <Col key={index} >
                            <Form.Check type="checkbox" id={setting.title} label={setting.title} onChange={e => this.onChangeGameSettings(e, "gameSettings")} checked={setting.isEnabled} />
                            { setting.isEnabled ? null : 
                                <Form.Select  id={setting.title} aria-label={setting.title} value={setting.lockedValue} onChange={e => this.setLockedValue(e, "gameSettings")} >
                                    {renderFuncs.selectOptions(setting.options)}
                                </Form.Select>
                            }
                        </Col>
                    ))}
                </Row>
            );
        }
    }

    displayGameModesBody = () => {
        var { gameModes } = this.state;
        if (!gameModes) { return null }

        if (this.state.isLoading) {
            return (
                <Spinner animation="border" role="status" variant="secondary" >
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            );
        }
        else {
            //return null;
            return (
                <Row sm={1} md={5} className="g-3">
                    {gameModes.map((setting, index) => (
                        <Col key={index} >
                            <Form.Check type="checkbox" id={setting.title} label={setting.title} onChange={e => this.onChangeGameSettings(e, "gameModes")} checked={setting.isEnabled} />
                            { setting.isEnabled ? null : 
                                <Form.Select  id={setting.title} aria-label={setting.title} value={setting.lockedValue} onChange={e => this.setLockedValue(e, "gameModes")} >
                                    {renderFuncs.selectOptions(setting.options)}
                                </Form.Select>
                            }
                        </Col>
                    ))}
                </Row>
            );
        }
    }

    displayVictoryTypesBody = () => {
        var { victoryTypes } = this.state;
        if (!victoryTypes) { return null }

        if (this.state.isLoading) {
            return (
                <Spinner animation="border" role="status" variant="secondary" >
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            );
        }
        else {
            //return null;
            return (
                <Row xs={1} md={4} className="g-3">
                    {victoryTypes.map((setting, index) => (
                        <Col key={index} >
                             <Form.Check type="checkbox" id={setting.title} label={setting.title} onChange={e => this.onChangeGameSettings(e, "victoryTypes")} checked={setting.isEnabled} />
                             { setting.isEnabled ? null : 
                                <Form.Select  id={setting.title} aria-label={setting.title} value={setting.lockedValue} onChange={e => this.setLockedValue(e, "victoryTypes")} >
                                    {renderFuncs.selectOptions(setting.options)}
                                </Form.Select>
                            }
                        </Col>
                    ))}
                </Row>
            );
        }
    }

    displayTradingAndDiplomacyBody = () => {
        var { tradingAndDiplomacy } = this.state;
        if (!tradingAndDiplomacy) { return null }

        if (this.state.isLoading) {
            return (
                <Spinner animation="border" role="status" variant="secondary" >
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            );
        }
        else {
            //return null;
            return (
                <Row sm={1} md={4} className="g-3">
                    {tradingAndDiplomacy.map((setting, index) => (
                        <Col key={index} >
                            <Form.Check type="checkbox" id={setting.title} label={setting.title} onChange={e => this.onChangeGameSettings(e, "tradingAndDiplomacy")} checked={setting.isEnabled} />
                            { setting.isEnabled ? null : 
                                <Form.Select  id={setting.title} aria-label={setting.title} value={setting.lockedValue} onChange={e => this.setLockedValue(e, "tradingAndDiplomacy")} >
                                    {renderFuncs.selectOptions(setting.options)}
                                </Form.Select>
                            }
                        </Col>
                    ))}
                </Row>
            );
        }
    }

    displayEditSettings = (group) => {
        if (!group.settings) {
            return null;
        }
        else {
            return (
                <div className="py-3 px-3">
                    <Form>
                        <Form.Group className="py-2">
                            <Form.Label>Max Number of Leader Bans</Form.Label>
                            <Form.Control as="select" id="maxNumberOfLeaderBans" aria-label="Number Of Civilizations"
                                value={group.settings.maxNumberOfLeaderBans} onChange={this.onChange} >
                                {renderFuncs.numberedOptions(5)}
                            </Form.Control>
                        </Form.Group>

                        {/* <Form.Group>
                        <Form.Label><strong>Group Victories</strong></Form.Label>
                        <Form.Check
                            type="checkbox"
                            id="allowMultipleWinners"
                            label={this.state.allowMultipleWinners ? "Allow Multiple Victors" : "Single Victors"}
                            onChange={this.onChange}
                            checked={this.state.allowMultipleWinners}
                            style={{ margin: '5px' }}
                        />
                    </Form.Group> */}

                        <Form.Group className="py-3" >
                            <Button onClick={this.onSubmitUpdateGameSettings}>Save</Button>
                        </Form.Group>
                    </Form>


                </div>
            );
        }
    }

    //#endregion

    render() {
        const { group, isLoading, title, message } = this.state;

        return (
            <div className="py-3 px-3" >
                <Card bg={this.state.variant}>
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        Game Settings
                        <Button onClick={this.handleShow}>Edit</Button>
                    </Card.Header>
                    <Card.Body>
                        {this.displayBody(group, isLoading)}
                    </Card.Body>
                </Card>

                {/* SHOW SETTINGS MODAL! */}
                <Modal size="lg" show={this.state.show} onHide={this.handleClose} centered animation={true} backdrop="static" keyboard={false}>
                    <Modal.Header className="d-flex justify-content-between align-items-center">
                        <Modal.Title>Edit Game Settings</Modal.Title>

                        <button type="button" onClick={this.handleClose} className="btn-close py-2" aria-label="Close" />
                    </Modal.Header>

                    {this.displayEditSettings(group)}
                </Modal>

                <SMMToast show={this.state.showToast} title={title} message={message} />
            </div>
        );

    }
}

GameSettings.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(GameSettings);