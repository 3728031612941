import React from 'react'
import Alert from 'react-bootstrap/Alert';

const SuccessAlert = ({ closeAlert, title, message }) => {
    return (
        <Alert>
            {message}
        </Alert>
    )
}

export default SuccessAlert;