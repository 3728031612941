
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import restService from "../../utils/restService";

import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
// import Modal from 'react-bootstrap/Modal';
// import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { return images[item.replace('./', '')] = r(item); });
    return images;
}

const skillIcons = importAll(require.context('../../images/icons/OldSchoolRuneScape', false, /\.(png|jpe?g|svg)$/));
const skillMap = ['attack', 'defence', 'strength', 'constitution', 'ranged', 'prayer', 'magic', 'cooking', 'woodcutting', 'fletching', 'fishing', 'firemaking', 'crafting', 'smithing', 'mining', 'herblore', 'agility', 'thieving', 'slayer', 'farming', 'runecrafting', 'hunter', 'construction'];

class OSRSStats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            auth: {},
            errors: {},
            playerAccount: {}
        };
    }

    componentDidMount() {
        var history = this.props.history.location.pathname + this.props.history.location.search;
        const match = matchPath(history, {
            path: "/osrsStats?playerName=:playerName&gameMode=:gameMode",
            strict: true
        });

        var playerName = match.params.playerName;
        var gameMode = match.params.gameMode;

        if (!this.props.auth.isAuthenticated) {
            this.props.history.push("/login");
        }
        else {
            this.props.history.push("/osrsStats?playerName=" + playerName + "&gameMode=" + gameMode);
        }

        this.setState({ playerName: playerName, gameMode: gameMode });
        this.getOSRSSteamDetails(playerName, gameMode);
    }

    getOSRSSteamDetails = (playerName, gameMode) => {
        restService.runeScape.getOSRSDetails(playerName, gameMode).then(res => {
            const data = res.data;
            this.setState({ playerAccount: data });
        }).catch(err => {
            console.log("Unexpected error with loading old school runescape profile: ", err);
        });
    }

    //creates a new group

    //handlers
    handleShow = () => {
        this.setState({ show: true });
    }

    handleClose = () => {
        //reset from variables
        this.setState({ show: false });
    }

    onChange = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        this.setState({ [name]: value });
    };

    displaySkills = (playerAccount) => {
        if (!playerAccount.skills) {
            return null;
        }
        else {
            return skillMap.map((item, index) => (
                <tr key={index}>
                    <td ><Image className="mx-3" src={skillIcons[item + '.png']?.default} thumbnail />{item.charAt(0).toUpperCase() + item.slice(1)}</td>
                    <td>{playerAccount?.skills[item]?.level}</td>
                    <td>{playerAccount?.skills[item]?.rank}</td>
                    <td>{playerAccount?.skills[item]?.experience}</td>
                </tr>
            ));
        }
    }

    render() {
        const { playerAccount, playerName } = this.state;
        
        return (
            <div >
                <Navbar expand="lg" variant="light" bg="light">
                    <Navbar.Brand className="mx-3">Old School RuneScape Stats - { playerName }</Navbar.Brand>
                </Navbar>
                <div className="mx-3" >
                    <Card className="my-3">
                        <Card.Header>Skills</Card.Header>
                        <Card.Body>
                            <Table responsive striped bordered hover >
                                <thead>
                                    <tr>
                                        <th>Skill</th>
                                        <th>Level</th>
                                        <th>Rank</th>
                                        <th>Exp</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.displaySkills(playerAccount)}
                                </tbody>
                            </Table>

                        </Card.Body>
                    </Card>
                </div>
            </div>
        );
    }
}

OSRSStats.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(OSRSStats);