
import _ from 'lodash';

const civFilter = async (games, members, filterType) => {
    var filteredGames = [];
    var memberList = members.map(m => m.userID);

    if (filterType === "Is") {
        games.forEach(game => {
            var gameMembersList = game.members.filter(m => m.isActive).map(m => m.userID);
            if (_.isEqual(gameMembersList.sort(), memberList.sort())) {
                filteredGames.push(game);
            }
        });
    }
    else if (filterType === "Includes") {
        games.forEach(game => {
            var gameMembersList = game.members.filter(m => m.isActive).map(m => m.userID);
            if (memberList.every(m => gameMembersList.includes(m))) {
                filteredGames.push(game);
            }
        });
    }
    
    return Promise.resolve(filteredGames);
}

export default civFilter;