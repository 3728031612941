import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import axios from 'axios';

// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
// import Form from 'react-bootstrap/Form';
// import Col from 'react-bootstrap/Col';
// import Row from 'react-bootstrap/Row';
// import Card from 'react-bootstrap/Card';
// import CardGroup from 'react-bootstrap/CardGroup';
import Carousel from 'react-bootstrap/Carousel';

import civRankings from '../../images/photos/civilization/civRankings.png';
import civActiveGames from '../../images/photos/civilization/civActiveGames.png';
import civEvents from '../../images/photos/civilization/civEvents.PNG';
import civGameNotes from '../../images/photos/civilization/civGameNotes.png';

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }


  render() {
    // const { errors } = this.state;

    return (
      <div>
        <div className="container" style={{ marginTop: '20px' }}>
          <p><strong>Tired of using forums or excel to manage your groups for your favourite games? SquadMatchMake is for you.</strong></p>
          <Carousel variant="dark">
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={civRankings}
                alt="First slide"
              />
              <Carousel.Caption >
                <h3 style={{ color: 'black' }}>Rankings</h3>
                <p style={{ color: 'black' }}>
                  Bring your Civilization 6 group into the Information Era with SquadMatchMake.
                </p>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="d-block w-100"
                src={civActiveGames}
                alt="First slide"
              />
              <Carousel.Caption >
                <h3 style={{ color: 'black' }}>Active Games</h3>
                <p style={{ color: 'black' }}>
                  Keep track of the games you start.
                </p>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="d-block w-100"
                src={civEvents}
                alt="First slide"
              />
              <Carousel.Caption >
                <h3 style={{ color: 'black' }}>Events</h3>
                <p style={{ color: 'black' }}>
                  Manage the games you start.
                </p>
              </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="d-block w-100"
                src={civGameNotes}
                alt="First slide"
              />
              <Carousel.Caption >
                <h3 style={{ color: 'black' }}>Game Notes</h3>
                <p style={{ color: 'black' }}>
                  Leave yourself some notes and pick up where you left off.
                </p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    );
  }
}

Landing.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(Landing);