import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import restService from '../../../../utils/restService';

import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';

class Members extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            groupID: "",
            group: {},
            //render control
            isLoading: true,
            variant: "light",
            show: false,
            userLeaderAgenda: ""
        };
    }

    componentDidMount() {
        this.setState({ groupID: this.props.groupID });
        this.getFullGroup(this.props.groupID);
        
    }

    getFullGroup = (groupID) => {
        restService.groups.getFullGroup(groupID).then((response) => {
            const data = response.data;
            this.setState({ group: data });
        }).catch((err) => {
            console.log("Error retrieving member data: ", err);
        }).then(() => {
            this.getGroupLeaderAgendas(groupID);
        });
    }

    getGroupLeaderAgendas = (groupID) => {
        restService.civilization.getGroupLeaderAgendas(groupID).then((response) => {
            const data = response.data;
            var { members } = this.state.group;

            var results = members.map(t1 => ({...t1, ...data.find(t2 => t2.userID === t1.userID)}));

            var group = this.state.group;
            group.members = results;

            this.setState({ group: group });
        }).catch((err) => {
            console.log("Error retrieving member data: ", err);
        }).then(() => {
            this.setState({ isLoading: false });
        });
    }

    handleShow = () => {
        this.setState({ show: true });
    }

    handleClose = () => {
        //reset from variables
        this.setState({ show: false });
    }

    onChange = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;

        this.setState({ [name]: value });
    }

    displayBody = (members, isLoading) => {
        if (isLoading) {
            return (
                <div>
                    <Spinner animation="border" role="status" variant="secondary" >
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            );
        }
        else {
            return (
                <div>
                    <Table responsive striped bordered hover variant={this.state.variant} >
                        <thead>
                            <tr>
                                <th>Member</th>
                                <th>Leader Adgenda</th>
                                <th>Role</th>
                            </tr>
                        </thead>
                        <tbody>
                            {members.map((member, index) => (
                                <tr key={index}>
                                    <th>{member.username}</th>
                                    <th>{member.leaderAgenda}</th>
                                    <th>{member.role}</th>
                                </tr>))}
                        </tbody>
                    </Table>
                </div>
            )
        }
    }

    render() {
        const { group, isLoading } = this.state;

        return (
            <div className="py-3 px-3">
                <Card bg={this.state.variant}>
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        Members
                    </Card.Header>
                    <Card.Body>
                        {this.displayBody(group.members, isLoading)}
                    </Card.Body>
                </Card>

                {/* Show members modal */}
                <Modal size="lg" show={this.state.show} onHide={this.handleClose} centered animation={true} backdrop="static" keyboard={false}>
                    <Modal.Header className="d-flex justify-content-between align-items-center">
                        <Modal.Title>User Leader Agenda</Modal.Title>
                        <button type="button" onClick={this.handleClose} className="btn-close" style={{ marginLeft: '0px' }} aria-label="Close"></button>
                    </Modal.Header>

                    <Modal.Body>
                        {this.state.userLeaderAgenda.split("\n").map((i, key) => {
                            return <div key={key}>{i}</div>;
                        })}
                    </Modal.Body>
                </Modal>
            </div>
        );
    }

}

Members.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps
)(Members);